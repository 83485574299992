<template>
  <button
    class="btn"
    :type="type"
    role="button"
    :aria-label="text"
    :class="{
      'is-disabled': isDisabled || visualDisable,
      'is-allowed': visualDisable,
      'is-loading': isLoading,
      'has-icon': icon,
      'is-uppercase': uppercase,
    }"
    :disabled="isDisabled || isLoading"
    @click="onAction"
  >
    <span class="btn-label">
      <icon v-if="icon" class="btn-icon" :type="icon.type" :color="icon.color" :size="icon.size || '20px'" />
      {{ text }}
      <slot></slot>
    </span>
    <span v-if="isLoading" class="btn-loader"></span>
  </button>
</template>

<script>
  import { debounce } from 'throttle-debounce';
  import Icon from '@/components/Icon.vue';

  export default {
    components: {
      Icon,
    },
    props: {
      text: {
        type: String,
        required: false,
        default: null,
      },
      type: {
        type: String,
        required: false,
        default: 'button',
      },
      uppercase: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: Object,
        default: null,
      },
      isDisabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      visualDisable: {
        type: Boolean,
        default: false,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      throttle: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['action'],
    data() {
      return {
        throttledAction: null,
      };
    },
    created() {
      this.throttledAction = debounce(500, (e) => {
        this.$emit('action', e);
      });
    },
    methods: {
      onAction(e) {
        if (!this.throttle) {
          this.$emit('action', e);
        } else {
          this.throttledAction(e);
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .is-allowed {
    cursor: pointer !important;
  }
</style>
