<template>
  <span
    class="icon-wrapper"
    aria-hidden="true"
    :style="{
      width: size,
      height: size,
      'min-width': size,
      'min-height': size,
    }"
  >
    <component :is="icon" :fill="color" preserveAspectRatio="xMinYMid meet" />
  </span>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import styles from '@/assets/styles/exports.module.scss';

  export default {
    props: {
      type: {
        type: String,
        required: true,
      },
      color: {
        type: String,
        required: false,
        default: styles.colorGrey,
      },
      size: {
        type: String,
        required: false,
        default: '32px',
      },
    },
    computed: {
      icon() {
        // eslint-disable-next-line
        return defineAsyncComponent(() => import(`../assets/images/icon-${this.type}.svg`));
      },
    },
  };
</script>

<style lang="scss" scoped>
  .icon-wrapper {
    position: relative;
    display: inline-block;
    height: 0;

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
</style>
