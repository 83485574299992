import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';

const Livestream = () => import('@/views/livestream/index.vue');

export default (app, store) => ({
  path: 'livestream',
  name: 'livestream',
  component: Livestream,
  meta: {
    auth: true,
    showNavbar: true,
    layout: 'FullScreen',
    permissions: {
      access: ({ user }) => !!user,
      redirect: (state, to) => ({
        name: 'account',
        query: { next: to.fullPath },
      }),
    },
  },
  beforeEnter: async (to, from, next) => {
    const now = moment();
    const { state } = store;

    if (state.shop.event && state.shop.event.livestream === 'internal' && moment(state.shop.event.doors_open) < now) {
      next();
    } else {
      next({
        name: 'tickets-detail',
        params: { slug: to.params.slug },
      });
    }
  },
});
