import { Line, GateObject } from '@getprotocollab/get-pal';
import { GateStoreable as S, Shop } from './types';

const mutations = {
  store_favorites(state: S, shopSlugs: string[]) {
    state.favorites = [...shopSlugs];
  },
  // TODO - queue should be renamed to gate
  store_queue(state: S, gate: GateObject) {
    state.queue = { ...gate };
  },
  store_shops(state: S, shops: Shop[]) {
    state.shops = [...shops];
  },
  store_gate_handle(state: S, gate_handle: string) {
    state.gate_handle = gate_handle;
  },
  set_is_fresh_handle(state: S, isFresh: boolean) {
    state.isFreshHandle = isFresh;
  },
  store_line(state: S, line: Line) {
    state.line = { ...line };
  },
  store_phone_number(state: S, number: string) {
    state.phoneNumber = number;
  },
  set_date_autoselect(state: S, value: boolean) {
    state.dateAutoSelect = value;
  },
  store_gate_token(state: S, gate_token: string) {
    state.gate_token = gate_token;
  },
};

export default mutations;
