import { App } from 'vue';
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { captureException } from '@sentry/browser';
import { PermissionsData } from '@/router/types';
import { Store } from '@/store/types';

const Status = () => import('@/views/shop/order/Status.vue');

export default (app: App, store: Store) =>
  ({
    path: 'status',
    name: 'status',
    component: Status,
    meta: {
      auth: true,
      gate: true,
      shop: true,
      order: true,
      layout: 'Empty',
      permissions: {
        access: (data: PermissionsData, to: RouteLocationNormalized): boolean => {
          const { user, gate: { queue, shops } = {} } = data;
          const { state } = store;
          const { shopSlug } = to.params;

          if (!shops || !queue || !queue.shop_slugs.find((s) => s === shopSlug)) return false;

          if (!state.gate) return false;

          if (!user) return false;

          if (import.meta.env.VITE_APP_API_MODE !== 'sharded' && !user.profile_complete) return false;

          return true;
        },
        redirect: (data: PermissionsData, to: RouteLocationNormalized) => {
          const { user, gate: { queue, shops } = {} } = data;
          const { state } = store;
          const {
            params: { shopSlug },
            path,
            fullPath,
          } = to;

          if (!shops || !queue || !queue.shop_slugs.find((s) => s === shopSlug)) {
            return {
              name: 'queue',
              query: { next: fullPath },
            };
          }

          if (shops.length > 0 && !state.gate) {
            return { name: 'notFound', params: { 0: path } };
          }

          if (!user) {
            return {
              name: 'account',
              query: { next: to.fullPath },
            };
          }
          return {
            name: 'profile',
            query: { next: to.fullPath },
          };
        },
      },
    },
    beforeEnter: async (to, _, next) => {
      const { dispatch, commit } = store;
      const { payment_id, psp_error } = to.query;

      try {
        await dispatch('order/getPayment', parseInt(payment_id as string, 10));
      } catch (e: any) {
        if (e?.response?.status === 404) {
          next({ name: to.params.shopType === 'resale' ? 'resale' : 'shop', params: { ...to.params } });
          commit('shop/set_local_error', { show: true, key: 'orderNotFound' });
          return;
        }
        next();
        throw e;
      }

      if (psp_error) {
        commit('order/set_payment_error', { show: true, key: psp_error as string });
        captureException(new Error(`PSP_ERROR: ${psp_error}`), {
          extra: { payment: payment_id },
        });
        next({ name: 'order', params: { ...to.params } });
        return;
      }
      next();
    },
  } as RouteRecordRaw);
