<template>
  <div :key="$i18n.locale" class="header" data-test-event-header>
    <h1 class="title" translate="no" data-test-event-title>{{ event.title }}</h1>
    <h2 class="subtitle" translate="no" data-test-event-subtitle>
      {{ event.subtitle }}
    </h2>
    <div class="info-wrapper">
      <div class="info">
        <DateCard
          class="date-card"
          :date="event.when"
          :ends="event.ends"
          :multiday="multiday"
          :zone="event.local_timezone"
          :endsOnPreviousDay="event.ends_on_previous_day"
        />
        <div class="meta">
          <p class="meta-item">
            <icon class="meta-icon" type="pin" size="20px" />
            <strong class="label">
              {{ event.location }}<span v-if="event.city">, {{ event.city }}</span>
            </strong>
          </p>
          <p class="meta-item">
            <icon class="meta-icon" type="time" size="20px" />
            <strong v-if="multiday" class="label">
              {{
                `${formatLocaleMultiDate(event.when, event.local_timezone)} - ${formatLocaleMultiDate(
                  eventEnds,
                  event.local_timezone,
                )}`
              }}
            </strong>
            <strong v-else class="label date">
              {{ formatLocaleDate(event.when, event.local_timezone) }}
            </strong>
          </p>
          <TimeZone
            v-if="!isSameTimezoneAsUser"
            :text="$t('shop.eventInfo.timezone', { timezone: replaceUnderscores(event.local_timezone) })"
            data-test-timezone
            class="timezone"
          />
          <div class="meta-tabs" role="tablist">
            <a
              v-if="showInfo"
              class="info-tab"
              :class="{ 'is-active': infoActive }"
              role="tab"
              data-test-info-tab
              @click="$emit('toggleInfo')"
            >
              <Icon :type="showSeated ? 'seat' : 'info'" size="18px" class="info-icon" />
              <span>
                {{ $t('shop.tabs.info') }}{{ ' ' }}
                <span v-if="showSeated" class="info-seats">{{ $t('shop.tabs.seated') }}</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { formatLocaleMultiDate, formatLocaleDate } from '@/helpers/filters';
  import Icon from '@/components/Icon.vue';
  import DateCard from '@/components/DateCard.vue';
  import helpers from '@/helpers';

  const TimeZone = defineAsyncComponent(() => import('@/components/shop/TimeZone.vue'));

  export default {
    components: {
      Icon,
      DateCard,
      TimeZone,
    },
    props: {
      event: { type: Object, required: true },
      showInfo: { type: Boolean, required: false, default: false },
      infoActive: { type: Boolean, required: false },
    },
    computed: {
      multiday() {
        return helpers.isMultiDay(this.event);
      },
      eventEnds() {
        const { ends, local_timezone, ends_on_previous_day } = this.event;
        return helpers.eventEnds(ends, local_timezone, ends_on_previous_day);
      },
      showSeated() {
        return this.event.seated && this.event.seating_image && !this.event.has_interactive_seating_image;
      },
      isSameTimezoneAsUser() {
        return helpers.isSameTimezoneAsUser(this.event?.local_timezone);
      },
    },
    methods: {
      replaceUnderscores: helpers.replaceUnderscores,
      formatLocaleMultiDate,
      formatLocaleDate,
    },
  };
</script>

<style lang="scss" scoped>
  .title,
  .subtitle {
    margin: 0;
    padding: 0;
    font-size: $size-large;
    line-height: 1;
    text-transform: uppercase;

    @media (min-width: $device-small) {
      font-size: 1.75rem;
    }
  }

  .title {
    font-weight: $weight-bold;
    color: $color-text;

    .is-widget & {
      margin-right: 4rem;
    }
  }

  .subtitle {
    font-weight: $weight-light;
    color: $color-primary;
    color: var(--color-theme);
  }

  .info-wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .info {
    flex: 1;
    width: 100%;
    min-width: 100%;
    display: flex;
    margin: 0.5rem 0 0 0;

    @media (min-width: 30rem) {
      min-width: 0; // break tab newline
    }
  }

  .date-card {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }

  .meta {
    display: block;
    flex: 1;
    min-width: 0; // fixes text truncate
    color: $color-grey;
    text-align: left;
  }

  .meta-tabs {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    isolation: isolate;
  }

  .meta-item {
    flex: 1;
    display: flex;
    align-items: center;
    margin: 0;
    overflow: hidden;

    .meta-icon {
      display: block;
      margin-right: 0.1rem;

      :deep(path) {
        fill: $color-grey;
      }
    }

    .label {
      flex: 1;
      font-weight: $weight-medium;
      @include text-truncate;
    }

    .date {
      text-transform: capitalize;
    }
  }

  .info-tab {
    position: absolute;
    user-select: none;
    cursor: pointer;
    z-index: 1;
    right: 0.5rem;
    bottom: 0;
    padding: 0.5rem 1rem 0.5em 0.5rem;
    display: flex;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    color: $color-secondary;
    font-weight: $weight-medium;
    border-style: solid;
    border-color: transparent;
    border-radius: 4px 4px 0 0;
    border-width: 1px 1px 0 1px;
    background: linear-gradient(
      to right,
      rgba($color-white, 0) 0%,
      rgba($color-white, 1) 8%,
      rgba($color-white, 1) 100%
    );

    .info-icon {
      margin-right: 0.5rem;

      :deep(path) {
        fill: $color-secondary;
      }
    }

    &.is-active {
      background: $color-offwhite;
      border-color: $color-border-light;
    }
  }

  @media (max-width: 26rem) {
    .info-seats {
      display: none;
    }
  }
</style>
