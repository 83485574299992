import { App } from 'vue';
import { Store } from '@/store/types';

const Profile = () => import('@/views/Profile.vue');

export default (app: App, store: Store) => ({
  path: '/profile',
  name: 'profile',
  component: Profile,
  meta: {
    auth: true,
    showNavbar: true,
    permissions: {
      access: ({ user }) => !!user,
      redirect: (state, to) => ({
        name: 'account',
        query: to.query,
      }),
    },
  },
  beforeEnter: async (to, from, next) => {
    if (!from.meta.gate) {
      store.commit('set_layout_cover', { cover: '' });
    }
    next();
  },
});
