const Detail = () => import('@/views/tickets/details/Detail.vue');

export default (app, store) => ({
  path: '',
  name: 'tickets-detail',
  component: Detail,
  meta: {
    auth: true,
    showNavbar: true,
    permissions: {
      access: ({ user }) => !!user,
      redirect: (state, to) => ({
        name: 'account',
        query: { next: to.fullPath },
      }),
    },
  },
});
