import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { RouteLocationNormalized } from 'vue-router';
import { GateShop } from '@getprotocollab/get-pal';
import { Dictionary } from '@/store/types/types';
import { GateMode, GateState, LineState, GateQueueState, ShopState } from '@/constants';
import { GateStoreable as S, Shop } from './types';

type _getters = {
  isAutoQueue(state: S, g: _ctx): boolean;
  isThrottlingQueue(state: S, g: _ctx): boolean;
  showThrottleLoader(state: S, g: _ctx): (redirectAccess: boolean, date: string) => boolean;
  isNotStarted(state: S): boolean;
  isSoldout(state: S): boolean;
  allShopsAreSoldOut(state: S): boolean;
  isOpen(state: S): boolean;
  isAccess(state: S): (route: RouteLocationNormalized) => boolean;
  isGroup(state: S, g: _ctx): boolean;
  shopsByDate(state: S, g: _ctx): Dictionary<Shop[]>;
  firstAvailableShops(state: S, g: _ctx): (selectedDate?: string) => { date: string; shops: Shop[] };
  favoriteShopsMap(state: S, g: _ctx): Record<string, GateShop>;
};
type _ctx = {
  [Property in keyof _getters]: ReturnType<_getters[Property]>;
};

const getters: _getters = {
  isAutoQueue: ({ queue }) =>
    !!queue && (queue.queue_state === GateQueueState.AUTO || queue.queue_state === GateQueueState.PENDING_AUTO),
  isThrottlingQueue: ({ queue }) =>
    !!queue && (queue.queue_state === GateQueueState.THROTTLE || queue.queue_state === GateQueueState.PENDING),
  showThrottleLoader:
    ({ shops, queue, line, dateAutoSelect }, { firstAvailableShops }) =>
    (redirectAccess: boolean, date: string) => {
      const lineState = line?.state;
      const { queue_state, show_datepicker } = queue || {};

      if ((!shops.length && lineState === LineState.TURN) || !queue || lineState === LineState.SOLDOUT) return false;

      const hasStarted = queue.time_to_start <= 0;

      if (!hasStarted || queue_state !== GateQueueState.THROTTLE) return false;
      if (
        show_datepicker &&
        firstAvailableShops(date).shops &&
        firstAvailableShops(date).shops.length === 1 &&
        queue_state === GateQueueState.THROTTLE &&
        dateAutoSelect
      )
        return true;

      if (shops.length === 1 && queue_state === GateQueueState.THROTTLE) return true;
      if (!line) return true;
      if (redirectAccess) return true;

      return lineState === LineState.WAITING;
    },
  isSoldout: ({ queue }) => !!queue && queue.state === GateState.SOLDOUT,

  allShopsAreSoldOut: ({ shops }) =>
    // this is only meaningful for non-datepicker gates,
    // date picker gate only fetches shops for the selected month
    // so we can't be certain that all shops in the gate are sold out
    shops.every((shop) => shop.state === ShopState.SOLDOUT),

  isNotStarted: ({ queue }) => {
    if (!queue) return false;

    const { mode, time_to_start } = queue;
    const hasStarted = time_to_start <= 0;
    return !hasStarted && mode !== GateMode.PRE_QUEUE;
  },
  isAccess:
    ({ queue }) =>
    ({ query }) => {
      if (!queue) return false;

      const { mode, time_to_start } = queue;
      const hasStarted = time_to_start <= 0;

      return mode === GateMode.ACCESS || (!hasStarted && !!query?.code);
    },
  isOpen: ({ queue }) => {
    if (!queue) return false;

    const { shop_slugs, mode } = queue;
    const hasStarted = queue.time_to_start <= 0;
    return queue && !!shop_slugs.length && hasStarted && mode === GateMode.OPEN;
  },

  isGroup: ({ queue, shops }) => {
    if (!queue) return false;
    if (queue.show_datepicker) return true;
    if (shops) return shops.length > 1;
    return false;
  },

  favoriteShopsMap: ({ shops, favorites }) =>
    shops.reduce((acc, shop) => {
      if (favorites.find((f) => f === shop.slug)) {
        acc[shop.slug] = shop;
      }
      return acc;
    }, {} as Record<string, GateShop>),

  shopsByDate: ({ shops }) =>
    shops.reduce((acc, shop) => {
      const start = moment.tz(shop.when, shop.local_timezone).startOf('day');
      const end = moment.tz(shop.ends, shop.local_timezone).startOf('day');
      let diff = moment.duration(end.diff(start, 'days'));

      if (diff > 0 && shop.ends_on_previous_day) diff -= 1;
      if (diff < 0) return acc;

      for (let i = 0; i <= diff; i += 1) {
        const date = start.clone().add(i, 'd').format('D/M/YYYY');

        acc[date] = acc[date] || [];
        acc[date].push(shop);
      }
      return acc;
    }, {} as Dictionary<Shop[]>),

  firstAvailableShops: (_, g) => (selectedDate?: string) => {
    const byDate = g.shopsByDate;
    if (selectedDate) {
      return byDate[selectedDate] ? { date: selectedDate, shops: byDate[selectedDate] } : { date: '', shops: [] };
    }
    const today = moment().startOf('day').format('D/M/YYYY');
    const datesWithShops = Object.entries(byDate).sort(([dateA], [dateB]) =>
      moment(dateA, 'D/M/YYYY').diff(moment(dateB, 'D/M/YYYY')),
    );
    const shopsInCurrentDate = datesWithShops.find((el) => {
      const [date] = el;
      return moment(date, 'D/M/YYYY').isSameOrAfter(moment(today, 'D/M/YYYY'));
    });

    return { date: shopsInCurrentDate?.[0] || '', shops: shopsInCurrentDate?.[1] || [] };
  },
};

export default getters;
