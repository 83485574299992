import { ActionContext, DispatchOptions, CommitOptions } from 'vuex';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import {
  fetchUserDetails,
  gusGetUserDetails,
  gusUpdateUserDetails,
  updateUserDetails,
  authRefresh,
  GP_Response,
  UserDetails,
  GetUserDetailsSuccess,
} from '@getprotocollab/get-pal';
import {
  ticketApiConfig,
  gusApiConfig,
  clearTicketApiAuthToken,
  clearGusApiAuthToken,
  setTicketApiAuthToken,
  setGusApiAuthToken,
} from '@/api';
import localeHelpers from '@/helpers/locale';
import { User, RootStoreable } from '@/store/types/models';
import gusConfig from '@/api/gus';
import helpers from '@/helpers';
import mutations from './mutations';

type _mutations = typeof mutations;
type _actions = ReturnType<typeof actions>;
type _ctx = {
  commit<K extends keyof _mutations, P extends Parameters<_mutations[K]>[1]>(
    ...args: P extends undefined
      ? [key: K, payload?: P, options?: CommitOptions]
      : [key: K, payload: P, options?: CommitOptions]
  ): ReturnType<_mutations[K]>;
  dispatch<K extends keyof _actions, P extends Parameters<_actions[K]>[1]>(
    ...args: P extends undefined
      ? [key: K, payload?: P, options?: DispatchOptions]
      : [key: K, payload: P, options?: DispatchOptions]
  ): ReturnType<_actions[K]>;
} & Omit<ActionContext<RootStoreable, RootStoreable>, 'commit' | 'dispatch'>;

function actions(app) {
  return {
    async getLoggedInUser({ dispatch }: _ctx, sso?: string): Promise<User | null> {
      const { storage } = app.config.globalProperties;
      let token: string;
      let refresh_token: string;

      if (sso) storage.setItem('rt', sso);

      const refreshToken = storage.getItem('rt');

      if (!refreshToken) return null;

      try {
        const response = await authRefresh(gusApiConfig, { refresh_token: refreshToken });
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        if (response.data?.status === 'error') throw response.data.error;
        token = response.data?.token || '';
        refresh_token = response.data?.refresh_token || '';
      } catch (e) {
        dispatch('clearLoggedInUser');
        throw e;
      }

      return dispatch('setLoggedInUser', { token, refresh_token });
    },
    async setLoggedInUser({ dispatch }: _ctx, tokens: { token: string; refresh_token: string }) {
      const { storage } = app.config.globalProperties;
      storage.setItem('rt', tokens.refresh_token);
      setTicketApiAuthToken(tokens.token);
      setGusApiAuthToken(tokens.token);

      let response: GP_Response<UserDetails> | GP_Response<GetUserDetailsSuccess>;
      let user;
      if (import.meta.env.VITE_APP_API_MODE === 'sharded') {
        response = await gusGetUserDetails(gusConfig);
        user = response?.data?.user_profile || null;
      } else {
        response = await fetchUserDetails(ticketApiConfig);
        user = response?.data || null;
      }

      if (user) {
        dispatch('setUser', user);
        dispatch('setLocale', user.locale);
      }
      return user;
    },
    clearLoggedInUser({ commit }: _ctx) {
      // TODO - decouple storage from vuex
      const { storage } = app.config.globalProperties;

      commit('set_user', null);
      clearTicketApiAuthToken();
      clearGusApiAuthToken();
      storage.removeItem('rt');
    },
    setUser({ commit }: _ctx, user: User) {
      // TODO - decouple gtm from vuex
      const { gtm } = app.config.globalProperties;

      const { birthdate, gender, city, country, mobile_number, first_name, last_name, email } = user;

      const age = user.birthdate && moment().diff(moment(birthdate), 'years');

      gtm.track({
        event: 'Login',
        email,
        first_name,
        last_name,
        mobile_number,
        age,
        gender,
        city,
        country,
        user_id: user.uuid,
      });

      commit('set_user', user);
    },
    async setLocale({ commit, state }: _ctx, lang: string) {
      // TODO - decouple storage from vuex
      // TODO - pass locale as param
      const { i18n, storage } = app.config.globalProperties;

      let newLocale = i18n.locale;

      if (lang) {
        newLocale = localeHelpers.setLocale(i18n, lang);
        storage.setItem('locale', newLocale);
      }

      if (state.user && state.user.locale !== newLocale) {
        if (import.meta.env.VITE_APP_API_MODE === 'sharded') {
          if (!helpers.isUserProfileComplete(state.user)) return;

          await gusUpdateUserDetails(gusApiConfig, { locale: newLocale });
          const response = await gusGetUserDetails(gusConfig);

          if (!response.data) return;
          const user = (response.data.user_profile as User) || null;
          commit('set_user', user);
        } else {
          const response = await updateUserDetails(ticketApiConfig, {
            id: state.user.id,
            user: { locale: newLocale },
          });
          const user = response.data || null;
          commit('set_user', user);
        }
      }
    },
  };
}

export default actions;
