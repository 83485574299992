import { UserEventDetails } from '@getprotocollab/get-pal';
import { Ticket, TicketGroup, TicketStoreable as S } from './types';

const mutations = {
  set_user_events(state: S, events: UserEventDetails[]) {
    state.user_events = events.map((event) => event);
  },

  set_event_tickets(state: S, tickets: Ticket[]) {
    state.event_tickets = tickets.map((ticket) => ticket);
  },

  set_group(state: S, group: TicketGroup) {
    state.group = group;
  },
  clear_group(state: S) {
    state.group = null;
  },
};

export default mutations;
