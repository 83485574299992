import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import {
  getUserPositionInLine,
  fetchGateObject,
  GateObject,
  getShopGateToken,
  requestGateShops,
  GateShop,
} from '@getprotocollab/get-pal';
import { ActionContext, DispatchOptions, CommitOptions } from 'vuex';
import { App } from 'vue';
import helpers from '@/helpers';
import { RootStoreable } from '@/store/types/models';
import { clearShopGateToken } from '@/api/ticket-service';
import gatekeeperConfig from '@/api/gatekeeper-service';
import { GateStoreable, Shop } from './types';
import mutations from './mutations';

type _mutations = typeof mutations;
type _actions = ReturnType<typeof actions>;
type _ctx = {
  commit<K extends keyof _mutations, P extends Parameters<_mutations[K]>[1]>(
    ...args: P extends undefined
      ? [key: K, payload?: P, options?: CommitOptions]
      : [key: K, payload: P, options?: CommitOptions]
  ): ReturnType<_mutations[K]>;
  dispatch<K extends keyof _actions, P extends Parameters<_actions[K]>[1]>(
    ...args: P extends undefined
      ? [key: K, payload?: P, options?: DispatchOptions]
      : [key: K, payload: P, options?: DispatchOptions]
  ): ReturnType<_actions[K]>;
} & Omit<ActionContext<GateStoreable, RootStoreable>, 'commit' | 'dispatch' | 'getters'>;

function actions(app: App) {
  return {
    async updateUserLine({ commit }: _ctx, gate_handle: string): Promise<void> {
      const response = await getUserPositionInLine(gatekeeperConfig, gate_handle);
      if (response.data) commit('store_line', response.data);
    },
    async fetchGateInfo(
      { commit, dispatch }: _ctx,
      { slug, date }: { slug: string; date?: string },
    ): Promise<{ queue: GateObject | null; shops: Shop[] }> {
      const response = await fetchGateObject(gatekeeperConfig, { gateSlug: slug });
      const gate = response.data!;

      let min;
      let max;
      if (date) {
        const month: number = moment(date, 'D/M/YYYY').month();
        min = helpers.constructFilterDate({ month, day: 0, date: undefined }) as string;
        max = helpers.constructFilterDate({ month: month + 1, day: 2, date: undefined }) as string;
      }
      if (gate.show_datepicker) {
        max = gate.end;
      }
      const shops: Shop[] = await dispatch('setShops', { slug, filter: gate.show_datepicker, min, max });
      commit('store_queue', gate);
      return { queue: gate, shops };
    },
    async setShops(
      { commit }: _ctx,
      { slug, filter = false, min, max }: { slug: string; filter?: boolean; min?: string; max?: string },
    ) {
      let after;
      let before;

      if (filter) {
        const month: number = moment().month();
        after = min || helpers.constructFilterDate({ month, day: 0, date: undefined });
        before = max || helpers.constructFilterDate({ month: month + 1, day: 2, date: undefined });
      }
      const response = await requestGateShops(gatekeeperConfig, { slug, after, before });
      if (response.data?.status === 'error') {
        throw new Error(response.data.error);
      }

      if (response.data) {
        commit('store_shops', response.data.shops);
      }
      return response.data?.shops || [];
    },
    async getShopGateToken(
      { commit, dispatch }: _ctx,
      { gate_handle, slug }: { gate_handle: string; slug: string },
    ): Promise<string> {
      let token = '';

      try {
        const response = await getShopGateToken(gatekeeperConfig, { gate_handle });
        if (response.data?.status === 'error') {
          throw Error(response.data.error);
        }
        token = response.data?.token || '';
      } catch (e: any) {
        clearShopGateToken();
        commit('store_gate_token', '');
        dispatch('clearGateHandle', slug);
        if (e.response?.status === 404 || ['not_your_turn', 'gate_slug_not_found'].includes(e.message)) return '';
        throw e;
      }

      commit('store_gate_token', token);
      return token;
    },
    setFavorites({ commit }: _ctx, { shopSlugs, slug }: { shopSlugs: string[]; slug: string }) {
      commit('store_favorites', shopSlugs);
      app.config.globalProperties.storage.setItem(`${slug}-favorites`, shopSlugs);
    },
    addFavorites({ commit, state: { queue, favorites } }: _ctx, shops: GateShop[]) {
      if (!queue) return;
      const newFavs = shops.reduce((favs, shop) => {
        const found = !!favs.find((slug) => slug === shop.slug);
        if (!found) {
          favs.push(shop.slug);
        }
        return favs;
      }, favorites);
      commit('store_favorites', newFavs);
      app.config.globalProperties.storage.setItem(`${queue.slug}-favorites`, newFavs);
    },
    removeFavorites({ commit, state: { queue, favorites } }: _ctx, shops: GateShop[]) {
      if (!queue) return;
      const newFavs = shops.reduce((favs, shop) => {
        const index = favs.findIndex((f) => f === shop.slug);
        if (index !== -1) favs.splice(index, 1);
        return favs;
      }, favorites);

      commit('store_favorites', newFavs);
      if (newFavs.length === 0) {
        app.config.globalProperties.storage.removeItem(`${queue.slug}-favorites`);
        return;
      }
      app.config.globalProperties.storage.setItem(`${queue.slug}-favorites`, newFavs);
    },
    getInitialFavorites({ state: { queue, shops }, dispatch }: _ctx) {
      if (!queue) return;
      const storageFavs = app.config.globalProperties.storage.getItem(`${queue.slug}-favorites`);
      if (!storageFavs || !storageFavs.length) return;
      const filteredFavs: string[] = storageFavs.filter((f) => shops.find(({ slug }) => slug === f));
      dispatch('setFavorites', { shopSlugs: filteredFavs, slug: queue.slug });
    },
    getGateHandle({ commit }, slug) {
      // TODO - decouple storage
      const gate_handle: string = app.config.globalProperties.storage.getItem(`${slug}-gate-handle`);
      commit('store_gate_handle', gate_handle);
      return gate_handle;
    },
    setGateHandle({ commit, state }: _ctx, gate_handle: string) {
      const { slug } = state.queue!;
      commit('store_gate_handle', gate_handle);
      commit('set_is_fresh_handle', true);
      // TODO - decouple storage
      app.config.globalProperties.storage.setItem(`${slug}-gate-handle`, gate_handle);
    },
    clearGateHandle({ commit }: _ctx, slug: string) {
      commit('store_gate_handle', '');
      // TODO - decouple storage
      app.config.globalProperties.storage.removeItem(`${slug}-gate-handle`);
    },
    clearAllGateHandles({ dispatch }: _ctx) {
      // TODO - decouple storage
      const storedHandleSlugs = app.config.globalProperties.storage
        .queryKeys(/\w+-gate_handle/)
        .map((key) => key.split('-')[0]);
      storedHandleSlugs.forEach((slug) => {
        dispatch('clearGateHandle', slug);
      });
    },
  };
}

export default actions;
