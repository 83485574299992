import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { defineAsyncComponent } from 'vue';
import { RESIZE_PREFIX, IMAGE_SIZES, STORE_BADGE_LANGUAGES } from '@/helpers/constants';
import { isUserProfileComplete } from '@/helpers/user';
import { clearShopGateToken } from '@/api/ticket-service';
import { i18n } from '@/lang';

function generateUUID4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r;

    if (c === 'x') {
      r = Math.floor(Math.random() * 16);
    } else {
      r = Math.floor(Math.random() * 4) + 8;
    }
    return r.toString(16);
  });
}

function isFramed() {
  return window.self !== window.top;
}

function isWidget() {
  return !!import.meta.env.VITE_APP_WIDGET_ENABLED;
}

function getBrand() {
  return import.meta.env.VITE_APP_BRAND;
}

function slugFromHumanSlug(humanSlug) {
  return humanSlug.split('-')[0];
}

function scrollToTop(smooth = true) {
  try {
    window.scroll({
      behavior: smooth ? 'smooth' : 'auto',
      top: 0,
    });
  } catch (err) {
    if (err instanceof TypeError) {
      window.scroll(0, 0);
    } else {
      throw err;
    }
  }
}

function scrollIntoView(el, smooth = true, minOffset = 0) {
  // Only scroll if element is not in viewport (with minimum)
  const offset = el.offsetTop - window.scrollY;
  if (offset > window.innerHeight - minOffset) {
    try {
      el.scrollIntoView({
        behavior: smooth ? 'smooth' : 'auto',
      });
    } catch (err) {
      if (err instanceof TypeError) {
        el.scrollIntoView();
      } else {
        throw err;
      }
    }
  }
}

function getClosestImageSize(size) {
  return IMAGE_SIZES.reduce((prev, curr) => (Math.abs(curr - size) < Math.abs(prev - size) ? curr : prev));
}

function getResizedImage(imageUrl, size) {
  if (size < 0) return imageUrl;

  try {
    const width = getClosestImageSize(size);
    const url = new URL(imageUrl);
    url.pathname = `${RESIZE_PREFIX}${width}${url.pathname}`;
    return url.href;
  } catch (error) {
    console.error(error);
    return imageUrl; // log the error but return the original image
  }
}

function logout({ store: { state, dispatch, commit }, router, currentRoute, message }) {
  // eslint-disable-next-line
  if (confirm(message)) {
    dispatch('clearLoggedInUser');
    dispatch('gate/clearAllGateHandles');
    commit('gate/store_line', null);
    commit('gate/store_gate_token', '');
    commit('shop/set_shop_user_tk_stats', []);
    commit('shop/set_user_shop_stats', null);
    clearShopGateToken();
    // access() needs to be triggered manually with empty queue to force queue restart
    if (
      !currentRoute.meta.permissions.access(
        {
          user: null,
          gate: {},
        },
        currentRoute,
      )
    ) {
      // redirect() requires queue to prevent 404 on /shop/details route
      router.replace(
        currentRoute.meta.permissions.redirect(
          {
            user: null,
            gate: { queue: state.gate.queue },
          },
          currentRoute,
        ),
      );
    }
  }
}

function getAcceptableLowContrastColor(color) {
  const isValid = /([A-Fa-f0-9]{6})$/i.test(color);
  if (isValid) {
    const rgb = color.match(/.{2}/g).map((cur) => parseInt(cur, 16));
    const contrast = (rgb[0] * 299 + rgb[1] * 587 + (rgb[2] + 113)) / 1000;
    return contrast <= 140 ? `#${color}` : null;
  }
  return null;
}

function dispatchResizeEvent() {
  // make sure sticky footer repositions
  // with old browsers workaround
  let resizeEvent;
  if (typeof Event === 'function') {
    resizeEvent = new Event('resize');
  } else {
    resizeEvent = document.createEvent('Event');
    resizeEvent.initEvent('resize', true, true);
  }
  window.dispatchEvent(resizeEvent);
}

function enableScrollLock(el) {
  disableBodyScroll(el);
}

function disableScrollLock(el) {
  enableBodyScroll(el);
}

function clearScrollLock() {
  clearAllBodyScrollLocks();
}

function capitalize(str) {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

function constructFilterDate({ date, month, day } = {}) {
  let momentDate;

  if (!date) momentDate = moment();
  if (date) momentDate = moment(date);
  if (month || month === 0) momentDate.month(month);
  if (day || day === 0) momentDate.date(day);

  return momentDate.startOf('day').utc(true).format();
}

function isMultiDay(event) {
  const actualEnds = event.ends_on_previous_day ? moment(event.ends).subtract(1, 'day') : moment(event.ends);
  return !moment.tz(event.when, event.local_timezone).isSame(actualEnds, 'day');
}

function eventEnds(ends, local_timezone, ends_on_previous_day) {
  return ends_on_previous_day ? moment.tz(ends, local_timezone).subtract(1, 'day').format() : ends;
}

function formatPercentage(num) {
  return `${parseFloat(num) * 100}%`;
}

function isSameTimezoneAsUser(timezone) {
  const userOffset = moment.tz(moment.tz.guess()).format('z');
  return userOffset === moment.tz(timezone).format('z');
}

function formatCurrency(value, { code, decimals, symbol = true }) {
  const options = {
    style: symbol ? 'currency' : 'decimal',
    currency: code,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  };
  let currency;

  try {
    currency = new Intl.NumberFormat(i18n.locale, options).format(value);
  } catch (e) {
    // fallback when Intl.NumberFormat not supported
    currency = `${code} ${Number(value).toFixed(decimals).replace(/[.]/, ',')}`;
  }

  return currency;
}

function formatDate(value, format, zone) {
  if (zone) {
    return moment.tz(value, zone).format(format);
  }
  return moment(value).format(format);
}

function formatLocaleMultiDate(value, zone) {
  return moment.tz(value, zone).format('ll');
}

function formatLocaleDate(value, zone) {
  const momentDate = moment.tz(value, zone);
  return `${capitalize(momentDate.format('dddd'))} ${momentDate.format('LL')}`;
}

function isExpectedTokenError(errorMessage) {
  return ['token_expired', 'token_different_user'].includes(errorMessage);
}

function isMaliciousString(string) {
  const regex = /<script[\s\S]*?>[\s\S]*?<\/script>/gi;

  return regex.test(string);
}

function replaceUnderscores(string) {
  return (string || '').replace(/_/g, ' ');
}

function obfuscatedWalletAddress(value) {
  return `${value.slice(0, 7)}...${value.slice(-5)}`;
}

function appleAppStoreBadge(locale) {
  if (STORE_BADGE_LANGUAGES.includes(locale)) {
    return defineAsyncComponent(() => import(`../assets/images/badges/ios/${locale}.svg`));
  }

  return defineAsyncComponent(() => import('../assets/images/badges/ios/en.svg'));
}

function googlePlayStoreBadge(locale) {
  if (STORE_BADGE_LANGUAGES.includes(locale)) {
    return defineAsyncComponent(() => import(`../assets/images/badges/android/${locale}.svg`));
  }

  return defineAsyncComponent(() => import('../assets/images/badges/android/en.svg'));
}

export default {
  capitalize,
  generateUUID4,
  isFramed,
  isWidget,
  getBrand,
  scrollToTop,
  scrollIntoView,
  getResizedImage,
  logout,
  enableScrollLock,
  disableScrollLock,
  clearScrollLock,
  getAcceptableLowContrastColor,
  dispatchResizeEvent,
  constructFilterDate,
  isMultiDay,
  formatPercentage,
  eventEnds,
  isSameTimezoneAsUser,
  formatCurrency,
  formatDate,
  formatLocaleMultiDate,
  formatLocaleDate,
  isExpectedTokenError,
  isMaliciousString,
  slugFromHumanSlug,
  replaceUnderscores,
  obfuscatedWalletAddress,
  appleAppStoreBadge,
  googlePlayStoreBadge,
  isUserProfileComplete,
};
