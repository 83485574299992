import { createStore, useStore as baseUseStore } from 'vuex';
import { InjectionKey } from 'vue';
import { RootStoreable } from '@/store/types/models';
import actions from './actions';
import mutations from './mutations';
import gate from './modules/gate';
import shop from './modules/shop';
import order from './modules/order';
import tickets from './modules/tickets';
import { Store } from './types';

const state = {
  user: null,
  global_error: { show: false, key: '' },
  layout_cover: { cover: '' },
  is_preview: false,
} as RootStoreable;

export const key: InjectionKey<Store> = Symbol('store');

export default (app): Store =>
  createStore<RootStoreable>({
    state() {
      return { ...state };
    },
    mutations,
    actions: actions(app),
    modules: {
      gate: gate(app),
      shop: shop(app),
      order: order(app),
      tickets: tickets(app),
    },
  });

export function useStore(): Store {
  return baseUseStore(key);
}
