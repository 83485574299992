<template>
  <div v-if="active" class="modal" role="dialog">
    <div class="modal-background" @click="$emit('update:active', false)"></div>
    <div class="modal-content">
      <section v-click-away="() => $emit('update:active', false)" class="country-list-modal">
        <header class="modal-header">
          <div @click="$emit('update:active', false)">
            <Icon type="close" size="24px" class="close-icon" />
          </div>
          <div class="control">
            <transition name="fade">
              <label v-show="isFocus || !isEmpty" class="placeholder-label" for="input-countries">
                {{ $t('account.login.searchCountryCode') }}
              </label>
            </transition>
            <input
              id="input-countries"
              v-autofocus
              type="text"
              class="has-icon"
              :class="{ 'is-empty': isEmpty }"
              :placeholder="isFocus ? '' : $t('account.login.searchCountryCode')"
              :value="searchInput"
              @focus="isFocus = true"
              @blur="isFocus = false"
              @input="handleSearch"
            />
            <Icon type="search-input" size="32px" class="search-icon" :class="{ 'is-active': isFocus }" />
          </div>
        </header>
        <CountryList :countries="countryOptions" @click="selectCountry" />
      </section>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { mixin as clickaway } from 'vue3-click-away';
  import Country from '@/helpers/Country';
  import CountryList from '@/components/CountryList.vue';
  import { autofocus } from '@/helpers/directives';
  import Icon from '@/components/Icon.vue';

  export default defineComponent({
    directives: {
      autofocus,
    },
    components: {
      Icon,
      CountryList,
    },
    mixins: [clickaway],
    props: {
      active: { type: Boolean, required: true },
    },
    emits: ['select-country', 'update:active'],
    data() {
      return {
        isFocus: false,
        searchInput: '',
      };
    },
    computed: {
      isEmpty() {
        return this.searchInput.trim() === '';
      },
      countryOptions() {
        const countries = Country.getCountryData();
        const options = this.mapCountries(countries);
        const search = this.searchInput.toLowerCase();
        return this.searchFilter(search, options);
      },
    },
    watch: {
      active(next) {
        if (!next) this.searchInput = '';
      },
    },
    methods: {
      handleSearch(e: Event) {
        const { value } = e.target as HTMLInputElement;
        this.searchInput = value;
      },
      selectCountry(country) {
        this.$emit('select-country', country);
      },
      mapCountries(countries) {
        return countries
          .map(({ iso2, localName = '', dialCode }) => ({
            code: iso2,
            label: this.$t(`countries.${iso2}`),
            dialCode,
            localName,
          }))
          .sort((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
          });
      },
      searchFilter(search, items) {
        if (!search) return items;
        return items.filter(
          (item) =>
            item.label.toLowerCase().includes(search) ||
            (item.localName && item.localName.toLowerCase().includes(search)),
        );
      },
    },
  });
</script>

<style lang="scss" scoped>
  .control {
    position: relative;
    overflow: hidden;
  }

  .country-list-modal {
    width: 100%;
    background-color: $color-white;
    overflow: hidden;

    @media (min-width: $device-medium) {
      min-width: $device-small;
      width: $device-small;
      border-radius: 8px;
    }
  }

  .placeholder-label {
    position: absolute;
    text-align: left;
    width: 100%;
    top: 4px;
    color: $color-secondary;
    font-size: $size-small;
    font-weight: $weight-medium;
    pointer-events: none;
    white-space: nowrap;
  }

  .search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.5rem;
    overflow: hidden;

    &:not(.is-active) {
      opacity: 0.5;
    }
  }

  .close-icon {
    position: absolute;
    right: 4px;
    top: 4px;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  input.has-icon,
  .placeholder-label {
    padding-left: 3rem;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.12s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .modal {
    z-index: 200;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: none;
  }

  .modal-background {
    z-index: 201;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $color-grey;
    opacity: 0.6;

    .is-widget & {
      border-radius: 8px;
    }
  }

  .modal-content {
    position: relative;
    z-index: 202;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media (min-width: $device-medium) {
      align-items: center;
    }
  }

  .modal-header {
    position: relative;
    background-color: $color-offwhite;
    font-size: $size-regular;
    text-align: center;
    padding: 2em 2em 1em;
  }
</style>
