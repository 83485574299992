{
  "selectCountry": "Selecciona el país",
  "AF": "Afganistán",
  "AX": "Islas Åland",
  "AL": "Albania",
  "DZ": "Argelia",
  "AS": "Samoa Americana",
  "AD": "Andorra",
  "AO": "Angola",
  "AI": "Anguila",
  "AQ": "Antártida",
  "AG": "Antigua y Barbuda",
  "AR": "Argentina",
  "AM": "Armenia",
  "AW": "Aruba",
  "AU": "Australia",
  "AT": "Austria",
  "AZ": "Azerbaiyán",
  "BS": "Bahamas",
  "BH": "Bahrein",
  "BD": "Bangladesh",
  "BB": "Barbados",
  "BY": "Belarús",
  "BE": "Bélgica",
  "BZ": "Belice",
  "BJ": "Benin",
  "BM": "Bermudas",
  "BT": "Bhután",
  "BO": "Bolivia (Estado Plurinacional de)",
  "BQ": "Bonaire, San Eustaquio y Saba",
  "BA": "Bosnia y Herzegovina",
  "BW": "Botswana",
  "BV": "Isla Bouvet",
  "BR": "Brasil",
  "IO": "Territorio Británico del Océano Índico",
  "BN": "Brunei Darussalam",
  "BG": "Bulgaria",
  "BF": "Burkina Faso",
  "BI": "Burundi",
  "CV": "Cabo Verde",
  "KH": "Camboya",
  "CM": "Camerún",
  "CA": "Canadá",
  "KY": "Islas Caimán",
  "CF": "República Centroafricana",
  "TD": "Chad",
  "CL": "Chile",
  "CN": "China",
  "CX": "Isla de Navidad",
  "CC": "Islas Cocos (Keeling)",
  "CO": "Colombia",
  "KM": "Comoras",
  "CG": "Congo",
  "CD": "Congo (República Democrática del)",
  "CK": "Islas Cook",
  "CR": "Costa Rica",
  "CI": "Costa de Marfil",
  "HR": "Croacia",
  "CU": "Cuba",
  "CW": "Curazao",
  "CY": "Chipre",
  "CZ": "Chequia",
  "DK": "Dinamarca",
  "DJ": "Djibouti",
  "DM": "Dominica",
  "DO": "República Dominicana",
  "EC": "Ecuador",
  "EG": "Egipto",
  "SV": "El Salvador",
  "GQ": "Guinea Ecuatorial",
  "ER": "Eritrea",
  "EE": "Estonia",
  "ET": "Etiopía",
  "FK": "Islas Malvinas (Falkland)",
  "FO": "Islas Feroe",
  "FJ": "Fiji",
  "FI": "Finlandia",
  "FR": "Francia",
  "GF": "Guayana Francesa",
  "PF": "Polinesia Francesa",
  "TF": "Territorios Australes Franceses",
  "GA": "Gabón",
  "GM": "Gambia",
  "GE": "Georgia",
  "DE": "Alemania",
  "GH": "Ghana",
  "GI": "Gibraltar",
  "GR": "Grecia",
  "GL": "Groenlandia",
  "GD": "Granada",
  "GP": "Guadalupe",
  "GU": "Guam",
  "GT": "Guatemala",
  "GG": "Guernsey",
  "GN": "Guinea",
  "GW": "Guinea-Bissau",
  "GY": "Guyana",
  "HT": "Haití",
  "HM": "La isla Heard y las islas McDonald",
  "VA": "La Santa Sede",
  "HN": "Honduras",
  "HK": "Hong Kong",
  "HU": "Hungría",
  "IS": "Islandia",
  "IN": "India",
  "ID": "Indonesia",
  "IR": "Irán (República Islámica del)",
  "IQ": "Iraq",
  "IE": "Irlanda",
  "IM": "Isla de Man",
  "IL": "Israel",
  "IT": "Italia",
  "JM": "Jamaica",
  "JP": "Japón",
  "JE": "Jersey",
  "JO": "Jordania",
  "KZ": "Kazajistán",
  "KE": "Kenya",
  "KI": "Kiribati",
  "KP": "Corea (República Popular Democrática de)",
  "KR": "Corea (República de)",
  "KW": "Kuwait",
  "KG": "Kirguistán",
  "LA": "República Democrática Popular Lao",
  "LV": "Letonia",
  "LB": "Líbano",
  "LS": "Lesotho",
  "LR": "Liberia",
  "LY": "Libia",
  "LI": "Liechtenstein",
  "LT": "Lituania",
  "LU": "Luxemburgo",
  "MO": "Macao",
  "MK": "Macedonia (la ex República Yugoslava de)",
  "MG": "Madagascar",
  "MW": "Malawi",
  "MY": "Malasia",
  "MV": "Maldivas",
  "ML": "Malí",
  "MT": "Malta",
  "MH": "Islas Marshall",
  "MQ": "Martinica",
  "MR": "Mauritania",
  "MU": "Mauricio",
  "YT": "Mayotte",
  "MX": "México",
  "FM": "Micronesia (Estados Federados de)",
  "MD": "Moldavia (República de)",
  "MC": "Mónaco",
  "MN": "Mongolia",
  "ME": "Montenegro",
  "MS": "Montserrat",
  "MA": "Marruecos",
  "MZ": "Mozambique",
  "MM": "Myanmar",
  "NA": "Namibia",
  "NR": "Nauru",
  "NP": "Nepal",
  "NL": "Países Bajos",
  "NC": "Nueva Caledonia",
  "NZ": "Nueva Zelanda",
  "NI": "Nicaragua",
  "NE": "Níger",
  "NG": "Nigeria",
  "NU": "Niue",
  "NF": "Isla de Norfolk",
  "MP": "Islas Marianas del Norte",
  "NO": "Noruega",
  "OM": "Omán",
  "PK": "Pakistán",
  "PW": "Palau",
  "PS": "Palestina, Estado de",
  "PA": "Panamá",
  "PG": "Papúa Nueva Guinea",
  "PY": "Paraguay",
  "PE": "Perú",
  "PH": "Filipinas",
  "PN": "Pitcairn",
  "PL": "Polonia",
  "PT": "Portugal",
  "PR": "Puerto Rico",
  "QA": "Qatar",
  "RE": "Reunión",
  "RO": "Rumania",
  "RU": "Federación de Rusia",
  "RW": "Rwanda",
  "BL": "San Bartolomé",
  "SH": "Santa Elena, Ascensión y Tristán da Cunha",
  "KN": "San Cristóbal y Nieves",
  "LC": "Santa Lucía",
  "MF": "San Martín (parte francesa)",
  "PM": "San Pedro y Miquelón",
  "VC": "San Vicente y las Granadinas",
  "WS": "Samoa",
  "SM": "San Marino",
  "ST": "Santo Tomé y Príncipe",
  "SA": "Arabia Saudita",
  "SN": "Senegal",
  "RS": "Serbia",
  "SC": "Seychelles",
  "SL": "Sierra Leona",
  "SG": "Singapur",
  "SX": "San Martín (parte holandesa)",
  "SK": "Eslovaquia",
  "SI": "Eslovenia",
  "SB": "Islas Salomón",
  "SO": "Somalia",
  "ZA": "Sudáfrica",
  "GS": "Georgia del Sur y las Islas Sándwich del Sur",
  "SS": "Sudán del Sur",
  "ES": "España",
  "LK": "Sri Lanka",
  "SD": "Sudán",
  "SR": "Surinam",
  "SJ": "Svalbard y Jan Mayen",
  "SZ": "Swazilandia",
  "SE": "Suecia",
  "CH": "Suiza",
  "SY": "República Árabe Siria",
  "TW": "Taiwán, Provincia de China",
  "TJ": "Tayikistán",
  "TZ": "Tanzanía, República Unida de",
  "TH": "Tailandia",
  "TL": "Timor-Leste",
  "TG": "Togo",
  "TK": "Tokelau",
  "TO": "Tonga",
  "TT": "Trinidad y Tabago",
  "TN": "Túnez",
  "TR": "Turquía.",
  "TM": "Turkmenistán",
  "TC": "Islas Turcas y Caicos",
  "TV": "Tuvalu",
  "UG": "Uganda",
  "UA": "Ucrania",
  "AE": "Emiratos Árabes Unidos",
  "GB": "Reino Unido de Gran Bretaña e Irlanda del Norte",
  "US": "Estados Unidos de América",
  "UM": "Islas Ultramarinas Menores de Estados Unidos",
  "UY": "Uruguay",
  "UZ": "Uzbekistán",
  "VU": "Vanuatu",
  "VE": "Venezuela (República Bolivariana de)",
  "VN": "Vietnam",
  "VG": "Islas Vírgenes (Británicas)",
  "VI": "Islas Vírgenes (EE.UU.)",
  "WF": "Wallis y Futuna",
  "EH": "Sahara Occidental",
  "YE": "Yemen",
  "ZM": "Zambia",
  "ZW": "Zimbabwe"
}
