{
  "global": {
    "language": "Español",
    "actionClose": "Cerrar",
    "actionBack": "Volver",
    "actionCancel": "Cancelar",
    "optional": "opcional",
    "head": {
      "title": "@:brand.name",
      "description": "¿Vendes o revendes entradas honestas? @:brand.nameShort elimina el fraude de billetes y los precios exorbitantes, gracias a la tecnología de cadenas de bloqueo. Para cada evento. ¡Regístrate! Desde el teatro hasta el concierto o el partido deportivo: con @:brand.nameShort la venta y reventa de entradas es simple, transparente y segura.",
      "twitter": "@:brand.twitter",
      "event": {
        "title": "Entradas para {title}",
        "open": "Ordena tus honestas entradas para {title}, {date} en {location} en @:brand.name",
        "queue": "Ordena tus honestas entradas para {title} en @:brand.name",
        "resale": "Revisa todas las entradas en nuestro Ticket Market para {title}, {date} en {location} en @:brand.name",
        "reseller": "Mis entradas están a la venta para {title}, {date} en {location} en @:brand.name. ¡Consíguelas mientras puedas!"
      }
    },
    "fullscreen": "Ir a pantalla completa para una mejor experiencia",
    "cookiesConsent": {
      "description": "Utilizamos cookies para una mejor experiencia.",
      "actionAccept": "Aceptar cookies",
      "actionMore": {
        "name": "Leer más"
      }
    },
    "logout": {
      "logoutText": "Cierre de sesión",
      "logoutConfirmText": "¿Estás seguro? Al iniciar sesión nuevamente, recibirás un nuevo código de acceso por medio de un mensaje de texto."
    },
    "navigation": {
      "account": "Cuenta",
      "eventList": "Eventos",
      "myTickets": "Mis entradas",
      "login": "Inicio de sesión",
      "profile": "Perfil"
    }
  },
  "brand": {
    "name": "#.name",
    "nameShort": "#.nameShort",
    "twitter": "#.twitter",
    "homepageUrl": "#.homepageUrl",
    "homepageUrlShort": "#.homepageUrlShort",
    "supportUrl": "#.supportUrl",
    "livestreamSupportUrl": "#.livestreamSupportUrl",
    "privacyPolicyUrl": "#.privacyPolicyUrl",
    "termsUrl": "#.termsUrl",
    "androidAppstoreUrl": "#.androidAppstoreUrl",
    "appleAppstoreUrl": "#.appleAppstoreUrl"
  },
  "validation": {
    "errors": {
      "required": "Este campo no puede estar en blanco",
      "email": "Esta dirección de correo electrónico parece ser inválida",
      "phoneNumber": "Este no es un número de móvil válido",
      "rateLimit": {
        "message": "Demasiados intentos de verificación.",
        "minutes": "Inténtalo de nuevo en 2 minutos.",
        "seconds": "Inténtalo de nuevo en {seconds} segundos."
      },
      "globalBlock": {
        "message": "Too many verification attempts.",
        "minutes": "Please try again in a few minutes.",
        "seconds": "Please try again in {seconds} seconds."
      },
      "birthdate": "Esta dirección de correo electrónico no parece ser válida"
    }
  },
  "messages": {
    "saveProfile": "¡Perfil salvado con éxito!",
    "seated": "¿Pediste entradas con asiento? Recibirás tus números de asiento por lo menos 24 horas antes del evento, ¡a través del correo electrónico y en nuestra aplicación @:brand.nameShort!",
    "seatsAssigned": "Nota: si estás comprando entradas con asiento de diferentes personas, no podemos garantizar que sus asientos sean contiguos."
  },
  "errors": {
    "default": "Ups! ¡Uy! Algo salió mal...<br/>Podrías <a onClick='window.location.reload(false)'>actualizar esta página</a> e intentar de nuevo.",
    "notStarted": "La venta de entradas no ha comenzado todavía. Comprueba si tu ordenador/teléfono está configurado para la fecha y hora correctas.",
    "invalidURL": "Error: el código de acceso es inválido o ya se ha utilizado.",
    "saveProfile": "Error: no se pudo guardar la información del perfil.",
    "exceeding_limits": "Estás excediendo el número máximo de boletos que puedes comprar.",
    "token_different_user": "Si iniciaste sesión con un número de móvil diferente al que se te proporcionó al entrar en la cola, utiliza el botón Actualizar para volver a entrar con el número correcto.",
    "token_expired": "Tu sesión en esta tienda ha caducado. Utiliza el botón Actualizar para volver a entrar",
    "paymentCanceled": "Has cancelado tu pago. Puedes volver a intentar pagar si quieres.",
    "paymentFailed": "Tu pago falló de alguna manera. Puedes volver a intentar pagar si quieres.",
    "invalid_order_state": "Tu pedido ha expirado o ya ha sido pagado. Puedes reordenar las entradas si están disponibles.",
    "orderNotFound": "El pedido solicitado (ya) no es válido. Puede reordenar las entradas si están disponibles.",
    "user_cannot_share": "Esta cuenta no tiene permiso para compartir entradas.",
    "already_in_group": "Ya eres parte de otro grupo para este evento específico. Prueba abandonando el otro grupo primero.",
    "user_cannot_leave": "No puedes abandonar porque tú comenzaste este grupo.",
    "group_is_full": "No puedes unirte a este grupo porque ya está lleno.",
    "expired_code": "Esta invitación ha expirado. Pídele a tu amigo una nueva invitación.",
    "invalid_code": "Este código de invitación es inválido.",
    "system_unavailable": "Nuestro servicio no está disponible en este momento por problemas técnicos. Estamos trabajando actualmente en buscar una solución. Te pedimos disculpas por las molestias.",
    "payment": {
      "canceledOrFailed": "El emisor ha cancelado o rechazado tu pago. Tu pedido sigue siendo válido. Si lo deseas, puedes volver a intentarlo.",
      "genericError": "Algo ha ido mal con el proveedor del servicio de pago. Si el problema continúa, los pagos no podrán realizarse en este momento. Inténtalo de nuevo más tarde."
    },
    "reservationInvitation": {
      "invalid_state": "I am sorry, there is no valid invitation associated with this link!",
      "claimed": "This invitation has already been claimed!",
      "expired": "This invitation is no longer valid!",
      "unkown_error": "Something went wrong! Please try again later."
    }
  },
  "notFound": {
    "head": {
      "title": " @:global.head.title",
      "complement": "Página no encontrada"
    },
    "title": "Esta página no se puede encontrar",
    "description": "¿Quizás estás buscando un evento del cual la (pre)venta no ha comenzado o no se conoce todavía? ¡Entonces, por favor, inténtelo de nuevo más tarde! ¿Quieres saber más sobre @:brand.name?",
    "action": "Ve a @:brand.homepageUrlShort"
  },
  "appstore": {
    "footer": "Instala la aplicación @:brand.name en tu smartphone. ¡La forma más fácil de entrar!",
    "action": "Instalar la aplicación",
    "install": {
      "title": "Instalar la aplicación móvil @:brand.name",
      "description": "Elige tu tipo de teléfono móvil para continuar en la tienda de aplicaciones:",
      "forAndroid": "Instala la app @:appstore.android.name",
      "forIos": "Instala la app  @:appstore.ios.name",
      "alsoAndroid": "También disponible para @:appstore.android.name",
      "alsoIos": "También disponible para @:appstore.ios.name"
    },
    "android": {
      "name": "Android",
      "store": "Google Play Store",
      "description": "Descubre en @:appstore.android.name",
      "url": "@:brand.androidAppstoreUrl"
    },
    "ios": {
      "name": "iPhone (iOS)",
      "store": "Apple App Store",
      "description": "Descarga en el  @:appstore.ios.name",
      "url": "@:brand.appleAppstoreUrl"
    }
  },
  "queue": {
    "start": {
      "queue": {
        "header": {
          "title": "Introduce la cola de eventos",
          "description": "Tus entradas serán adjuntadas a tu número de móvil: ¡comprueba tus datos!"
        },
        "actionEnter": "Introduce la cola"
      },
      "preQueue": {
        "header": {
          "title": "La venta comienza en {day} {month} a las {time}",
          "description": "Únete a la cola previa y recibirás automáticamente una posición aleatoria para la venta de entradas a las {time}."
        },
        "actionEnter": "Introduce la cola previa"
      },
      "form": {
        "phonePlaceholder": "Tu número de móvil",
        "emailPlaceholder": "Tu dirección de correo electrónico",
        "focusPhonePlaceholder": "+31612345678",
        "focusEmailPlaceholder": "correoelectrónico{'@'}gmail.com"
      },
      "privacy": {
        "description": "Al introducir tus datos, te unes a la cola y estás de acuerdo con {0}.",
        "name": "nuestra política de privacidad"
      }
    },
    "allEventsPast": "Este evento ha expirado y las entradas ya no están disponibles.",
    "countdown": {
      "positionInfoZero": "Casi es tu turno, espera...",
      "positionInfoOne": "Hay {numberInFront} persona delante de ti.",
      "positionInfoMultiple": "Hay {numberInFront} personas delante de ti.",
      "calculatePosition": "Calculando tu posición",
      "approx": "aprox.",
      "minutes": "minutos | minuto | minutos",
      "hour": "Más de una hora...",
      "soldout": "no hay oportunidad (agotado)",
      "calculateChance": "¡Llegaste rápido! Estamos calculando cuán rápido van las ventas y mostraremos tus posibilidades aquí en un minuto.",
      "single": "Tienes {chance} de que haya entradas disponibles para cuando sea tu turno.",
      "groupSingle": "Tienes {chance} de que haya entradas disponibles para tu evento favorito, para cuando sea tu turno",
      "groupMultiple": "Tienes la oportunidad {chance} que habrá entradas disponibles para al menos uno de tus {count} eventos favoritos, para cuando sea tu turno.",
      "groupEmtpy": "Selecciona tus eventos favoritos y elige fácilmente uno cuando sea tu turno.",
      "selectFav": "Seleccionar favoritos",
      "changeFav": "Ajustar favoritos"
    },
    "eventList": {
      "otherData": "Todos los eventos",
      "soldout": "Agotado",
      "allEvents": "Ver todos los eventos",
      "favoriteEvents": "Tus favoritos",
      "search": "Filtro en la ubicación",
      "showsIn": "No hay eventos en | 1 evento en | {count} eventos en",
      "other": "Otros lugares",
      "modal": {
        "title": "Selecciona tu(s) evento(s) favorito(s)",
        "subtitle": "Cuando sea tu turno, tus eventos favoritos aparecerán primero en la lista que puedes elegir. ¡Tranquilo!"
      },
      "few_available": "casi agotado",
      "no_available": "agotado",
      "tickets_in_carts": "agotado"
    },
    "preQueue": {
      "starting": {
        "title": "Asignando la posición...",
        "description": "Tendrás una posición aleatoria en la cola, para que todos tengan una oportunidad justa de comprar entradas. Espera."
      },
      "waiting": {
        "title": "Cuenta regresiva para {time}",
        "description": "Cuando se abra la cola de venta obtendrás una posición aleatoria y tendrás que esperar hasta que sea tu turno para comprar las entradas."
      },
      "footer": {
        "noNumber": "Te registraste con tu número de móvil. Si no es correcto, no podrás comprar entradas: {link} para volver a introducirlo.",
        "description": "Te registraste con móvil {number}. Si el número no es correcto, no podrás comprar entradas: {link} para volver a introducirlo.",
        "link": "Ve aquí"
      }
    },
    "paused": {
      "queuePaused": "Cola en pausa",
      "details": "Debido a la extrema demanda, actualmente no dejamos que nadie pase por la cola. Un momento, por favor..."
    },
    "turn": {
      "single": {
        "title": "¡Tu turno!",
        "description": "Ahora puedes pedir tus entradas",
        "order": "Pedir"
      },
      "group": {
        "title": "¡Tu turno!",
        "description": "Selecciona un evento. Siempre puedes volver aquí para elegir uno diferente."
      }
    },
    "open": {
      "title": "Elige un evento"
    },
    "notStarted": {
      "description": "La venta de entradas aún no ha comenzado. Empezará en {date} a las {time}, por favor, vuelva entonces.",
      "action": "Ver eventos",
      "support": "Por favor, consulta nuestra {0} para obtener más información sobre el proceso de pedido.",
      "supportLink": "página de apoyo"
    },
    "soldout": {
      "title": "Este evento está actualmente agotado",
      "details": "Lástima, todas las entradas disponibles se han vendido. Existe la posibilidad de que las entradas se vendan de nuevo, ¡entonces asegúrate de volver a comprobarlo más tarde otra vez!"
    },
    "access": {
      "description": "Esta tienda solo es accesible mediante los enlaces facilitados por el organizador del evento. Una vez que hayas solicitado acceso a una tienda, siempre podrás volver.",
      "checkAccess": "Comprobar acceso"
    }
  },
  "shop": {
    "global": {
      "tickets": "Entradas | Entrada | Entradas",
      "total": "Total",
      "free": "Libres",
      "serviceCosts": "costos del servicio",
      "serviceCostsLong": "Incluyendo @:shop.global.serviceCosts",
      "serviceCostsShort": "Incl. @:shop.global.serviceCosts",
      "buyerFee": "Incluye una comisión de {fee}",
      "floorplan": "Plano de la planta",
      "back": "Volver a la tienda"
    },
    "tabs": {
      "info": "Información",
      "seated": "y asientos"
    },
    "eventInfo": {
      "openDoors": "Abrir",
      "startTime": "Comenzar",
      "endTime": "Finalizar",
      "openMap": "Abrir en el mapa",
      "timezone": "Nota: la zona horaria es {timezone}"
    },
    "floorplan": {
      "description": "Selecciona la sección deseada en el plano de planta para comprar fácilmente las entradas.",
      "action": "Ordena usando el plano de planta",
      "list": "O selecciona tu nivel y sección de la lista de abajo."
    },
    "resale": {
      "actionBack": "Volver a la tienda principal",
      "resaleLink": "Verifica el Ticket Market",
      "available": "{count} disponibles",
      "originalPrice": "Precio más bajo {price}",
      "showMore": "Mostrar más entradas",
      "checkMore": "Comprobar si hay más entradas",
      "refreshMessage": "Parece que algunas de estas entradas ya han sido revendidas y la lista actual podría estar desactualizada. ¿Te gustaría actualizar la lista?",
      "emptyMessage": "Actualmente, nadie ofrece entradas a la venta en nuestro Ticket Market. Vuelva más tarde, o actualiza, porque la gente puede ofrecer sus entradas en cualquier momento!",
      "noOthers": "Actualmente, las únicas entradas disponibles son ofrecidas por {seller}. Por favor, marca arriba, o actualiza.",
      "noOthersFallback": "Actualmente, las únicas entradas disponibles figuran en la lista de arriba. También puedes actualizarla.",
      "resellerItems": {
        "manyResellers": "{names} y otros {others_count}",
        "isSelling": " está vendiendo entradas",
        "noTickets": "Esta persona (ya) no tiene ninguna entrada a la venta.",
        "others": "Entradas ofrecidas por otros:"
      },
      "resaleItem": {
        "price": "Entradas por {price} cada | {count} entrada por {price} | {count} entradas por {price} cada",
        "priceUpsell": "Por {price} cada una | {count} por {price} | {count} por {price} cada una",
        "seller": "Ofrecido por {name}",
        "justSold": {
          "warning": "¡Vendido hace un momento!",
          "message": "Alguien se te adelantó."
        }
      },
      "resaleHeader": {
        "title": "¡Las entradas en esta tienda están agotadas!",
        "description": "Sin embargo: cualquiera con boletos puede ofrecerlos a la venta en cualquier momento en nuestro Ticket Market!"
      },
      "feesInfo": {
        "title": "Explicación de las comisiones del mercado de reventa",
        "description": "Se aplica una comisión de servicio del {percentage} a cada entrada.",
        "descriptionWithFixedFees": "Se aplica una comisión de servicio del {percentage} más unos costes de transacción de {fixed} a cada entrada.",
        "buyerFee": "Comisión del comprador",
        "totalPrice": "Precio que pagas",
        "footerWithFixedFees": "Para el vendedor, se aplica una comisión de servicio del {percentage} más unos costes de transacción de {fixed} a cada entrada. Esto ya se incluye en tu precio.",
        "footer": "Para el vendedor, se aplica una comisión de servicio del {percentage} a cada entrada. Esto ya se incluye en tu precio"
      }
    },
    "tickets": {
      "soldout": "Agotado",
      "ticketsLeft": "Quedan {left}",
      "tutorial": "¿Necesitas ayuda?",
      "upsell": "Suplementos"
    },
    "footer": {
      "maxTickets": "Max {amount} entrada por persona | Max {amount} entradas por persona",
      "userMaxTickets": "No puedes pedir más entradas | Puedes pedir más {amount} entrada | Puedes pedir más {amount} entrada",
      "maxTicketsLeft": "de {amount} máx",
      "actionOrder": "Pedir",
      "actionNext": "Siguiente"
    },
    "state": {
      "past": {
        "title": "Este evento ya ha tenido lugar",
        "description": "No hay más entradas disponibles."
      },
      "soldoutWithoutResale": {
        "title": "Todas las entradas están (actualmente) agotadas",
        "description": "Es posible que se vuelvan a vender más entradas en un futuro próximo."
      }
    },
    "datepicker": {
      "toggle": "Selecciona un día diferente",
      "legend": {
        "title": "Elige un día para comprar tus entradas",
        "soldout": "Estos días están actualmente agotados",
        "hasEvents": "Las entradas todavía están disponibles en estos días"
      },
      "shopList": {
        "shopCount": "Hay {count} eventos en este día",
        "description": "Elige un evento para comprar tus entradas"
      },
      "noEvents": {
        "titleMonth": "No hay eventos este mes",
        "descriptionMonth": "No hay eventos este mes. Usa el calendario para elegir otro mes y examinar la agenda.",
        "titleDay": "No hay eventos este día",
        "descriptionDay": "No hay eventos este día. Usa el calendario para elegir otro día y examinar la agenda."
      }
    },
    "loadingMessage": {
      "wait": "Espera un momento",
      "contacting": "Contactando con la tienda",
      "browser": "No cierres el navegador",
      "fetching": "Buscando entradas disponibles",
      "starting": "Iniciando tienda",
      "populating": "Rellenando tienda",
      "opening": "Abriendo tienda"
    },
    "wallet-connect": {
      "notification": {
        "title": "Check your eligibility to buy tickets",
        "description": {
          "main": "This ticket shop is only accessible if you hold the required <a target=\"_blank\" href=\"https://support.guts.tickets/hc/en-001/articles/4410619860242-What-is-an-Event-Collectible\">digital collectible(s)</a> in your wallet.",
          "login": "Please login with your mobile number and verify ownership of your wallet that holds the collectible(s), to check your eligibility.",
          "verifyAccess": "Verify that you own one of the required digital collectibles by logging in and connecting your wallet."
        },
        "button": {
          "login": "LOG IN",
          "verify": "VERIFY ACCESS"
        }
      },
      "connect": {
        "title": "Connect your wallet",
        "description": "To access the shop, you need to hold a collectible from one of the following collections:",
        "button": "Connect wallet"
      },
      "searching": {
        "title": "Checking Collectibles in your wallet..."
      },
      "notFound": {
        "title": "Required collectibles not found...",
        "description": "You may try and connect a different wallet to check for your collectibles again",
        "button": "Connect different wallet"
      },
      "sign": {
        "title": "Confirm ownership by signing",
        "description": "We need to verify your ownership of the connected wallet by signing a message through your wallet",
        "button": "Sign via wallet"
      },
      "success": {
        "title": "Access to the shop granted!",
        "description": "We have successfully verified ownership of your wallet and it holds the required collectibles needed to access this ticket-shop",
        "button": "Proceed to Shop"
      },
      "connectedWallet": "Connected wallet",
      "previousWallet": "Previously connected wallet",
      "disconnect": "Disconnect",
      "searched": "Required collection(s)",
      "status": {
        "not-found": "Not found",
        "found": "Found",
        "no-collections": "Error: no collections to check were found."
      }
    }
  },
  "account": {
    "head": {
      "title": "Cuenta"
    },
    "login": {
      "title": "Verifica tu número de teléfono móvil",
      "description": "Introduce tu número de móvil incluyendo el código de tu país. Recibirás un código de acceso a través de un mensaje de texto.",
      "phonePlaceholder": "Tu número de móvil",
      "focusPhonePlaceholder": "+44123456789",
      "actionVerify": "Verificar",
      "searchCountryCode": "Busca el código de tu país",
      "selectCountry": "Selecciona tu país",
      "mobileInputDescription": "Debes incluir el código de tu país, por ejemplo, +1"
    },
    "callUser": {
      "title": "¿No has recibido un código?",
      "description": "Nuestro robot puede llamarte y leer tu código de verificación en voz alta.",
      "incorrectNumber": "Mi número de teléfono no es correcto",
      "action": "Llamadme al {phone}",
      "calling": "Te llamaremos ahora"
    },
    "verify": {
      "title": "Revisa tus mensajes de texto",
      "description": "Se ha enviado un mensaje de texto a {number}. Introduce el código de 4 dígitos.",
      "triesLeft": "Tu código no es correcto. Puedes intentarlo de nuevo {times}.",
      "time": "{tries} vez | {tries} veces",
      "requestCodeCountdown": "El reenvío estará disponible en {countdown} segundos."
    },
    "captcha": {
      "notice": "Esta función está protegida por reCAPTCHA y se aplican la {privacy} y las {tos} de Google.",
      "privacy": "Política de privacidad",
      "tos": "Condiciones del servicio",
      "title": "Por favor, verifica que eres humano",
      "description": "Selecciona la casilla de abajo para demostrar que no eres un bot. De vez en cuando te pedimos esto para evitar a spammers y estafadores."
    }
  },
  "profile": {
    "head": {
      "title": "Mi perfil"
    },
    "title": "Mi perfil",
    "description": "Tu perfil podría ser revisado en un evento; así que, asegúrate de que la información sea correcta.",
    "mandatory": "Los campos marcados con un * son obligatorios.",
    "additonalInfoTitle": "Información adicional",
    "additonalInfoDescription": "El organizador del evento solicita la siguiente información adicional.",
    "terms": "Estoy de acuerdo <a href=@:brand.termsUrl target=\"_blank\">con los términos y condiciones</a> de @:brand.nameShort <small>(obligatorio)</small>",
    "optIn": "@:brand.nameShort puede compartir mis datos con los artistas I comprar entradas para mí e informarme sobre las noticias y los próximos eventos, como se indica en <a href=@:brand.privacyPolicyUrl target=\"_blank\">nuestra política de privacidad</a>.",
    "firstName": "Nombre *",
    "lastName": "Apellidos *",
    "email": "Dirección de correo electrónico *",
    "gender": "Género",
    "male": "Hombre",
    "female": "Mujer",
    "other": "Otro",
    "birthdate": "Fecha de nacimiento",
    "dateProperties": {
      "day": "día",
      "month": "mes",
      "year": "año"
    },
    "country": "País",
    "state": "Estado",
    "zipcode": "Código postal",
    "address": "Nombre de la calle & número de la casa",
    "city": "Ciudad",
    "nft_address": {
      "wallet_address": "Wallet address",
      "title": "Define la dirección de tu billetera de NFT",
      "description_off": "Receive event collectibles on your own existing Web3 wallet. By default a Web3 wallet will be created for you.",
      "description_on": "Receive event collectibles on your own Web3 wallet by connecting it once below. Collectibles are distributed on the Polygon blockchain.",
      "description_connected": "Your event collectibles will be sent to the wallet connected below on the Polygon blockchain. Reset to switch to a different wallet.",
      "help": "¿No tienes ni idea de qué significa? <a href=\"{url}\" target=\"_blank\">Más información</a>",
      "overview": "Recibirás tus NFT coleccionables digitales en la siguiente dirección de billetera de Ethereum / Polygon",
      "reset": "Restablecer",
      "edit": "Edit",
      "confirmation": {
        "title": "¿Seguro?",
        "description": "Esto te permitirá definir una dirección de billetera diferente para cualquier reclamación futura de coleccionables digitales, o simplemente déjalo en blanco para no volver a reclamar."
      }
    },
    "actionSave": "Guardar"
  },
  "order": {
    "head": {
      "title": "Tu pedido"
    },
    "overview": {
      "credits": {
        "applied": "Créditos aplicados",
        "optIn": "Utilizar mis créditos para pagar este pedido",
        "appliedDescription": "Tienes {amount} créditos adjuntos a este pedido.",
        "availableDescription": "Tienes {amount} créditos restantes en tu cuenta. Estos créditos valen para todos los eventos de {org} y caducan el {expirationDate}."
      },
      "organisationName": "el organizador",
      "termsOptIn": {
        "url": "Estoy de acuerdo con los <a href=\"{url}\" target=\"_blank\">términos y condiciones</a> de {name} <small>(obligatorio)</small>",
        "custom": "{label} - <a target=\"_blank\" href=\"{url}\">Términos y condiciones</a> <small>(obligatorio)</small>",
        "text": "<span>Estoy de acuerdo con los términos y condiciones de {name} <small>(obligatorio)</small></span><p>{text}</p>"
      },
      "privacyOptIn": {
        "url": "Quiero que informen de los eventos y promociones y estoy de acuerdo con la <a href=\"{url}\" target=\"_blank\">declaración de privacidad</a> de {name}",
        "custom": "{label} - <a target=\"_blank\" href=\"{url}\">Política de privacidad</a>",
        "text": "<span>Quiero que me informen de los eventos y promociones y estoy de acuerdo con la declaración de privacidad de {name}</span><p>{text}</p>"
      },
      "splitMarketTerms": "Acepto los <a href=\"{url}\" target=\"_blank\">términos y condiciones</a> del mercado de reventa de GUTS Netherlands B.V.",
      "timeLeft": "Ahora tienes 15 minutos para completar tu pedido",
      "actionFree": "Confirmar",
      "actionPaid": "Paga"
    },
    "giftcard": {
      "title": "Tengo un código de tarjeta regalo o vale",
      "description": "Introduce tu código a continuación par canjearlo",
      "action": "Canjear",
      "placeholder": "Por ejemplo: ‘321BDFK874’",
      "label": "Código de tarjeta regalo o vale",
      "modal": {
        "label": "Código de tarjeta regalo/vale válido",
        "title": "Valor",
        "description": "Esto es lo que sucederá",
        "description-one": "{amount} se abonará en tu cuenta",
        "description-two-a": "Puedes utilizar estos créditos en",
        "description-two-b": "todos los eventos de {org}",
        "description-three": "Caducarán el",
        "action": "Canjear",
        "cancel": "Ahora no"
      },
      "error": {
        "empty": "Introduce un código válido de tarjeta regalo o cupón",
        "invalid": "Este código no es válido",
        "expired": "Este código ha caducado",
        "used": "Este código ya se ha utilizado",
        "wrong_organizer": "{org} no puede utilizar este código",
        "generic": "Algo ha ido mal. El código no se puede canjear"
      }
    },
    "payment": {
      "ideal": "Elige tu banco iDeal",
      "other": "Elige tu método de pago",
      "noPaymentOptions": "No hay métodos de pago disponibles por el momento. Intenta actualizar esta página. Si el problema persiste, <a href=\"@:brand.supportUrl\" target=\"_blank\">por favor, contáctanos</a>."
    },
    "paymentLoader": {
      "redirecting": "Estás siendo redirigido a {method}...",
      "error": "{method} parece estar teniendo problemas.\nInténtalo de nuevo o elige otro método de pago..."
    },
    "status": {
      "pending": {
        "title": "Tu pedido está siendo procesado..."
      },
      "paid": {
        "title": "¡El pedido ha sido exitoso!",
        "description": "Se ha enviado una confirmación a {email}, asegúrate de revisar tu carpeta de correo electrónico no deseado.",
        "descriptionAnonymous": "An order confirmation has been sent to you - if it's not there, be sure to check your spam. ",
        "actionTickets": "Ir a las entradas online"
      },
      "timeout": {
        "title": "Aguanta ahí....",
        "description": "Desafortunadamente no hemos podido confirmar tu pago todavía. Tan pronto como lo hagamos, recibirás una confirmación en {email}."
      },
      "error": {
        "title": "El pedido falló...",
        "description": "Algo salió mal al completar tu pedido. Puedes intentar pedir entradas de nuevo.",
        "actionBack": "Volver al evento"
      }
    },
    "app": {
      "title": "Entradas desde @:brand.nameShort son compatibles con tu smartphone, ¡entonces instala la aplicación!",
      "description": "Beneficios del uso de la aplicación:",
      "imageAlt": "@:brand.name aplicación de cartera para iPhone y Android",
      "featureList": [
        "Las entradas digitales de la aplicación te permiten entrar en el evento.",
        "Vende entradas facilmente si no puedes hacerlo.",
        "Regala entradas o compártelas con tus amigos."
      ]
    }
  },
  "tickets": {
    "index": {
      "head": {
        "title": "Mis eventos"
      },
      "title": "Mis próximos eventos",
      "noEventsTitle": "No hay eventos próximos",
      "noEventsDescription": "No tienes entradas para eventos próximos."
    },
    "details": {
      "head": {
        "title": "Entradas"
      },
      "actionBack": "Mis eventos",
      "inactive": {
        "title": "Tu código QR está reservado de forma segura",
        "description": "Aparecerá automáticamente en {unlockDate}."
      },
      "active": {
        "title": "Escanea este código QR en el evento",
        "description": "Este código funciona para todas tus entradas y amigos."
      },
      "notScannable": {
        "title": "No hay entradas para registrar",
        "description": "Todas tus entradas ya están registradas, vendidas o devueltas."
      },
      "noCopies": "Las copias o impresiones no funcionarán",
      "seating": {
        "seatsAppear": "Los números de asientos se asignan el día anterior al evento.",
        "entrance": "Entrada",
        "section": "Sección",
        "row": "Fila",
        "seats": "Asiento"
      },
      "group": {
        "info": "Grupo de {activeUsers}/{groupSize}",
        "leave": "Abandonar",
        "confirmLeave": "¿Estás seguro de que quieres dejar este grupo?"
      },
      "livestream": {
        "watchStream": "Mira ahora.",
        "upsell": {
          "shopLink": "Ir a la tienda",
          "warning": "Nota: No has comprado ninguna entrada",
          "description": "Solo has comprado suplementos. Si también querías comprar entradas, aún puedes hacerlo."
        },
        "inactive": {
          "title": "Acceder a la transmisión en vivo",
          "description": "La transmisión en vivo será accesible aquí mismo en {unlockDate}."
        },
        "unauthorized": {
          "title": "No hay acceso a la transmisión en vivo",
          "description": "No has comprado el acceso a esta transmisión en vivo"
        },
        "active": {
          "title": "¡La transmisión ha comenzado!",
          "description": "Tienes acceso exclusivo a esta transmisión en vivo."
        }
      },
      "footer": {
        "title": "¿Quieres compartir o vender tus entradas?",
        "description": "Instala la aplicación móvil y haz más."
      }
    }
  },
  "invite": {
    "title": "Invitación de",
    "shareTickets": "{ticketAmount} entrada compartida con todo el mundo | {ticketAmount} entradas compartidas con todo el mundo",
    "group": "{personAmount} persona ya está en el grupo | {personAmount} personas ya están en el grupo",
    "confirmAccept": "¿Estás seguro de que quieres unirte a este grupo?",
    "confirmDecline": "¿Estás seguro de que no quieres unirte a este grupo?",
    "warning": "<strong>Sólo acepta si conoces y confías en {inviterName}!</strong>\nTodos en este grupo tienen los mismos derechos, incluyendo el derecho a invitar a otros y a vender entradas para este evento, ¡incluyendo las tuyas!",
    "invite": "Invitación de",
    "accept": "aceptar",
    "decline": "rechazar"
  },
  "pendingPayment": {
    "title": "Advertencia: Pago pendiente",
    "pay": "Pagar",
    "wait": "Esperar",
    "existingPayment": "Ya has iniciado un pago para este pedido.",
    "confirmationEmail": "Recibirás un correo electrónico de confirmación en breve si has completado este pago.",
    "confirm": "¿Quieres iniciar un nuevo pago o esperar?"
  },
  "reservationInvitation": {
    "claimAction": "Claim tickets",
    "status": {
      "title": "Great, the tickets are yours!"
    }
  }
}
