import { App } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import status from '@/router/routes/preview/shop/order/status';
import overview from '@/router/routes/preview/shop/order/overview';
import { OrderState, PaymentStatuses, VUEJONES_URL } from '@/constants';
import { Order as OrderType, Payment } from '@/store/modules/order/types';
import { Store } from '@/store/types';
import PostMessageRequest from '@/helpers/PostMessageRequest';

const Order = () => import('@/views/shop/Order.vue');

export default (app: App, store: Store) =>
  ({
    path: '/preview/:slug/:shopSlug/:shopType/orders/:id',
    component: Order,
    meta: {
      preview: true,
      permissions: {
        access: (): boolean => store.state.is_preview,
      },
    },
    children: [status(app, store), overview(app, store)],
    beforeEnter: async (to, _, next) => {
      const { commit, state, getters } = store;
      const {
        shop: { event },
      } = state;

      const { shopType } = to.params;

      const ticketKinds = getters['shop/ticketList'];

      // sum the ticket total
      let items = ticketKinds.map((tk) => ({
        kind: tk.id,
        rank: tk.name,
        amount: tk.amountInCart || 0,
        buyer_fee: '0.00',
        seller_fee: '0.00',
        sub: tk.price,
        kind_price: tk.price,
        upsell: tk.upsell,
      }));

      // Filter out any items with 0 quantity
      items = items.filter((item) => item?.amount > 0);

      const total = items.reduce((acc, item) => acc + parseFloat(item.sub) * (item.amount || 0), 0);

      let success_text = 'PREVIEW';

      if (shopType === 'preview') {
        const previewPermissionRequester = new PostMessageRequest(
          window,
          window.parent,
          VUEJONES_URL,
          { request: 'request_success_text' },
          (res) => typeof res === 'string',
        );

        const text = await previewPermissionRequester.request().then((res) => res.data);

        if (text) {
          success_text = text;
        }
      }

      const order: OrderType = {
        id: 0,
        ticket_total: `${total}`,
        total: `${total}`,
        buyer_fee: '0.00',
        pending_payments: false,
        seller_fee: '0.00',
        state: OrderState.COMPLETED,
        last_payment_status: PaymentStatuses.PAID,
        details: {
          event: '',
          event_id: event?.id || 0,
          title: event?.title || '',
          items,
          currency: { name: 'Euro', code: 'EUR', decimals: 2 },
          shop_name: 'preview',
          shop_url: 'preview',
        },
        success_text,
        credits: '0.00',
        created: '',
        owner: 0,
        currency: 'EUR',
        amount_deducted: '0.00',
        refunded: '0.00',
        market: 'primary',
        is_secondary_market: false,
        order_type: 'preview',
      };

      const payment: Payment = {
        amount: `${total}`,
        state: PaymentStatuses.PAID,
        payment_method: '',
        order_id: 0,
        paymentid: '0',
        id: 0,
        refunded: 'false',
        ticket_ids: items.map((tk) => tk.kind),
      };

      commit('order/set_order', order);
      commit('order/set_payment', payment);

      next();
    },
  } as RouteRecordRaw);
