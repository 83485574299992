import { App } from 'vue';
import { RouteLocationNormalized } from 'vue-router';
import { PermissionsData } from '@/router/types';
import { GateState } from '@/constants';
import { Store } from '@/store/types';

const Resale = () => import('@/views/shop/Resale.vue');

export default (app: App, store: Store) => ({
  path: 'resale',
  name: 'resale',
  component: Resale,

  meta: {
    gate: true,
    showNavbar: true,
    shop: true,

    permissions: {
      access: (data: PermissionsData, to: RouteLocationNormalized): boolean => {
        const { gate: { queue, gate_token } = {} } = data;
        const { shopSlug } = to.params;
        const { state } = store;
        if (!queue) return false;

        if (!queue.shop_slugs.find((s) => s === shopSlug)) return false;

        if (!state.gate) return false;

        const hasStarted = queue.time_to_start <= 0;
        if (!hasStarted && state.is_preview) return true;

        if (queue.state === GateState.SOLDOUT || !hasStarted) return false;

        if (gate_token) return true;

        return false;
      },
      redirect: (data: PermissionsData, to: RouteLocationNormalized) => {
        const {
          gate: { queue },
        } = data;
        const {
          params: { shopSlug },
          path,
          fullPath,
        } = to;
        const { state } = store;

        if (!queue || (!!queue.shop_slugs.length && !state.gate)) {
          return { name: 'notFound', params: { pathMatch: path.substring(1).split('/') } };
        }

        if (!queue.shop_slugs.find((s) => s === shopSlug)) return { name: 'queue' };

        return {
          name: 'queue',
          query: { next: fullPath },
        };
      },
    },
  },
});
