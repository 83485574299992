import { App } from 'vue';
import Gatekeeper from '@/views/Gatekeeper.vue';
import queue from '@/router/routes/gatekeeper/queue';
import shop from '@/router/routes/gatekeeper/shop';
import { Store } from '@/store/types';

export default (app: App, store: Store) => ({
  path: '/:slug',
  component: Gatekeeper,
  children: [queue(app, store), shop(app, store)],
  beforeEnter: async (to, from, next) => {
    const cover = store.state.gate?.queue?.cover;

    if (cover) {
      store.commit('set_layout_cover', { cover });
    }

    next();
  },
});
