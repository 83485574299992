<template>
  <a class="action-back" @click="$emit(action)">
    <Icon type="chevron-right" color="inherit" size="1.5rem" class="icon" />
    {{ backText }}
  </a>
</template>
<script>
  import Icon from '@/components/Icon.vue';

  export default {
    components: {
      Icon,
    },
    props: {
      action: {
        type: String,
        required: true,
      },
      text: {
        type: String,
        default: '',
      },
    },
    computed: {
      backText() {
        return this.text ? this.text : this.$i18n.t('global.actionBack');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .action-back {
    color: $color-primary;
    color: var(--color-theme);
    display: inline-block;
    font-weight: $weight-medium;
    padding: 0.75rem 0.75rem 0.25rem 0.25rem;
    cursor: pointer;

    .icon {
      transition: transform ease-in 0.12s;
      transform: rotate(180deg);
      vertical-align: middle;
      transform: translate(6px, -1px) rotate(180deg);

      fill: $color-primary;
      fill: var(--color-theme);
    }

    &:hover,
    &:focus,
    &:active {
      .icon {
        transform: translate(4px, -1px) rotate(180deg);
      }
    }
  }
</style>
