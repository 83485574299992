import { App, reactive } from 'vue';
import View from '@/components/loader/ProgressBar.vue';

type ReactiveProgressBarProps = {
  show: boolean;
  isFailed: boolean;
  isRunning: boolean;
  percent: number;
};

export class ProgressBar {
  _timer: number | ReturnType<typeof setTimeout> | undefined;

  _speed: number;

  _isInit: boolean;

  _reactive_props: ReactiveProgressBarProps;

  constructor(reactive_props?: ReactiveProgressBarProps) {
    this._timer = undefined;
    this._speed = 0;
    this._isInit = true;
    this._reactive_props = reactive_props || {
      show: false,
      isFailed: false,
      isRunning: false,
      percent: 0,
    };
  }

  start() {
    this._reactive_props.percent = 0;
    this._reactive_props.isRunning = true;
    this._reactive_props.show = true;
    this._reactive_props.isFailed = false;

    this._clear_timer();

    this._timer = setInterval(() => {
      // set speed relative to progress to slow down when time increases
      this._speed = 10 - this._reactive_props.percent * 0.1;
      this.increase(this._speed * Math.random());

      if (this._reactive_props.percent > 99) {
        this.finish();
      }
    }, 132);
  }

  set(num) {
    this._reactive_props.show = true;
    this._reactive_props.isFailed = false;
    this._reactive_props.percent = Math.floor(num);
  }

  get() {
    return Math.floor(this._reactive_props.percent);
  }

  increase(num) {
    this._reactive_props.percent = Math.min(99, this._reactive_props.percent + Math.floor(num));
  }

  decrease(num) {
    this._reactive_props.percent -= Math.floor(num);
  }

  hide() {
    this._reactive_props.isRunning = false;
    this._clear_timer();
    // hide global loader from index.html after initial load
    if (this._isInit) {
      this._isInit = false;

      const globalLoader = document.getElementById('global-loader');

      if (globalLoader && globalLoader.parentNode) {
        setTimeout(() => {
          globalLoader.parentNode?.removeChild(globalLoader);
        }, 150); // make transition to vue view-loader smooth
      }
    }
    setTimeout(() => {
      if (!this._reactive_props.isRunning) {
        this._reactive_props.show = false;
        this._reactive_props.percent = 0;
      }
    }, 200);
  }

  pause() {
    this._clear_timer();
  }

  finish() {
    this._reactive_props.percent = 100;
    this.hide();
  }

  fail() {
    this._reactive_props.isFailed = true;
    this._reactive_props.percent = 100;
    this.hide();
  }

  _clear_timer() {
    if (this._timer) {
      // TODO: typescript seems buggy, it requires a timeout to be sent but it should actually be a number
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      clearInterval(this._timer);
      this._timer = undefined;
    }
  }
}

// based on https://github.com/aacassandra/vue3-progressbar
export default {
  install: (app: App) => {
    const reactiveBar = reactive({
      PROGRESS_BAR: {
        percent: 0,
        show: false,
        isRunning: false,
        isFailed: false,
      },
    });
    const progressBar = new ProgressBar(reactiveBar.PROGRESS_BAR);

    app.provide('PROGRESS_BAR', reactiveBar.PROGRESS_BAR);

    app.component('ProgressBar', View);
    app.config.globalProperties.$Progress = progressBar;
  },
};
