import { configureScope } from '@sentry/browser';
import { RootStoreable as S, User } from '@/store/types/models';
import { ErrorMessage } from './types/types';

const mutations = {
  set_user(state: S, user: User | null) {
    state.user = user;

    // TODO - decouple
    configureScope((scope) => {
      scope.setUser(user && user.id ? { id: user.id.toString() } : {});
    });
  },
  set_cookie_consent_display(state: S, display: boolean) {
    state.cookie_consent_display = display;
  },
  set_global_error(state: S, error: ErrorMessage) {
    state.global_error = { ...error };
  },
  set_layout_cover(state: S, { cover }: { cover: string }) {
    state.layout_cover = { cover };
  },
  set_is_preview(state: S, preview: boolean) {
    state.is_preview = preview;
  },
};

export default mutations;
