import { App } from 'vue';
import list from '@/router/routes/tickets/list';
import details from '@/router/routes/tickets/details';
import helpers from '@/helpers';
import { VUESCERA_URL } from '@/constants';
import Tickets from '@/views/tickets/index.vue';
import { Store } from '@/store/types';

export default (app: App, store: Store) => ({
  path: '/tickets',
  component: Tickets,
  children: [list(app, store), details(app, store)],
  beforeEnter: async (to, from, next) => {
    if (helpers.isWidget()) {
      try {
        const token = app.config.globalProperties.storage.getItem('rt');

        window.location.assign(`${VUESCERA_URL}/tickets?sso=${token}`);
      } catch (_) {
        window.location.assign(`${VUESCERA_URL}/tickets`);
      }
      return;
    }
    next();
  },
});
