import { authRefresh, GP_HttpError, GUSRequestConfig, http_request, promiseQueue } from '@getprotocollab/get-pal';
import { captureException } from '@sentry/browser';
import { SharedStorage } from '@/plugins/SharedStorage';

export const createSetAuthToken = (config) => (token: string) => {
  config.headers.set('Authorization', `JWT ${token}`);
};

export const createClearAuthtoken = (config) => () => {
  config.headers.delete('Authorization');
};

export const createAuthRefreshMiddleware = (
  gusConfig: GUSRequestConfig,
  setAuthToken: (token: string) => void,
  clearAuthToken: () => void,
) => {
  const onAuthRefresh = async () => {
    const storage = SharedStorage.getInstance();
    const refreshToken = storage.getItem('rt');
    if (!refreshToken) {
      throw new Error('no_refresh_token');
    }

    const response = await authRefresh(gusConfig, { refresh_token: refreshToken });

    if (response.data?.status === 'error') {
      storage.removeItem('rt');
      clearAuthToken();
      throw new Error(response.data.error);
    }

    const jwtToken = response.data?.token || '';
    const newRefreshToken = response.data?.refresh_token || '';

    storage.setItem('rt', newRefreshToken);
    setAuthToken(jwtToken);
  };

  const refreshSubsription = promiseQueue(onAuthRefresh);
  const authRefreshMiddleware = async (res: any): Promise<any> => {
    if (res instanceof GP_HttpError) {
      const { response, request } = res;

      const dontFailOn = ['jwt_token_expired', 'Authentication credentials were not provided.'];

      if (!request || !response) throw res;
      if (response.status !== 401) throw res;
      if (!dontFailOn.includes(response.data.detail)) throw res;
      try {
        await refreshSubsription();
      } catch (e: any) {
        captureException(e, { tags: { error_group: 'refresh_fail' } });
        throw res;
      }

      return http_request(request);
    }

    if (res instanceof Error) throw res;
    return res;
  };

  return authRefreshMiddleware;
};
