import { GUSRequestConfig, TicketServiceRequestConfig } from '@getprotocollab/get-pal';
import { createAuthRefreshMiddleware } from './helpers';

// eslint-disable-next-line import/prefer-default-export
export const initRefreshMiddlewares = (
  ticketAPI: {
    setAuthToken: (token: string) => void;
    clearAuthToken: () => void;
    config: TicketServiceRequestConfig;
  },
  gusAPI: {
    setAuthToken: (token: string) => void;
    clearAuthToken: () => void;
    config: GUSRequestConfig;
  },
) => {
  const setAuthToken = (token: string) => {
    ticketAPI.setAuthToken(token);
    gusAPI.setAuthToken(token);
  };

  const clearAuthToken = () => {
    ticketAPI.clearAuthToken();
    gusAPI.clearAuthToken();
  };

  const middleware = createAuthRefreshMiddleware(gusAPI.config, setAuthToken, clearAuthToken);

  (ticketAPI.config?.errorMiddleware || []).push(middleware);
  (gusAPI.config?.errorMiddleware || []).push(middleware);
};
