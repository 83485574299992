<template>
  <div v-if="isActive" class="progress-bar" role="progressbar" :class="{ 'is-failed': isFailed }">
    <div class="bar" :style="style"></div>
  </div>
</template>

<script>
  export default {
    name: 'ProgressBar',
    inject: ['PROGRESS_BAR'],
    computed: {
      style() {
        const style = {
          width: `${this.progress}%`,
        };
        return style;
      },
      progress() {
        return this.PROGRESS_BAR.percent;
      },
      isActive() {
        return this.PROGRESS_BAR.show;
      },
      isFailed() {
        return this.PROGRESS_BAR.isFailed;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .progress-bar {
    pointer-events: none;
    width: 100%;
    height: 2px;
    max-width: 100%;
    overflow: hidden;
  }

  .bar {
    position: relative;
    width: 100%;
    height: 100%;
    @include background-gradient;
    transition: width 0.12s ease-out;

    .is-failed & {
      background: $color-error;
    }
  }
</style>
