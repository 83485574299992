import { App } from 'vue';
import { RouteLocationNormalized } from 'vue-router';
import { PermissionsData } from '@/router/types';
import { Store } from '@/store/types';

const Queue = () => import('@/views/Queue.vue');

export default (app: App, store: Store) => ({
  path: '',
  name: 'queue',
  component: Queue,
  meta: {
    gate: true,
    showNavbar: false,
    permissions: {
      access: (data: PermissionsData): boolean => {
        const {
          gate: { queue, shops },
        } = data;
        if (!queue || !shops) return false;
        return true;
      },

      redirect: (data: PermissionsData, to: RouteLocationNormalized) => {
        const { query } = to;
        return { name: 'notFound', params: { pathMatch: to.path.substring(1).split('/') }, query };
      },
    },
  },
});
