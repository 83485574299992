/* eslint-disable class-methods-use-this */
import COUNTRIES from '@/helpers/countries';

let instance = null;

class Country {
  static getInstance() {
    if (!instance) {
      instance = new Country();
      instance._initCodes();
    }
    return instance;
  }

  constructor() {
    this.dialCodes = {};
  }

  getCountryData() {
    return COUNTRIES;
  }

  _getDialCodes() {
    return this.getCountryData().reduce((codes, { dialCode, priority, areaCodes, iso2 }) => {
      codes[dialCode] = codes[dialCode] || [];
      const index = priority || 0;
      codes[dialCode][index] = iso2;

      if (areaCodes) {
        areaCodes.forEach((code) => {
          codes[dialCode + code] = codes[dialCode + code] || [];
          codes[dialCode + code].push(iso2);
        });
      }

      return codes;
    }, {});
  }

  _initCodes() {
    this.dialCodes = this._getDialCodes();
  }

  getCountriesByDialCode(numeric) {
    return this.dialCodes[numeric];
  }

  getCountryByIso(iso) {
    const activeCountry = this.getCountryData().filter((value) => value.iso2 === iso);
    return activeCountry[0];
  }

  getCountryCodeOfNumber(number) {
    const dialCode = this.getDialCodeByNumber(number);
    const numeric = this.getNumeric(dialCode);
    const countryCodes = this.getCountriesByDialCode(numeric);

    if (countryCodes) {
      return countryCodes.filter((iso2) => iso2)[0];
    }
    return '';
  }

  getDialCodeByNumber(number) {
    let dialCode = '';
    // only interested in international numbers (starting with a plus)
    if (number[0] === '+') {
      let numericChars = '';
      // iterate over chars
      for (let i = 0; i < number.length; i += 1) {
        const c = number[i];
        // if char is number
        if (this.isNumeric(c)) {
          numericChars += c;
          // if current numericChars make a valid dial code
          if (this.getCountriesByDialCode([numericChars])) {
            // store the actual raw string (useful for matching later)
            dialCode = number.substr(0, i + 1);
          }
          // longest dial code is 4 chars
          if (numericChars.length === 4) {
            break;
          }
        }
      }
    }
    return dialCode;
  }

  getNumeric(str) {
    return str.replace(/\D/g, '');
  }

  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n); // eslint-disable-line
  }
}

export default Country.getInstance();
