{
  "global": {
    "language": "Nederlands",
    "actionClose": "Sluiten",
    "actionBack": "Terug",
    "actionCancel": "Annuleer",
    "optional": "optioneel",
    "head": {
      "title": "@:brand.name",
      "description": "Eerlijke tickets kopen of verkopen? @:brand.nameShort stopt ticketfraude en woekerprijzen dankzij blockchaintechnologie. Voor alle evenementen. Sluit je aan! Van theater tot concert of sportwedstrijd: met @:brand.nameShort is het kopen en verkopen van tickets voor een eerlijke prijs eenvoudig, transparant en superveilig.",
      "twitter": "@:brand.twitter",
      "event": {
        "title": "Tickets voor {title}",
        "open": "Bestel jouw eerlijke tickets voor {title} op {date} in {location} via @:brand.name",
        "queue": "Bestel jouw eerlijke tickets voor {title} via @:brand.name",
        "resale": "Bekijk alle tickets op onze Ticketmarkt voor {title}, {date} in {location} via @:brand.name",
        "reseller": "Mijn tickets staan te koop voor {title}, {date} in {location} via @:brand.name. Koop ze nu het nog kan!"
      }
    },
    "fullscreen": "Open in volledig scherm",
    "cookiesConsent": {
      "description": "Sta cookies toe voor een betere ervaring.",
      "actionAccept": "Accepteren",
      "actionMore": {
        "name": "Lees meer"
      }
    },
    "logout": {
      "logoutText": "Uitloggen",
      "logoutConfirmText": "Weet je het zeker? Als je weer inlogt ontvang je een nieuwe toegangscode per sms."
    },
    "navigation": {
      "account": "Account",
      "eventList": "Evenementen",
      "myTickets": "Mijn tickets",
      "login": "Inloggen",
      "profile": "Profiel"
    }
  },
  "brand": {
    "name": "#.name",
    "nameShort": "#.nameShort",
    "twitter": "#.twitter",
    "homepageUrl": "#.homepageUrl",
    "homepageUrlShort": "#.homepageUrlShort",
    "supportUrl": "#.supportUrl",
    "livestreamSupportUrl": "#.livestreamSupportUrl",
    "privacyPolicyUrl": "#.privacyPolicyUrl",
    "termsUrl": "#.termsUrl",
    "androidAppstoreUrl": "#.androidAppstoreUrl",
    "appleAppstoreUrl": "#.appleAppstoreUrl"
  },
  "validation": {
    "errors": {
      "required": "Dit veld is verplicht",
      "email": "Dit e-mailadres is niet correct",
      "phoneNumber": "Dit is is geen geldig mobiel nummer",
      "rateLimit": {
        "message": "Te veel verificatiepogingen.",
        "minutes": "Probeer het opnieuw in 2 minuten.",
        "seconds": "Probeer het opnieuw over {seconds} seconden."
      },
      "globalBlock": {
        "message": "Te veel verificatiepogingen.",
        "minutes": "Probeer het over een paar minuten opnieuw.",
        "seconds": "Probeer het opnieuw over {seconds} seconden."
      },
      "birthdate": "Deze geboortedatum is niet correct"
    }
  },
  "messages": {
    "saveProfile": "Profiel succesvol opgeslagen!",
    "seated": "Zitplaatsen besteld? Uiterlijk 24 uur voor het evenement ontvang je de stoelnummers via e-mail en in de @:brand.nameShort app!",
    "seatsAssigned": "Let op: Als je zitplaatsen van verschillende personen koopt, kunnen we niet garanderen dat de zitplaatsen aaneengesloten zijn."
  },
  "errors": {
    "default": "Oeps! Er is iets misgegaan...<br/><a onClick='window.location.reload(false)'>Refresh de pagina</a> eventueel en probeer het nogmaals.",
    "notStarted": "De ticketverkoop is nog niet gestart. Controleer of je computer of telefoon of de datum & tijd correct is ingesteld.",
    "invalidURL": "Error: Toegangscode is ongeldig of of al gebruikt.",
    "saveProfile": "Error: Profiel kon niet opgeslagen worden.",
    "exceeding_limits": "Je overschrijdt het maximale aantal tickets dat je mag aanschaffen.",
    "token_different_user": "Ben je ingelogd met een andere telefoonnummer dan je hebt opgegeven bij het ingaan van de wachtrij? Gebruik dan de refresh knop om de ticketshop opnieuw in te gaan met het juiste nummer.",
    "token_expired": "Je sessie voor deze ticketshop is verlopen, gebruik de refresh knop om opnieuw toegang te krijgen tot de ticketshop.",
    "paymentCanceled": "Je hebt de betaling geannuleerd. Je kunt eventueel opnieuw een betaling doen.",
    "paymentFailed": "Je betaling is helaas mislukt. Je kunt eventueel opnieuw een betaling doen.",
    "invalid_order_state": "Je bestelling is verlopen of al betaald. Je kunt opnieuw tickets bestellen als deze beschikbaar zijn.",
    "orderNotFound": "Sorry, deze sessie is verlopen. Klik <a onClick='window.location.reload(false)'>hier</a> om de pagina te vernieuwen en verder te gaan.",
    "user_cannot_share": "Dit account heeft geen toestemming om tickets te delen.",
    "already_in_group": "Je maakt al deel uit van een andere groep voor dit specifieke evenement. Probeer eerst de andere groep te verlaten.",
    "user_cannot_leave": "Je kunt deze groep niet verlaten omdat jij deze hebt gestart.",
    "group_is_full": "Je kunt niet lid worden van deze groep omdat deze al vol is",
    "expired_code": "Deze uitnodiging is verlopen. Vraag je vriend om een nieuwe uitnodiging.",
    "invalid_code": "Deze uitnodigings-code is niet geldig.",
    "system_unavailable": "Ons platform is momenteel onbereikbaar vanwege een technische storing. Wij werken momenteel aan een oplossing. Excuses voor het ongemak.",
    "payment": {
      "canceledOrFailed": "Je betaling is geannuleerd of geweigerd, maar je order is nog steeds actief. Probeer het gerust opnieuw.",
      "genericError": "Er is iets misgegaan met je betaling. Indien het probleem zich blijft voordoen, zijn betalingen op dit moment waarschijnlijk niet mogelijk. Probeer het later opnieuw."
    },
    "reservationInvitation": {
      "invalid_state": "I am sorry, there is no valid invitation associated with this link!",
      "claimed": "This invitation has already been claimed!",
      "expired": "This invitation is no longer valid!",
      "unkown_error": "Something went wrong! Please try again later."
    }
  },
  "notFound": {
    "head": {
      "title": "@:global.head.title",
      "complement": "Pagina niet gevonden"
    },
    "title": "Deze pagina kon niet worden gevonden",
    "description": "Wellicht ben je op zoek naar een evenement waarvan de (voor)verkoop nog niet is gestart of nog niet bekend is. Probeer het dan later nogmaals! Meer weten over @:brand.name?",
    "action": "Naar @:brand.homepageUrlShort"
  },
  "appstore": {
    "footer": "Installeer de @:brand.name app op je smartphone. De gemakkelijkste manier om binnen te komen!",
    "action": "Installeer app",
    "install": {
      "title": "Installeer de @:brand.name app op je smartphone",
      "description": "Kies jouw type mobiele telefoon om naar de app store te gaan:",
      "forAndroid": "Installeer voor @:appstore.android.name",
      "forIos": "Installeer voor @:appstore.ios.name",
      "alsoAndroid": "Ook beschikbaar voor @:appstore.android.name",
      "alsoIos": "Ook beschikbaar voor @:appstore.ios.name"
    },
    "android": {
      "name": "Android",
      "store": "Google Play Store",
      "description": "Ontdek het op @:appstore.android.name",
      "url": "@:brand.androidAppstoreUrl"
    },
    "ios": {
      "name": "iPhone (iOS)",
      "store": "Apple App Store",
      "description": "Download in de @:appstore.ios.name",
      "url": "@:brand.appleAppstoreUrl"
    }
  },
  "queue": {
    "start": {
      "queue": {
        "header": {
          "title": "Sluit je aan in de wachtrij",
          "description": "Je tickets worden gekoppeld aan je mobiele nummer, controleer je gegevens goed!"
        },
        "actionEnter": "Aansluiten"
      },
      "preQueue": {
        "header": {
          "title": "Verkoop start op {day} {month} om {time}",
          "description": "Schrijf je in voor pre-registratie en je ontvangt automatisch een willekeurige positie voor de ticket verkoop om {time}."
        },
        "actionEnter": "Aansluiten"
      },
      "form": {
        "phonePlaceholder": "Jouw mobiele nummer",
        "emailPlaceholder": "Jouw e-mailadres",
        "focusPhonePlaceholder": "+31612345678",
        "focusEmailPlaceholder": "emailadres{'@'}gmail.com"
      },
      "privacy": {
        "description": "Door jouw gegevens in te voeren sluit je aan in de wachtrij en ga je akkoord met {0}.",
        "name": "onze privacyverklaring"
      }
    },
    "allEventsPast": "Dit evenement is verstreken en er zijn geen tickets meer verkrijgbaar.",
    "countdown": {
      "positionInfoZero": "Je bent bijna aan de beurt...",
      "positionInfoOne": "Er is nog maar {numberInFront} wachtenden voor je.",
      "positionInfoMultiple": "Er zijn nog {numberInFront} wachtenden voor je.",
      "calculatePosition": "Je positie wordt berekend...",
      "approx": "circa",
      "minutes": "minuten | minuut | minuten",
      "hour": "Meer dan 1 uur...",
      "soldout": "geen tickets",
      "calculateChance": "Jij bent snel! Binnen een klein minuutje zal hier een indicatie van jouw kans op tickets verschijnen.",
      "single": "Helaas, er zijn momenteel {noTickets} meer beschikbaar.",
      "groupSingle": "Helaas, er zijn momenteel {noTickets} meer beschikbaar voor je favoriete evenement. | Helaas, er zijn momenteel {noTickets} meer beschikbaar voor je favoriete evenementen.",
      "groupMultiple": "Helaas, er zijn momenteel {noTickets} meer beschikbaar voor {soldOutCount} van jouw {favoritesCount} favoriete evenementen.",
      "groupEmtpy": "Markeer vast je favoriete evenementen om snel een keuze te maken als je aan de beurt bent.",
      "selectFav": "Stel favorieten in",
      "changeFav": "Pas favorieten aan"
    },
    "eventList": {
      "otherData": "Alle evenementen",
      "soldout": "Uitverkocht",
      "allEvents": "Bekijk alle evenementen",
      "favoriteEvents": "Jouw favorieten",
      "search": "Filter op locatie",
      "showsIn": "Geen evenement in | 1 evenement in | {count} evenementen in",
      "other": "Overige locaties",
      "modal": {
        "title": "Markeer alvast je favorieten",
        "subtitle": "Als je straks aan de beurt bent staan je favoriete evenementen en locaties direct bovenaan. Handig!"
      },
      "few_available": "bijna uitverkocht",
      "no_available": "uitverkocht",
      "tickets_in_carts": "uitverkocht"
    },
    "preQueue": {
      "starting": {
        "title": "Positie wordt bepaald...",
        "description": "Je krijgt een willekeurige positie in de wachtrij, zodat iedereen een eerlijke kans heeft om tickets te kopen. Momentje!"
      },
      "waiting": {
        "title": "Aan het aftellen: {time}",
        "description": "Wanneer de wachtrij wordt geopend, krijg je een willekeurige positie en zul je moeten wachten tot het jouw beurt is om ticket te kopen."
      },
      "footer": {
        "noNumber": "Je staat geregistreerd met je mobiele nummer. Als deze niet correct is, kun je geen tickets kopen: {link} om deze te wijzigen.",
        "description": "Je staat geregistreerd met nummer: {number}. Als deze niet correct is, kun je geen tickets kopen: {link} om deze te wijzigen.",
        "link": "Klik hier"
      }
    },
    "paused": {
      "queuePaused": "Wachtrij gepauzeerd",
      "details": "Wegens grote drukte worden er momenteel geen nieuwe wachtenden doorgelaten. Een moment geduld alsjeblieft..."
    },
    "turn": {
      "single": {
        "title": "Jouw beurt!",
        "description": "Je kunt nu jouw tickets bestellen",
        "order": "Bestel"
      },
      "group": {
        "title": "Jouw beurt!",
        "description": "Kies een evenement. Je kunt altijd terug om een andere te kiezen."
      }
    },
    "open": {
      "title": "Kies je evenement"
    },
    "notStarted": {
      "description": "De ticketverkoop is nog niet gestart. Deze start op {date} om {time} dus kom dan nog eens terug.",
      "action": "Bekijk evenementen",
      "support": "Voor informatie over het bestelproces kun je terecht op onze {0}.",
      "supportLink": "support pagina"
    },
    "soldout": {
      "title": "Dit evenement is momenteel uitverkocht",
      "details": "Helaas, alle tickets zijn verkocht. Er is een kans dat kopers hun tickets weer in de verkoop plaatsen, dus probeer het later zeker nog een keer."
    },
    "access": {
      "description": "Deze ticketshop is alleen toegankelijk via access links verstrekt door de event organizer. Als je al toegang hebt verkregen tot de shop, kun je altijd later terugkeren.",
      "checkAccess": "Check toegang"
    }
  },
  "shop": {
    "global": {
      "tickets": "Tickets | Ticket | Tickets",
      "total": "Totaal",
      "free": "Gratis",
      "serviceCosts": "servicekosten",
      "serviceCostsLong": "Inclusief @:shop.global.serviceCosts",
      "serviceCostsShort": "Incl. @:shop.global.serviceCosts",
      "buyerFee": "Inclusief {fee} servicekosten",
      "floorplan": "Plattegrond",
      "back": "Bestelling aanpassen"
    },
    "tabs": {
      "info": "Info",
      "seated": "& zitplaatsen"
    },
    "eventInfo": {
      "openDoors": "Open",
      "startTime": "Start",
      "endTime": "Einde",
      "openMap": "Open op kaart",
      "timezone": "Let op: Tijdzone is {timezone}"
    },
    "floorplan": {
      "description": "Kies op de plattegrond de gewenste sectie om makkelijk tickets te kopen.",
      "action": "Bestel via plattegrond",
      "list": "Of kies je rang en koop tickets via onderstaande lijst."
    },
    "resale": {
      "actionBack": "Terug naar ticket shop",
      "resaleLink": "Bekijk Ticketmarkt",
      "available": "{count} verkrijgbaar",
      "originalPrice": "Laagste prijs {price}",
      "showMore": "Toon meer tickets",
      "checkMore": "Check voor meer tickets",
      "refreshMessage": "Het lijkt erop dat sommige tickets al zijn doorverkocht en dat de huidige lijst mogelijk verouderd is. Wil je de lijst vernieuwen?",
      "emptyMessage": "Momenteel biedt niemand tickets te koop aan via onze Ticketmarkt. Kom later terug of refresh de lijst, want mensen kunnen hun tickets op elk moment aanbieden!",
      "noOthers": "Momenteel worden de enige beschikbare tickets aangeboden door {seller}. Bekijk de tickets hierboven of vernieuw de lijst.",
      "noOthersFallback": "Momenteel worden de beschikbare tickets hierboven getoond.",
      "resellerItems": {
        "manyResellers": "{names} en {others_count} anderen",
        "isSelling": "verkoopt kaarten. | verkopen kaarten.",
        "noTickets": "Alle tickets die deze persoon te koop heeft aangeboden, zijn al verkocht, of worden momenteel door iemand gekocht.",
        "others": "Tickets aangeboden door anderen:"
      },
      "resaleItem": {
        "price": "{count} ticket voor {price} | {count} tickets voor {price} per stuk",
        "priceUpsell": "Voor {price} per stuk | {count} voor {price} | {count} voor {price} per stuk",
        "seller": "Aangeboden door {name}",
        "justSold": {
          "warning": "Net verkocht!",
          "message": "Iemand was je voor"
        }
      },
      "resaleHeader": {
        "title": "Tickets in deze shop zijn uitverkocht!",
        "description": "Maar iedereen kan op elk moment tickets te koop aanbieden op onze Ticketmarkt!"
      },
      "feesInfo": {
        "title": "Ticketmarkt servicekosten uitgelegd",
        "description": "Er wordt {percentage} servicekosten per ticket in rekening gebracht.",
        "descriptionWithFixedFees": "Er wordt {percentage} servicekosten en {fixed} transactiekosten per ticket in rekening gebracht.",
        "buyerFee": "Servicekosten koper",
        "totalPrice": "Prijs die jij betaalt",
        "footerWithFixedFees": "Voor verkopers wordt er {percentage} servicekosten en {fixed} transactiekosten per ticket in rekening gebracht. Dit zit al in jouw prijs inbegrepen.",
        "footer": "Voor verkopers wordt er {percentage} servicekosten per ticket in rekening gebracht. Dit zit al in jouw prijs inbegrepen."
      }
    },
    "tickets": {
      "soldout": "Uitverkocht",
      "ticketsLeft": "Nog {left} over",
      "tutorial": "Hulp nodig?",
      "upsell": "Extra"
    },
    "footer": {
      "maxTickets": "Max. {amount} ticket per persoon | Max. {amount} tickets per persoon",
      "userMaxTickets": "Je kunt geen tickets (meer) bestellen | Je kunt nog {amount} ticket bestellen | Je kunt nog {amount} tickets bestellen",
      "maxTicketsLeft": "van {amount} max",
      "actionOrder": "Bestel",
      "actionNext": "Volgende"
    },
    "state": {
      "past": {
        "title": "Dit evenement heeft al plaatsgevonden",
        "description": "Er zijn geen tickets meer verkrijgbaar."
      },
      "soldoutWithoutResale": {
        "title": "Er zijn momenteel geen tickets beschikbaar",
        "description": "Mogelijk worden er in de nabije toekomst weer meer tickets verkocht."
      }
    },
    "datepicker": {
      "toggle": "Selecteer een andere dag",
      "legend": {
        "title": "Kies een dag om je tickets te kopen",
        "soldout": "Deze dagen zijn momenteel uitverkocht",
        "hasEvents": "Voor deze dagen zijn tickets verkrijgbaar"
      },
      "shopList": {
        "shopCount": "Er zijn {count} evenementen deze dag",
        "description": "Kies een evenement om je tickets te kopen."
      },
      "noEvents": {
        "titleMonth": "Geen evenementen deze maand",
        "descriptionMonth": "Er zijn deze maand geen evenementen. Gebruik de kalender om een andere maand in te toekomst te kiezen en de complete agenda te bekijken.",
        "titleDay": "Er zijn deze dag geen evenementen.",
        "descriptionDay": "Er zijn op deze dag geen evenementen. Gebruik de kalender om andere dag te kiezen en de agenda te bekijken."
      }
    },
    "loadingMessage": {
      "wait": "Een moment geduld alsjeblieft",
      "contacting": "Verbinden met de ticketshop",
      "browser": "Ticketshop wordt geladen",
      "fetching": "Beschikbare tickets aan het laden",
      "starting": "Ticketshop wordt gestart",
      "populating": "Ticketshop wordt geladen",
      "opening": "Ticketshop wordt geopend"
    },
    "wallet-connect": {
      "notification": {
        "title": "Controleer je toegang om tickets te kopen",
        "description": {
          "main": "Deze ticketshop is toegankelijk als je de vereiste <a target=\"_blank\" href=\"https://support.guts.tickets/hc/en-001/articles/4410619860242-What-is-an-Event-Collectible\">digitale collectible(s)</a> in je wallet hebt.",
          "login": "Log in met je mobiele nummer en verifieer eigenaarschap van de wallet die de collectible(s) bevat om je toegang te controleren.",
          "verifyAccess": "Verifieer eigenaarschap van de wallet die de collectible(s) bevat, om je toegang te controleren."
        },
        "button": {
          "login": "INLOGGEN",
          "verify": "TOEGANG VERIFIËREN"
        }
      },
      "connect": {
        "title": "Verbind eerst je wallet!",
        "description": "Om toegang te krijgen tot de shop, moet je een collectible uit de een van de volgende collecties hebben:",
        "button": "Verbind wallet"
      },
      "searching": {
        "title": "Collectibles in je wallet controleren..."
      },
      "notFound": {
        "title": "We hebben de benodigde collectibles niet gevonden",
        "description": "Als je denkt dat dit niet klopt, probeer dan bijv. een andere wallet te connecten en probeer het opnieuw",
        "button": "Connect andere wallet"
      },
      "sign": {
        "title": "Bevestig dat je de eigenaar bent van de wallet",
        "description": "We moeten verifiëren dat je eigenaar bent van de wallet om je toegang te kunnen geven tot de shop",
        "button": "Ondertekenen via wallet"
      },
      "success": {
        "title": "Je hebt toegang tot de shop!",
        "description": "We hebben het eigenaarschap van de wallet die de vereiste collectible(s) bevat succesvol geverifieerd",
        "button": "Ga naar shop"
      },
      "connectedWallet": "Connected wallet",
      "previousWallet": "Eerdere connected wallet",
      "disconnect": "Disconnect wallet",
      "searched": "Gezochte collecties",
      "status": {
        "not-found": "Niet gevonden",
        "found": "Gevonden",
        "no-collections": "Fout: er zijn geen collecties gevonden om te controleren."
      }
    }
  },
  "account": {
    "head": {
      "title": "Account"
    },
    "login": {
      "title": "Verifieer je mobiele nummer",
      "description": "Voer je mobiele nummer in. Je ontvangt een eenmalige toegangscode via sms.",
      "phonePlaceholder": "Jouw mobiele nummer",
      "focusPhonePlaceholder": "+31612345678",
      "actionVerify": "Verifiëren",
      "searchCountryCode": "Zoek jouw landcode",
      "selectCountry": "Selecteer jouw land",
      "mobileInputDescription": "Moet jouw landcode bevatten, bijvoorbeeld +31"
    },
    "callUser": {
      "title": "Geen code ontvangen?",
      "description": "Onze robot kan jou bellen en je verificatiecode hardop voorlezen.",
      "incorrectNumber": "Mijn telefoonnummer klopt niet",
      "action": "Bel mij op {phone}",
      "calling": "Je wordt nu gebeld"
    },
    "verify": {
      "title": "Check je mobiele telefoon",
      "description": "Je toegangscode is verstuurd naar {number}. Voer de 4 cijferige code in.",
      "triesLeft": "Deze toegangscode is niet juist. Je kunt het nog {times} proberen.",
      "time": "{tries} keer",
      "requestCodeCountdown": "Opnieuw verzenden is mogelijk over {countdown} seconden."
    },
    "captcha": {
      "notice": "Deze functie wordt beschermd door reCAPTCHA en de Google {privacy} en {tos} zijn van toepassing.",
      "privacy": "Privacybeleid",
      "tos": "Servicevoorwaarden",
      "title": "Verifiëer dat je geen robot bent",
      "description": "Vink onderstaand vakje aan om te bewijzen dat je geen bot bent. We vragen dit zo nu en dan om spammers en oplichters tegen te houden."
    }
  },
  "profile": {
    "head": {
      "title": "Mijn profiel"
    },
    "title": "Mijn profiel",
    "description": "Je gegevens kunnen gecontroleerd worden bij een evenement, dus zorg dat deze kloppen.",
    "mandatory": "Velden gemarkeerd met * zijn verplicht.",
    "additonalInfoTitle": "Aanvullende informatie",
    "additonalInfoDescription": "De organisator van het evenement vraagt om de volgende aanvullende informatie.",
    "terms": "Ik ga akkoord met de <a href=@:brand.termsUrl target=\"_blank\">algemene voorwaarden</a> van @:brand.nameShort <small>(verplicht)</small>",
    "optIn": "@:brand.nameShort mag mijn gegevens delen met de uitvoerende artiest waar ik tickets voor koop en mij informeren over nieuws en opkomende evenementen. Zie <a href=@:brand.privacyPolicyUrl target=\"_blank\">onze privacyverklaring</a>.",
    "firstName": "Voornaam *",
    "lastName": "Achternaam *",
    "email": "E-mailadres *",
    "gender": "Geslacht",
    "male": "Man",
    "female": "Vrouw",
    "other": "Anders",
    "birthdate": "Geboortedatum",
    "dateProperties": {
      "day": "dag",
      "month": "maand",
      "year": "jaar"
    },
    "country": "Land",
    "state": "Staat",
    "zipcode": "Postcode",
    "address": "Straatnaam & huisnummer",
    "city": "Woonplaats",
    "nft_address": {
      "wallet_address": "Wallet adres",
      "title": "Connect je eigen Web3 Wallet?",
      "description_off": "Ontvang event collectibles op je eigen Web3 wallet. Standaard wordt er een Web3 wallet voor je aangemaakt.",
      "description_on": "Ontvang event collectibles op je eigen Web3 wallet door deze eenmalig te connecten De collectibles worden gedistribueerd via de Polygon blockchain.",
      "description_connected": "Jouw event collectibles zullen verzonden worden naar de hieronder ingevulde wallet op de Polygon blockchain. Reset om van wallet te switchen.",
      "help": "Onbekend met Web3 Wallets? <a href=\"{url}\" target=\"_blank\">Lees meer</a> of sla dit over!",
      "overview": "Je zult je digitale collectibles (NFT's) op het volgende Ethereum / Polygon wallet adres ontvangen",
      "reset": "Reset",
      "edit": "Edit",
      "confirmation": {
        "title": "Weet je het zeker?",
        "description": "Hierdoor kun je een ander wallet adres connecten voor al je toekomstige event collectibles. Als je besluit geen wallet te connecten, dan maken wij een wallet voor je aan."
      }
    },
    "actionSave": "opslaan"
  },
  "order": {
    "head": {
      "title": "Jouw bestelling"
    },
    "overview": {
      "credits": {
        "applied": "Credits toegepast",
        "optIn": "Gebruik mijn credits voor deze bestelling",
        "appliedDescription": "Aan deze bestelling zijn {amount} credits gekoppeld.",
        "availableDescription": "Je hebt {amount} resterende credits in jouw account. Deze credits zijn geldig voor evenementen van {org} en vervallen op {expirationDate}."
      },
      "organisationName": "de organisator",
      "termsOptIn": {
        "url": "Ik ga akkoord met de <a href=\"{url}\" target=\"_blank\">algemene voorwaarden</a> van {name} <small>(verplicht)</small>",
        "custom": "{label} - <a target=\"_blank\" href=\"{url}\">Algemene voorwaarden</a> <small>(verplicht)</small>",
        "text": "<span>Ik ga akkoord met de algemene voorwaarden van {name} <small>(verplicht)</small></span><p>{text}</p>"
      },
      "privacyOptIn": {
        "url": "Ik wil op de hoogte gehouden worden van evenementen en acties en ga akkoord met <a href=\"{url}\" target=\"_blank\">de privacyverklaring</a> van {name}",
        "custom": "{label} - <a target=\"_blank\" href=\"{url}\">privacyverklaring</a>",
        "text": "<span>Ik wil op de hoogte gehouden worden van evenementen en acties en ga akkoord met de privacyverklaring van {name}</span><p>{text}</p>"
      },
      "splitMarketTerms": "Ik ga akkoord met de <a href=\"{url}\" target=\"_blank\">algemene voorwaarden</a> van GUTS Netherlands B.V.",
      "timeLeft": "Je hebt nu 15 minuten om je bestelling af te ronden",
      "actionFree": "Bevestig",
      "actionPaid": "Betalen"
    },
    "giftcard": {
      "title": "Ik heb een cadeaukaart of vouchercode",
      "description": "Voer de code hieronder in om deze te verzilveren.",
      "action": "Verzilver",
      "placeholder": "Bijvoorbeeld: '321BDFK874'",
      "label": "Cadeaukaart of vouchercode",
      "modal": {
        "label": "Geldige cadeaukaart / vouchercode",
        "title": "Waarde",
        "description": "Dit is wat er gaat gebeuren",
        "description-one": "Jouw account wordt gecrediteerd met {amount}",
        "description-two-a": "Je kunt deze credits gebruiken voor",
        "description-two-b": "alle evenementen van {org}",
        "description-three": "Deze verlopen op",
        "action": "Verzilver",
        "cancel": "Niet nu"
      },
      "error": {
        "empty": "Voer een geldige cadeaukaart of vouchercode in",
        "invalid": "Deze code is ongeldig",
        "expired": "Deze code is verlopen",
        "used": "Deze code is al verzilverd",
        "wrong_organizer": "Deze code kan niet worden gebruikt voor evenementen van {org}",
        "generic": "Er is iets misgegaan, code kan niet worden verzilverd"
      }
    },
    "payment": {
      "ideal": "Kies jouw bank voor betaling via iDeal",
      "other": "Kies je betaalmethode",
      "noPaymentOptions": "Er zijn momenteel geen betaalmethoden beschikbaar. Vernieuw deze pagina om het nogmaals te proberen. <a href=\"@:brand.supportUrl\" target=\"_blank\">Neem contact met ons op</a> als het probleem aanhoudt."
    },
    "paymentLoader": {
      "redirecting": "Je wordt doorgestuurd naar {method}...",
      "error": "{method} lijkt een storing te hebben.\nProbeer het nogmaals of kies een andere betalingsmethode..."
    },
    "status": {
      "pending": {
        "title": "Je bestelling is in behandeling..."
      },
      "paid": {
        "title": "Bestelling succesvol!",
        "description": "Een bevestiging is verzonden naar {email} - Niet in je inbox? Check dan je spam folder.",
        "descriptionAnonymous": "An order confirmation has been sent to you - if it's not there, be sure to check your spam. ",
        "actionTickets": "Naar online tickets"
      },
      "timeout": {
        "title": "Nog even geduld...",
        "description": "We hebben je betaling nog niet kunnen bevestigen. Zodra dat is gelukt, ontvang je een bevestiging via {email}."
      },
      "error": {
        "title": "Bestelling mislukt...",
        "description": "Er is iets misgegaan bij het afronden van je bestelling. Je kunt eventueel nogmaals proberen tickets te bestellen.",
        "actionBack": "Terug naar evenement"
      }
    },
    "app": {
      "title": "Tickets van @:brand.nameShort werken op je smartphone, installeer dus de app!",
      "description": "De voordelen van de app op een rijtje:",
      "imageAlt": "@:brand.name Wallet app voor iPhone & Android",
      "featureList": [
        "De digitale tickets in de app geven je toegang tot het evenement.",
        "Verkoop je tickets gemakkelijk als je onverwachts niet kan.",
        "Geef tickets cadeau of deel deze met je vrienden."
      ]
    }
  },
  "tickets": {
    "index": {
      "head": {
        "title": "Mijn evenementen"
      },
      "title": "Mijn evenementen",
      "noEventsTitle": "Geen evenementen",
      "noEventsDescription": "Je hebt geen tickets voor aankomende evenementen."
    },
    "details": {
      "head": {
        "title": "Tickets"
      },
      "actionBack": "Mijn evenementen",
      "inactive": {
        "title": "QR-code is veilig vergrendeld",
        "description": "Deze zal op {unlockDate} vanzelf verschijnen."
      },
      "active": {
        "title": "Laat de QR-code gescand worden",
        "description": "Deze werkt voor al je beschikbare tickets en aanhang."
      },
      "notScannable": {
        "title": "Geen tickets om in te checken",
        "description": "Alle tickets zijn ingecheckt, verkocht of geinvalideerd (naar credits)"
      },
      "noCopies": "Kopieën en prints werken niet",
      "seating": {
        "seatsAppear": "Stoelnummers worden de dag voor het evenement toegewezen.",
        "entrance": "Ingang",
        "section": "Sectie",
        "row": "Rij",
        "seats": "Stoel"
      },
      "group": {
        "info": "Groep van {activeUsers}/{groupSize}",
        "leave": "Verlaten",
        "confirmLeave": "Weet je zeker dat je deze groep wilt verlaten?"
      },
      "livestream": {
        "watchStream": "Bekijken",
        "upsell": {
          "shopLink": "Ga naar shop",
          "warning": "Let op: je hebt geen tickets gekocht",
          "description": "Je hebt alleen extra's gekocht. Als je ook tickets wil kopen, dat kan dat via de ticket-shop."
        },
        "inactive": {
          "title": "Toegang tot livestream",
          "description": "De livestream is hier beschikbaar op {unlockDate}."
        },
        "unauthorized": {
          "title": "Geen toegang tot livestream",
          "description": "Je hebt geen toegang tot deze livestream"
        },
        "active": {
          "title": "Livestream is begonnen!",
          "description": "Je hebt exclusieve toegang tot deze livestream."
        }
      },
      "footer": {
        "title": "Wil je jouw tickets delen of verkopen?",
        "description": "Installeer de mobiele app en doe meer."
      }
    }
  },
  "invite": {
    "title": "Uitnodiging van",
    "shareTickets": "{ticketAmount} ticket gedeeld met iedereen | {ticketAmount} tickets gedeeld met iedereen",
    "group": "{personAmount} persoon zit al in deze groep | {personAmount} personen zitten al in groep",
    "confirmAccept": "Weet je zeker dat je deze uitnodiging wilt accepteren?",
    "confirmDecline": "Weet je zeker dat je deze uitnodiging wilt weigeren?",
    "warning": "<strong>Accepteer alleen als je {inviterName} kent en vertrouwt! </strong> iedereen in deze groep heeft exact dezelfde rechten - inclusief het recht om anderen uit te nodigen en alle tickets voor dit evenement te verkopen, inclusief de jouwe!",
    "invite": "Uitnodiging van",
    "accept": "Accepteren",
    "decline": "Weigeren"
  },
  "pendingPayment": {
    "title": "Let op: Lopende betaling",
    "pay": "Betalen",
    "wait": "Wachten",
    "existingPayment": "Je hebt al eerder een betaling gestart.",
    "confirmationEmail": "Je zult spoedig een bevestigingsmail ontvangen als je deze betaling hebt afgemaakt.",
    "confirm": "Wil je een nieuwe betaling starten of wachten?"
  },
  "reservationInvitation": {
    "claimAction": "Claim tickets",
    "status": {
      "title": "Great, the tickets are yours!"
    }
  }
}
