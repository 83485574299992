import { App } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import { Store } from '@/store/types';

const Overview = () => import('@/views/shop/order/Overview.vue');

export default (app: App, store: Store) =>
  ({
    path: '/preview/:slug/:shopSlug/:shopType/orders/:id',
    name: 'preview-order',
    component: Overview,
    meta: {
      preview: true,
      showNavbar: true,
      permissions: {
        access: (): boolean => store.state.is_preview,
      },
    },
    beforeEnter: async (to, _, next) => {
      next();
    },
  } as RouteRecordRaw);
