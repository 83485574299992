import { App } from 'vue';
import { RouteLocationNormalized, Router } from 'vue-router';
import helpers from '@/helpers';
import { Order } from '@/store/modules/order/types';
import { ShopTK, ShopPageLayout } from '@/store/modules/shop/types';
import { Store } from '@/store/types';

export type GtmOptions = {
  whitelist: string[];
  blacklist: string[];
  policies?: Record<string, (...args: any[]) => any>;
};

type GtmPluginConfig = {
  options?: { debug?: boolean };
  store?: Store;
  router?: Router;
  ignoredViews?: string[];
};

function loadGtmScript(gtmId: string, options: GtmOptions, dataLayerName: string, sgtm?: boolean) {
  if (!gtmId) {
    throw new Error('Cannot load GTM plugin: ID not set.');
  }

  window[dataLayerName] = [];

  // Inject policies in dataLayer
  const policies = options?.policies || {};
  function addPolicy(...args) {
    /* eslint-disable prefer-rest-params */
    window[dataLayerName]!.push(arguments);
  }
  Object.entries(policies).forEach(([policy, handler]) => addPolicy('policy', policy, handler));

  // Add additional whitelist/blacklist
  if (options?.whitelist) {
    window[dataLayerName].push({
      'gtm.whitelist': options.whitelist,
    });
  }

  if (options?.blacklist) {
    window[dataLayerName].push({
      'gtm.blacklist': options.blacklist,
    });
  }

  // Add additional info in dataLayer
  window[dataLayerName].push({
    environment: import.meta.env.NODE_ENV,
    event: 'gtm.js',
    'gtm.start': new Date().getTime(),
  });

  // Inject GTM script with dayaLayerName
  const script = document.createElement('script');
  script.async = true;
  script.src = sgtm
    ? `https://load.analytics.guts.tickets/avovpoal.js?id=${gtmId}&l=${dataLayerName}`
    : `https://www.googletagmanager.com/gtm.js?id=${gtmId}&l=${dataLayerName}`;
  document.body.appendChild(script);
}

export function trackProductImpressions(
  gtm: GtmManager,
  data: {
    currencyCode: string;
    ticketLayout: ShopPageLayout;
    category: string;
    eo: string;
  },
): void {
  const { ticketLayout, category, currencyCode, eo } = data;
  const parseProducts = (products: ShopTK[], page: string) =>
    products.map((tk) => ({
      id: `${tk.id}`,
      name: tk.name,
      type: tk.upsell ? 'upsell' : 'ticket',
      price: parseFloat(tk.price),
      quantity: tk.available,
      category: page,
    }));

  const impressions = ticketLayout.reduce((acc, curr) => {
    let products: ShopTK[] = [];
    if (curr.type === 'ticket') {
      products = [curr.item];
    }
    if (curr.type === 'group') {
      products = curr.item.members;
    }
    acc = acc.concat(parseProducts(products, category));
    return acc;
  }, [] as Array<{ id: string; name: string; price: number; quantity: number; category: string; type: string }>);

  gtm.track({ ecommerce: null });
  gtm.track({
    event: 'ProductImpression',
    ecommerce: {
      currencyCode,
      currency: currencyCode,
      impressions,
      items: impressions.map((item) => ({
        item_id: item.id,
        item_name: item.name,
        item_brand: eo,
        item_category: item.category,
        item_variant: item.type,
        price: item.price,
        quantity: item.quantity,
        currency: currencyCode,
      })),
    },
  });
}

export function trackAddProduct(
  gtm: GtmManager,
  data: {
    name: string;
    category: string | null;
    amount: number;
    gateSlug: string;
    shopSlug: string;
    currencyCode: string;
    id: number | string;
    price: string;
    eo: string;
  },
): void {
  const { name, category, amount, gateSlug, shopSlug, id, price, currencyCode, eo } = data;

  gtm.track({ ecommerce: null });
  gtm.track({
    event: 'AddProduct',
    name,
    category,
    amount,
    gateSlug,
    shopSlug,
    ecommerce: {
      currencyCode,
      add: {
        products: [{ name, id: `${id}`, price: parseFloat(price), category, quantity: amount }],
      },
      currency: currencyCode,
      value: parseFloat(price),
      items: [
        {
          item_name: name,
          item_id: `${id}`,
          price: parseFloat(price),
          item_brand: eo,
          item_category: category,
          quantity: amount,
        },
      ],
    },
  });
}

export function trackRemoveProduct(
  gtm: GtmManager,
  data: {
    name: string;
    category: string | null;
    amount: number;
    gateSlug: string;
    shopSlug: string;
    id: number | string;
    price: string;
    currencyCode: string;
    eo: string;
  },
): void {
  const { name, category, amount, gateSlug, shopSlug, id, price, currencyCode, eo } = data;
  gtm.track({ ecommerce: null });
  gtm.track({
    event: 'RemoveProduct',
    name,
    category,
    amount,
    gateSlug,
    shopSlug,
    ecommerce: {
      remove: {
        products: [{ name, id: `${id}`, price: parseFloat(price), category, quantity: amount }],
      },
      currency: currencyCode,
      value: parseFloat(price),
      items: [
        {
          item_name: name,
          item_id: `${id}`,
          price: parseFloat(price),
          item_brand: eo,
          item_category: category,
          quantity: amount,
        },
      ],
    },
  });
}
export function trackOrderCheckout(
  gtm: GtmManager,
  data: {
    currency: string;
    tickets: number;
    total: number;
    totalAmount: number;
    ticketKinds: Array<ShopTK>;
    categoriesByKind: Record<number, string>;
    eo: string;
  },
): void {
  const { currency, tickets, total, totalAmount, ticketKinds, categoriesByKind, eo } = data;

  const { upsellCount, upsellAmount } = ticketKinds.reduce(
    (acc, t) => {
      if (!t.upsell) return acc;
      const amountInCart = t.amountInCart || 0;
      acc.upsellAmount += amountInCart * parseFloat(t.price);
      acc.upsellCount += amountInCart;
      return acc;
    },
    { upsellCount: 0, upsellAmount: 0 },
  );

  const transformedKinds = ticketKinds.reduce(
    (acc, { name, upsell, price, amountInCart = 0, id }) => {
      const sharedData = { name, price, quantity: amountInCart };
      if (amountInCart > 0) {
        acc.products.push({
          ...sharedData,
          type: upsell ? 'upsell' : 'ticket',
          price: parseFloat(price),
        });
        acc.ecomProducts.push({
          ...sharedData,
          id: `${id}`,
          price: parseFloat(price),
          category: categoriesByKind[id] || null,
        });
        acc.items.push({
          item_name: name,
          item_id: `${id}`,
          price: parseFloat(price),
          item_brand: eo,
          item_category: categoriesByKind[id] || null,
          quantity: amountInCart,
        });
      }

      return acc;
    },
    { products: [], ecomProducts: [], items: [] } as {
      products: Array<Record<string, any>>;
      ecomProducts: Array<Record<string, any>>;
      items: Array<Record<string, any>>;
    },
  );

  gtm.track({ ecommerce: null });
  gtm.track({
    event: 'Checkout',
    currency,
    tickets,
    ticketsAmount: totalAmount - upsellAmount,
    upsells: upsellCount,
    upsellAmount,
    total,
    totalAmount,
    products: transformedKinds.products,
    ecommerce: {
      currencyCode: currency,
      checkout: {
        products: transformedKinds.ecomProducts,
      },
      currency,
      value: totalAmount,
      items: transformedKinds.items,
    },
  });
}

export function trackPurchase(
  gtm: GtmManager,
  data: {
    status: string;
    order: Order | null;
    currency: string;
    daysInAdvance: number;
    categoriesByKind: Record<number, string>;
    eo: string;
  },
): void {
  if (!data?.order || !data?.currency) {
    return;
  }

  const { order, status, categoriesByKind, daysInAdvance, currency, eo } = data;
  const transformedItems = order.details.items.reduce(
    (acc, item) => {
      const sharedData = {
        name: item.rank,
        quantity: item.amount,
      };
      acc.products.push({
        ...sharedData,
        type: item.upsell ? 'upsell' : 'ticket',
        price: parseFloat(item.kind_price),
        sub_total: parseFloat(item.sub),
      });
      acc.ecomProducts.push({
        ...sharedData,
        id: `${item.kind}`,
        category: categoriesByKind[item.kind] || null,
        price: parseFloat(item.kind_price),
        sub_total: parseFloat(item.sub),
      });
      acc.items.push({
        item_name: item.rank,
        item_id: `${item.kind}`,
        price: parseFloat(item.kind_price),
        sub_total: parseFloat(item.sub),
        item_brand: eo,
        item_category: categoriesByKind[item.kind] || null,
        quantity: item.amount,
      });
      return acc;
    },
    { products: [], ecomProducts: [], items: [] } as {
      products: Array<Record<string, any>>;
      ecomProducts: Array<Record<string, any>>;
      items: Array<Record<string, any>>;
    },
  );
  const orderCounts = order.details.items.reduce(
    (acc, ticket) => {
      const price = parseFloat(ticket.sub);
      const count = ticket.amount;
      if (!ticket.upsell) {
        acc.tickets += count;
        acc.ticketsAmount += price;
      } else {
        acc.upsells += count;
        acc.upsellAmount += price;
      }
      acc.total += count;
      acc.totalAmount += price;
      return acc;
    },
    {
      tickets: 0,
      ticketsAmount: 0,
      upsells: 0,
      upsellAmount: 0,
      total: 0,
      totalAmount: 0,
    },
  );

  gtm.track({ ecommerce: null });
  gtm.track({
    event: 'Purchase',
    ...orderCounts,
    order_id: order.id,
    status,
    daysInAdvance,
    currency,
    products: transformedItems.products,
    ecommerce: {
      currencyCode: currency,
      purchase: {
        actionField: {
          id: `${order.id}`,
          revenue: `${orderCounts.totalAmount}`,
        },
        products: transformedItems.ecomProducts,
      },
      currency,
      value: orderCounts.totalAmount,
      transaction_id: `${order.id}`,
      items: transformedItems.items,
    },
  });
}

export function trackPrivacyOptIn(gtm: GtmManager, value: boolean): void {
  gtm.track({ privacy_opt_in: value });
}

export class GtmManager {
  debug: boolean;

  gtmList: Set<string>;

  dataLayerList: Set<string>;

  dataLayerCache: Map<string, any[]>;

  consentScriptInterval?: NodeJS.Timer;

  consentInitialised: boolean;

  constructor({ debug = true }) {
    this.debug = debug;
    this.gtmList = new Set();
    this.dataLayerList = new Set();
    this.dataLayerCache = new Map();
    this.consentInitialised = false;
    this.consentScriptInterval = undefined;
  }

  log(...args) {
    if (this.debug) {
      // eslint-disable-next-line no-console
      console.log('GTM:', ...args);
    }
  }

  enable(gtmId: string, options: GtmOptions, dataLayerName = 'dataLayer', sgtm = false) {
    if (this.gtmList.has(gtmId)) {
      this.log(`${gtmId}: Already enabled`);
      return;
    }

    try {
      loadGtmScript(gtmId, options, dataLayerName, sgtm);
    } catch (e: any) {
      this.log(e.message);
      return;
    }

    this.gtmList.add(gtmId);
    this.dataLayerList.add(dataLayerName);
    this._initConsentScriptCheck();

    this.log(`${gtmId} enabled. dataLayer: ${dataLayerName}`);
  }

  track(data) {
    if (!this.gtmList.size) {
      this.log('Disabled. Tried to push:', data);
      return;
    }

    this.dataLayerList.forEach((dataLayer) => {
      if (this.consentInitialised || ['Init', 'trackerInit'].includes(data.event)) {
        this.log(`Sending to ${dataLayer}`, data);
        if (window[dataLayer]) window[dataLayer].push(data);
      } else {
        this.log(`Consent not yet initialised - caching data for ${dataLayer}`, data);
        const value = this.dataLayerCache.get(dataLayer) || [];
        value.push(data);
        this.dataLayerCache.set(dataLayer, value);
      }
    });
  }

  _initConsentScriptCheck(): void {
    if (this.consentScriptInterval || this.consentInitialised || !window) return;

    this._initConsentLoadListener();

    let count = 0;
    this.consentScriptInterval = setInterval(() => {
      count += 1;

      if (typeof window !== 'undefined' && window.Cookiebot) {
        this.log(`Cookiebot script detected`);
        this._clearConsentScriptCheckInterval();
        return;
      }

      if (count >= 5) {
        this._clearConsentScriptCheckInterval();
        this.log(`No cookiebot script detected after 2.5 seconds. Initialising consent as denied`);
        this.consentInitialised = true;
        this._destroyConsentLoadListener();
        this._pushCachedDatalayer();
      }
    }, 500);
  }

  _clearConsentScriptCheckInterval(): void {
    if (this.consentScriptInterval) {
      clearInterval(this.consentScriptInterval);
      this.consentScriptInterval = undefined;
    }
  }

  _initConsentLoadListener(): void {
    window.addEventListener('CookiebotOnLoad', this._consentLoadCallback.bind(this));
    // If dialog is shown, treat consent as initialised
    // but temporarily denied
    window.addEventListener('CookiebotOnDialogInit', this._consentLoadCallback.bind(this));
  }

  _destroyConsentLoadListener(): void {
    window.removeEventListener('CookiebotOnLoad', this._consentLoadCallback);
    // If dialog is shown, treat consent as initialised
    // but temporarily denied
    window.removeEventListener('CookiebotOnDialogInit', this._consentLoadCallback);
  }

  _consentLoadCallback(): void {
    this.log(`Cookie consent settings initialised`);
    this.consentInitialised = true;
    this._pushCachedDatalayer();
    this._clearConsentScriptCheckInterval();
    this._destroyConsentLoadListener();
  }

  _pushCachedDatalayer(): void {
    this.dataLayerCache.forEach((cachedData, dataLayer) => {
      this.log(`Sending cached data to ${dataLayer}`, cachedData);
      cachedData.forEach((data) => this.track(data));
    });
    this.dataLayerCache.clear();
  }
}

/**
 * GTM Router guard.
 */
export const gtmRouterGuard = (gtm: GtmManager, config: GtmPluginConfig, to: RouteLocationNormalized) => {
  const { ignoredViews, store } = config;
  /* The above code is checking to see if the view name is null or if the view name is in the
    ignoredViews array. If either of these conditions are true, then the code will return and not run
    the rest of the code. */
  if (!to.name || (ignoredViews && ignoredViews.indexOf(to.name as string) !== -1)) {
    return;
  }

  const event: Record<string, any> = {
    event: 'PageView',
    pagePath: to.fullPath,
    pageRoute: to.name,
  };

  const { slug, shopSlug, eventSlug } = to.params;

  // If the current route is `shop` we'll add some extra
  // data to the PageView event. This can probably only be done when the
  // current route is `shop` as it relies on certain items inside the vuex store.
  if (to.name === 'shop') {
    const { shop } = store?.state || {};

    // Shop is missing from the store.
    // Happens whenever 'Shop Required' error is thrown and is missing
    // from the store state.
    if (shop && shop?.event?.title) {
      event.eventName = shop.event.title;
      event.eventSubName = shop.event.subtitle;
    }
  }

  // If the current route is `queue` we will add the title
  // and subtitle of the event to the PageView event as `gateName` and `gateTitle`.
  // Normally we would get this from the `shop` object in that global state
  // but this is not available in the `queue` route.
  if (to.name === 'queue') {
    const { title, subtitle } = store?.state?.gate?.queue || {};
    event.gateName = title;
    event.gateSubName = subtitle;
  }

  if (slug) {
    event.gateSlug = helpers.slugFromHumanSlug(slug);
  }

  if (shopSlug) {
    event.shopSlug = shopSlug;
  }

  if (eventSlug) {
    event.eventSlug = eventSlug;
  }

  gtm.track(event);
};

export default {
  install: (app: App, config: GtmPluginConfig) => {
    const gtm = new GtmManager(config.options || {});

    app.config.globalProperties.$gtm = gtm;
    app.config.globalProperties.gtm = gtm;

    app.provide('gtm', gtm);

    // Init GTMs without blacklist
    const options = {
      whitelist: ['google', 'sandboxedScripts', 'customScripts'],
      blacklist: [],
    } as GtmOptions;

    // Init Global GTM
    if (import.meta.env.VITE_APP_GTM_ID && !config.store?.state.is_preview) {
      gtm.enable(import.meta.env.VITE_APP_GTM_ID, options);
    }

    // Init Whitelabel GTM
    if (import.meta.env.VITE_APP_WL_GTM_ID && !config.store?.state.is_preview) {
      gtm.enable(import.meta.env.VITE_APP_WL_GTM_ID, options);
    }

    // Init Server-side GTM
    const urlParams = new URLSearchParams(window.location.search);
    const sgtmId = urlParams.get('sgtm');
    if (sgtmId) gtm.enable(sgtmId, options, 'dataLayer', true);

    gtm.track({ event: 'Init', referrer: document.referrer, href: document.location.href });

    if (config.router) {
      config.router.afterEach((to) => gtmRouterGuard(gtm, config, to));
    }
  },
};
