import { createI18n } from 'vue-i18n';

import nl from './locales/nl/main.json';
import nlCountries from './locales/nl/countries.json';
import 'moment/dist/locale/nl';

import de from './locales/de/main.json';
import deCountries from './locales/de/countries.json';
import 'moment/dist/locale/de';

import it from './locales/it/main.json';
import itCountries from './locales/it/countries.json';
import 'moment/dist/locale/it';

import es from './locales/es/main.json';
import esCountries from './locales/es/countries.json';
import 'moment/dist/locale/es';

import fr from './locales/fr/main.json';
import frCountries from './locales/fr/countries.json';
import 'moment/dist/locale/fr';

import en from './locales/en/main.json';
import enCountries from './locales/en/countries.json';
// in order to be set as global (fallback) locale, 'en-gb' has to be imported as the last one
import 'moment/dist/locale/en-gb';

const messages = {
  nl: { countries: nlCountries, ...nl },
  en: { countries: enCountries, ...en },
  de: { countries: deCountries, ...de },
  it: { countries: itCountries, ...it },
  es: { countries: esCountries, ...es },
  fr: { countries: frCountries, ...fr },
};

const locale = 'en';
const fallbackLocale = 'en';

const numberFormats = {
  nl: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    },
  },
  en: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    },
  },
  de: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    },
  },
  it: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    },
  },
  es: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    },
  },
  fr: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    },
  },
};

const i18n = createI18n({
  locale,
  fallbackLocale,
  messages,
  numberFormats,
  warnHtmlInMessage: 'off',
});

// order of locales defines preference
const supportedLocales = ['en', 'nl', 'de', 'it', 'es', 'fr'];

export { i18n, supportedLocales };
