const getQueryLocale = (url) => {
  const queryString = url.split('?')[1];
  if (queryString && queryString.includes('lang=')) {
    // get everything from lang= until the end of the string or a new query var
    return queryString.split('lang=')[1].split('&')[0];
  }
  return null;
};

const setLocale = (i18n, locale) => {
  if (locale in i18n.messages === false) {
    locale = i18n.fallbackLocale;
  }

  i18n.locale = locale;
  document.querySelector('html').setAttribute('lang', i18n.locale);
  return i18n.locale;
};

const getBrowserLanguages = () => {
  if (navigator) {
    const { languages, language, userLanguage, browserLanguage } = navigator;
    if (languages) {
      return languages;
    }
    if (language) {
      return [language];
    }
    if (userLanguage) {
      return [userLanguage];
    }
    if (browserLanguage) {
      return [browserLanguage];
    }
  }
  return [];
};

const getPreferredSupportedLang = (browserLangs, fallbackLocale, locales = []) => {
  const formattedLangs = browserLangs.map((lang) => {
    const langElements = lang.split('-');
    return langElements[0];
  });

  const index = locales.findIndex((lang) => formattedLangs.includes(lang));

  if (index !== -1) return locales[index];
  return fallbackLocale;
};

export default {
  getQueryLocale,
  setLocale,
  getPreferredSupportedLang,
  getBrowserLanguages,
};
