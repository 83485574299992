/* eslint-disable max-classes-per-file */

const getStorage = (sf) => {
  try {
    return sf();
  } catch (e) {
    return null;
  }
};

const checkStorage = (s) => {
  try {
    const key = 'test';
    s.setItem(key, key);
    if (s.getItem(key) !== key) {
      return false;
    }
    s.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

export class SharedStorage {
  constructor(prefix, storageFactories) {
    this.prefix = prefix || '';
    this.storages = storageFactories
      .map(getStorage)
      .filter((s) => !!s)
      .filter(checkStorage);
    SharedStorage._instance = this;
  }

  static getInstance() {
    return this._instance;
  }

  toInternalKey(k) {
    return `${this.prefix}${k}`;
  }

  removePrefix(k) {
    return k.substring(this.prefix.length);
  }

  setItem(k, v) {
    const value = JSON.stringify(v);
    const key = this.toInternalKey(k);
    this.storages.forEach((s) => s.setItem(key, value));
  }

  getItem(k, defaultValue = null) {
    const key = this.toInternalKey(k);
    for (let i = 0; i < this.storages.length; i += 1) {
      const v = this.storages[i].getItem(key);
      if (v) {
        try {
          return JSON.parse(v);
        } catch (e) {
          console.error(e);
          return v;
        }
      }
    }
    return defaultValue;
  }

  queryKeys(query) {
    return [...this.keys()].filter((k) => k.match(query) || (!query && typeof k === 'string'));
  }

  removeItem(k) {
    const key = this.toInternalKey(k);
    return this.storages.forEach((s) => s.removeItem(key));
  }

  keys() {
    return this.storages.reduce((acc, s) => {
      // This is not extendable, we need an intermediate class if we want to support
      // cookieStorage or memoryStorage
      Object.keys(s)
        .filter((k) => k.startsWith(this.prefix))
        .forEach((k) => acc.add(this.removePrefix(k)));
      return acc;
    }, new Set());
  }

  sync() {
    this.keys().forEach((k) => this.setItem(k, this.getItem(k)));
  }
}

export class MemoryStorage {
  constructor() {
    this._data = {};
  }

  getItem(k) {
    return this._data[k];
  }

  setItem(k, v) {
    this._data[k] = v;
  }

  removeItem(k) {
    delete this._data[k];
  }
}

export default {
  install: (app, { storageFactories = [], prefix = 'guts-' }) => {
    const sharedStorage = new SharedStorage(prefix, storageFactories);

    app.config.globalProperties.$storage = sharedStorage;
    app.config.globalProperties.storage = sharedStorage;

    app.provide('storage', sharedStorage);

    sharedStorage.sync();
  },
};
