<template>
  <component
    :is="activeView.view"
    class="view view-account"
    :phone="activeView.phone"
    :mobileAuth="activeView.mobileAuth"
    @setView="setActiveView"
    @handleLogin="loginSuccess"
  />
</template>

<script>
  import { request } from '@getprotocollab/get-pal';
  import { defineAsyncComponent } from 'vue';
  import { useHead } from '@vueuse/head';
  import ticketApiConfig from '@/api/ticket-service';
  import Login from '@/views/account/Login.vue';
  import { i18n } from '@/lang';
  import helpers from '@/helpers';

  const verify = defineAsyncComponent(() => import('@/views/account/Verify.vue'));
  const callUser = defineAsyncComponent(() => import('@/views/account/CallUser.vue'));

  export default {
    components: {
      Login,
      verify,
      callUser,
    },
    setup() {
      const { t } = i18n.global;

      useHead({
        title: `${t('account.head.title')} - ${t('global.head.title')}`,
      });
    },
    data() {
      return {
        activeView: {
          view: 'login',
        },
      };
    },
    methods: {
      setActiveView({ view, phone, mobileAuth }) {
        this.activeView = {
          view,
          phone,
          mobileAuth,
        };
      },
      async loginSuccess(user) {
        const { query } = this.$route;
        let isComplete = true;
        if (import.meta.env.VITE_APP_API_MODE !== 'sharded') {
          if (query.orgId && query.shop) {
            const response = await request({
              ...ticketApiConfig,
              resource: `shops/${query.shop}/profile-complete/`,
            });
            isComplete = response.data.profile_complete;
          }
        }

        if (
          (import.meta.env.VITE_APP_API_MODE === 'sharded' && !helpers.isUserProfileComplete(user)) ||
          !user.profile_complete ||
          !isComplete
        ) {
          this.$router.replace({
            name: 'profile',
            query,
          });
          return;
        }

        if (query.next) {
          this.$router.replace(query.next);
          return;
        }
        this.$router.push({ name: 'profile' });
      },
    },
  };
</script>
