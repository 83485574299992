import { createTicketServiceConfig } from '@getprotocollab/get-pal';
import { createClearAuthtoken, createSetAuthToken } from './helpers';

const defaultUrl = import.meta.env.VITE_APP_ENTRAILS || 'http://localhost:8000/api/v2';

const config = createTicketServiceConfig({
  base_url: defaultUrl,
  headers: {},
  beforeMiddleware: [],
  errorMiddleware: [],
  afterMiddleware: [],
});

export const setShopGateToken = (token: string) => {
  config.headers.set('Gate-token', token);
};

export const clearShopGateToken = () => {
  config.headers.delete('Gate-token');
};

export const setAuthToken = createSetAuthToken(config);
export const clearAuthtoken = createClearAuthtoken(config);

export default config;
