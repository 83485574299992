import { createGUSConfig } from '@getprotocollab/get-pal';
import { createClearAuthtoken, createSetAuthToken } from './helpers';

const BASE_URL: string = import.meta.env.VITE_APP_OESOPHAGUS || 'http://localhost:8008/api';
const TICKETEER: string = import.meta.env.VITE_APP_TICKETEER || 'guts';

const gusConfig = createGUSConfig({
  base_url: BASE_URL,
  ticketeer: TICKETEER,
  app: 'queue',
  errorMiddleware: [],
});

export const setAuthToken = createSetAuthToken(gusConfig);
export const clearAuthtoken = createClearAuthtoken(gusConfig);

export default gusConfig;
