<template>
  <article class="view-error">
    <section class="view-content">
      <Icon type="soldout" color="white" size="80px" />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="description" v-html="$t(`errors.${errorKey}`)"></p>
    </section>
  </article>
</template>
<script>
  import Icon from '@/components/Icon.vue';

  export default {
    components: {
      Icon,
    },
    props: {
      errorKey: {
        type: String,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .view-error {
    padding: 2rem;
    color: $color-white;
    text-align: center;
    background-color: rgba($color-primary, 0.8);
    flex: 1 0 auto;
  }

  .description {
    margin: 0;
    line-height: 1.25;
  }
</style>
