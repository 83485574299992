import { GetterTree, Module } from 'vuex';
import { RootStoreable } from '@/store/types/models';
import actions from './actions';
import mutations from './mutations';
import { TicketStoreable } from './types';

const state: TicketStoreable = {
  user_events: [],
  event_tickets: [],
  group: null,
};

const getters: GetterTree<TicketStoreable, RootStoreable> = {};

function ticketsModule(app): Module<TicketStoreable, RootStoreable> {
  return {
    namespaced: true,
    state() {
      return state;
    },
    actions: actions(app),
    mutations,
    getters,
  };
}

export default ticketsModule;
