<template>
  <section class="view view-account-login" data-test-login @keyup.enter="throttledSubmit">
    <ActionBack v-if="showBackButton" action="goBack" @goBack="$router.go(-1)" />
    <header class="view-header">
      <figure class="image">
        <MobileLogin :alt="$t('account.login.title')" :color="colorSecondary" width="160" height="112" />
      </figure>
      <h1>{{ $t('account.login.title') }}</h1>
      <p>{{ $t('account.login.description') }}</p>
    </header>
    <section class="view-content">
      <div class="login-form">
        <FormField :validation="v$.phone" class="input-number">
          <MobileInput
            name="recipient"
            :placeholder="$t('account.login.phonePlaceholder')"
            :focusPlaceholder="$t('account.login.focusPhonePlaceholder')"
            :errors="v$.phone.$error"
            :modelValue="phone"
            @update:modelValue="handleMobileInput"
          />

          <p v-if="isRateLimited" data-test-rate-limit class="is-error rate-limit">
            {{ $t('validation.errors.rateLimit.message') }}
            {{
              rateLimitSeconds > 60
                ? $t('validation.errors.rateLimit.minutes')
                : $t('validation.errors.rateLimit.seconds', { seconds: rateLimitSeconds })
            }}
          </p>

          <p v-else-if="isGloballyBlocked" data-test-globally-blocked class="is-error globally-blocked">
            {{ $t('validation.errors.globalBlock.message') }}
            {{
              globalBlockSeconds > 60
                ? $t('validation.errors.globalBlock.minutes')
                : $t('validation.errors.globalBlock.seconds', { seconds: globalBlockSeconds })
            }}
          </p>
        </FormField>
      </div>
    </section>
    <footer v-if="!isRateLimited && !isGloballyBlocked" class="view-footer">
      <ActionButton
        uppercase
        :text="$t('account.login.actionVerify')"
        :isDisabled="isDisabled"
        :isLoading="isLoading"
        @action="throttledSubmit"
      />
    </footer>
    <Teleport to="#modal-target">
      <CaptchaModal v-model:active="captchaModal" @onVerify="login" @onError="handleCaptchaError" />
    </Teleport>
  </section>
</template>

<script lang="ts">
  import { requestLoginCodeWithCache } from '@getprotocollab/get-pal/dist/login';
  import useVuelidate from '@vuelidate/core';
  import { required } from '@vuelidate/validators';
  import { defineAsyncComponent, defineComponent } from 'vue';
  import { AuthCodeResult, GP_Response } from '@getprotocollab/get-pal';
  import MobileInput from '@/components/form/MobileInput.vue';
  import FormField from '@/components/form/Field.vue';
  import ActionButton from '@/components/ActionButton.vue';
  import ActionBack from '@/components/ActionBack.vue';
  import { isValidPhoneNumber } from '@/helpers/validators';
  import MobileLogin from '@/components/illustrations/MobileLogin.vue';
  import gusConfig from '@/api/gus';
  import styles from '@/assets/styles/exports.module.scss';
  import { debounce } from 'throttle-debounce';
  import { RATE_LIMIT } from '@/helpers/constants';

  const CaptchaModal = defineAsyncComponent(() => import('@/components/recaptcha/CaptchaModal.vue'));

  type Data = {
    phone: string;
    hasLiveValidation: boolean;
    isLoading: boolean;
    captchaModal: boolean;
    captchaCallback: null | ((captcha_token: string) => GP_Response<AuthCodeResult>);
    colorSecondary: string;
    throttledSubmit: (e: Event) => void;
    rateLimitSeconds: number;
    isRateLimited: boolean;
    globalBlockSeconds: number;
    isGloballyBlocked: boolean;
    interval: ReturnType<typeof setInterval> | null;
  };

  export default defineComponent({
    components: {
      MobileInput,
      FormField,
      ActionButton,
      ActionBack,
      MobileLogin,
      CaptchaModal,
    },
    props: {
      isDisabled: {
        type: Boolean,
        default: false,
      },
      showBackButton: {
        type: Boolean,
        default: true,
      },
    },
    emits: ['localError', 'setView'],
    setup() {
      return { v$: useVuelidate() };
    },
    data(): Data {
      return {
        phone: '',
        hasLiveValidation: false,
        isLoading: false,
        captchaModal: false,
        captchaCallback: null,
        colorSecondary: styles.colorSecondary,
        throttledSubmit: debounce(
          500,
          (e) => {
            this.onSubmit();
          },
          { atBegin: true },
        ),
        rateLimitSeconds: RATE_LIMIT / 100,
        isRateLimited: false,
        globalBlockSeconds: 0,
        isGloballyBlocked: false,
        interval: null,
      };
    },
    validations() {
      return {
        phone: {
          required,
          phoneNumber: isValidPhoneNumber,
        },
      };
    },
    computed: {
      queuePhone() {
        return this.$store.state.gate.line?.number || '';
      },
    },
    async created() {
      if (!this.phone && this.queuePhone) {
        this.phone = this.queuePhone;
      } else if (!this.phone && this.$route.query.phone) {
        this.phone = this.$route.query.phone as string;
      }
    },
    unmounted() {
      if (this.interval) clearInterval(this.interval);
    },
    methods: {
      handleMobileInput(value) {
        this.phone = value;
        if (this.hasLiveValidation) this.v$.phone.$touch();
      },
      setGloballyBlocked(timeout: number) {
        this.isGloballyBlocked = true;
        this.globalBlockSeconds = Math.floor((new Date(timeout).valueOf() - Date.now()) / 1000);

        if (this.interval) clearInterval(this.interval);

        this.interval = setInterval(() => {
          this.globalBlockSeconds -= 1;

          if (this.globalBlockSeconds === 0 && this.interval) {
            clearInterval(this.interval);
            this.isGloballyBlocked = false;
          }
        }, 1000);
      },
      setRateLimit() {
        this.isRateLimited = true;

        if (this.interval) clearInterval(this.interval);

        this.interval = setInterval(() => {
          this.rateLimitSeconds -= 1;

          if (this.rateLimitSeconds === 0 && this.interval) {
            clearInterval(this.interval);
            this.isRateLimited = false;
            this.rateLimitSeconds = RATE_LIMIT / 100;
          }
        }, 1000);
      },
      onSubmit() {
        this.v$.$touch();
        if (this.v$.$error) {
          this.hasLiveValidation = true;
          return;
        }
        this.login();
      },
      async login(captcha?: string) {
        let response: GP_Response<AuthCodeResult>;
        const locale = this?.$i18n.locale;
        this.isLoading = true;

        try {
          if (this.captchaCallback && captcha) {
            response = await this.captchaCallback(captcha);
          } else {
            response = await requestLoginCodeWithCache(gusConfig, {
              phone_number: this.phone,
              captcha_token: captcha,
              locale: locale || 'en',
            });
          }

          if (typeof response === 'function') {
            this.captchaCallback = response;
            this.captchaModal = true;
            return;
          }

          this.captchaCallback = null;

          if (response.data?.status === 'error') {
            if (response.data.error === 'captcha_required') {
              this.captchaModal = true;
              return;
            }
            if (response.data.error === 'too_many_auth_failures') {
              this.setGloballyBlocked(response.data.try_again_in);
              return;
            }

            throw Error(response.data.error);
          }

          this.captchaModal = false;

          this.$emit('setView', {
            view: 'verify',
            phone: this.phone,
            mobileAuth: response.data,
          });
        } catch (e: any) {
          if (e?.response?.status === 429) {
            this.setRateLimit();
            return;
          }

          throw e;
        } finally {
          this.isLoading = false;
        }
      },
      handleCaptchaError() {
        this.$store.commit('set_global_error', { show: true });
        this.captchaCallback = null;
        this.captchaModal = false;
        this.isLoading = false;
      },
    },
  });
</script>

<style lang="scss" scoped>
  .view-account-login {
    background: linear-gradient(to bottom, $color-white 0%, $color-offwhite 50%);
  }

  .view-header {
    padding: 1rem 1.5rem 0.5rem 1.5rem;
    font-size: $size-regular;
    text-align: center;
    max-width: $device-small;
    margin: 0 auto;
    align-self: center;

    h1 {
      font-size: $size-medium;
      font-weight: $weight-semibold;
    }

    p {
      color: $color-grey-dark;
      font-weight: $weight-regular;
      margin: 0;
    }
  }

  .image {
    display: flex;
    justify-content: center;
    min-height: 112px;
  }

  .login-form {
    position: relative;
    max-width: $device-small;
    margin: 0 auto;
    padding: 0.5rem 1.5rem 1rem 1.5rem;
    display: flex;
    flex-direction: column;
  }

  // overwite default error styling
  .is-error,
  .field :deep(.is-error) {
    position: absolute;
    left: 0;
    right: 0;
    top: -50px;
    margin: 0 1.25rem;
    color: $color-white;
    font-size: $size-regular;
    padding: 0.75rem 1rem;
    background-color: $color-error;
    border-radius: 2px;
    @include arrow(8px, $color-error, bottom);
    animation: fadeIn 0.12s forwards;
  }

  .rate-limit,
  .globally-blocked {
    top: -75px !important;
  }

  .view-footer {
    border-top: 1px solid $color-border-light;
    display: flex;
    justify-content: flex-end;
    padding: 0.75rem 1rem;
    background-color: $color-white;
  }
</style>
