import { App } from 'vue';
import Account from '@/views/Account.vue';
import { Store } from '@/store/types';

export default (app: App, store: Store) => ({
  path: '/account',
  name: 'account',
  component: Account,
  meta: {
    auth: true,
    showNavbar: true,
    permissions: {
      access: () => true,
      redirect: () => ({ name: 'notFound' }),
    },
  },
  beforeEnter: async (to, from, next) => {
    if (!from.meta.gate) {
      store.commit('set_layout_cover', { cover: '' });
    }
    next();
  },
});
