import { GP_Response, request } from '@getprotocollab/get-pal';
import { captureException } from '@sentry/browser';
import { App } from 'vue';
import { ticketApiConfig } from '@/api';
import helpers from '@/helpers/index';
import { Store } from '@/store/types';

type CheckoutResponse = { state: 'success'; order: number } | { state: 'error'; error: 'string'; order: null };

export default (app: App, store: Store) => ({
  path: 'checkout/:shopType',
  name: 'checkout',
  meta: {
    gate: true,
    auth: true,
    shop: true,
    permissions: {
      access: ({ user }) => !!user,
      redirect: (state, to) => ({
        name: 'account',
        query: {
          next: to.fullPath,
          shop: to.params.shopSlug,
          orgId: store.state.shop.shop?.organization_id,
        },
      }),
    },
  },
  beforeEnter: async (to, from, next) => {
    let response: GP_Response<CheckoutResponse>;
    const { storage } = app.config.globalProperties;
    const { commit, state, dispatch } = store;
    const { shopSlug, slug } = to.params;
    const fromProfile = from.name === 'profile' || from.name === 'account';
    const isResale = to.params.shopType === 'resale';

    const resaleCart = storage.getItem('resale-cart');
    const cart = storage.getItem('cart');

    if (!shopSlug || !slug) {
      next({ name: 'notFound', params: { pathMatch: to.path.substring(1).split('/') } });
      return;
    }

    if ((!cart || !cart.uuid) && (!resaleCart || !resaleCart.uuid)) {
      // TODO - add different message for cart notfound related to storage
      commit('shop/set_local_error', { show: true, key: 'orderNotFound' });
      next({ name: 'shop', params: { ...to.params } });
      throw Error('Cart not found in storage');
    }

    if (to.params.shopType !== 'resale' && to.params.shopType !== 'shop') {
      next({ name: to.name, params: { ...to.params, shopType: 'shop' } });
      return;
    }

    if (import.meta.env.VITE_APP_API_MODE !== 'sharded') {
      try {
        const res = await request<{ profile_complete: boolean }, never>({
          ...ticketApiConfig,
          resource: `shops/${shopSlug}/profile-complete/`,
        });

        if (res.data && !res.data.profile_complete) {
          next({ name: 'profile', query: { next: to.fullPath, orgId: state.shop.shop?.organization_id } });
          return;
        }
      } catch (e) {
        captureException(e);
      }
    }

    if ((cart?.uuid && !isResale) || (resaleCart?.uuid && isResale)) {
      const fullCartUuid = isResale ? `${shopSlug}/resale/${resaleCart.uuid}` : `${shopSlug}/${cart.uuid}`;
      const { tc: referral_id = null } = to.query;

      const rumenToken = storage.getItem(`${shopSlug}-rumen-token`);
      if (rumenToken) ticketApiConfig.headers.set('Rumen-token', rumenToken);
      try {
        response = await request<CheckoutResponse, { referral_id: string | null }>({
          ...ticketApiConfig,
          resource: `carts/${fullCartUuid}/checkout/`,
          method: 'put',
          data: { referral_id },
        });
      } catch (e: any) {
        if (e.response?.data?.error) {
          const message = e.response.data.error;
          if (import.meta.env.VITE_APP_API_MODE === 'sharded' && message === 'profile_incomplete') {
            next({ name: 'profile', query: { next: to.fullPath, orgId: state.shop.shop?.organization_id } });
            return;
          }

          dispatch('gate/clearGateHandle', helpers.slugFromHumanSlug(slug));
          helpers.scrollToTop();

          if (helpers.isExpectedTokenError(message)) {
            commit('shop/set_local_error', { show: true, key: message });
            next({ name: 'shop', params: { ...to.params } });
            return;
          }

          if (['wrong_collection_group', 'invalid_rumen_token', 'missing_rumen_token'].includes(message)) {
            storage.removeItem(`${shopSlug}-rumen-token`);
            next({ name: 'wallet-connect' });
            return;
          }

          commit('set_global_error', { show: true });
          next({ name: 'shop', params: { ...to.params } });
          throw e;
        }

        commit('shop/set_local_error', { show: true, key: 'orderNotFound' });
        next({ name: 'shop', params: { ...to.params } });
        throw e;
      }

      if (response.data?.state === 'error') {
        commit('shop/set_local_error', { show: true, key: response.data.error });
        next({ name: 'shop', params: { ...to.params } });
        return;
      }

      next({
        name: 'order',
        params: {
          ...to.params,
          id: response.data?.order,
        },
        replace: fromProfile,
      });
      return;
    }

    commit('set_global_error', { show: true });
    next({ name: 'shop', params: { ...to.params } });
  },
});
