import { GP_HttpError } from '@getprotocollab/get-pal';
import { init } from '@sentry/browser';
import { Vue as VueIntergration } from '@sentry/integrations';

const APP_URL = import.meta.env.VITE_APP_VUESCERA_URL || 'http://localhost:9100';

export default {
  initSentry: (Vue, options) => {
    init({
      ...options,
      integrations: [
        new VueIntergration({
          Vue,
          attachProps: true,
          logError: true,
        }),
      ],
      allowUrls: [APP_URL],
      // Common unactionable browser errors
      // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/filtering/
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Our own additions
        // thrown by google catpcha
        'Non-Error promise rejection captured with value: Timeout (h)',
        // thrown by microsoft outlook scan
        'Non-Error promise rejection captured with value: Object Not Found Matching Id:4',
      ],
      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
      beforeSend(event, hint) {
        // Ignore ChunkLoadError
        if (event.exception.type === 'ChunkLoadError') return null;

        if (hint && hint.originalException instanceof GP_HttpError) {
          const { response } = hint.originalException;

          // Ignore network errors
          if (response.status === 0) return null;
          const expected_errors = ['refresh_token_not_found', 'refresh_token_expired'];
          if (expected_errors.includes(response?.data?.error)) return null;
        }

        if (hint && hint.originalException instanceof Event) {
          event.extra.isTrusted = hint.originalException.isTrusted;
          event.extra.detail = hint.originalException.detail;
          event.extra.type = hint.originalException.type;
          event.extra.message = hint.originalException.message;
        }
        return event;
      },
    });
  },
};
