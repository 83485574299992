import {
  fetchUserEventDetails,
  request,
  fetchAllPages,
  parseRequestQueryParams,
  ListResult,
} from '@getprotocollab/get-pal';
import { CommitOptions, ActionContext, DispatchOptions } from 'vuex';
import { captureException } from '@sentry/browser';
import { ticketApiConfig } from '@/api';
import helpers from '@/helpers';
import { RootStoreable as R } from '@/store/types/models';
import {
  ShopStoreable as S,
  TicketShop,
  ResaleShop,
  ResaleItem,
  cartDifference,
  CartTicket,
  UserTKStats,
  UserShopStats,
  ShopTK,
  ResaleCartTicket,
  Organization,
} from '@/store/modules/shop/types';
import { calcEffectiveMaxTickets, ticketsCountInRemoteCarts, adjustTKLimits } from '@/helpers/shop-utils';
import mutations from './mutations';
import { _ctx as getterCTX } from './getters';

type _mutations = typeof mutations;
type _actions = ReturnType<typeof actions>;
type _ctx = {
  commit<K extends keyof _mutations, P extends Parameters<_mutations[K]>[1]>(
    ...args: P extends undefined
      ? [key: K, payload?: P, options?: CommitOptions]
      : [key: K, payload: P, options?: CommitOptions]
  ): ReturnType<_mutations[K]>;
  dispatch<K extends keyof _actions, P extends Parameters<_actions[K]>[1]>(
    ...args: P extends undefined
      ? [key: K, payload?: P, options?: DispatchOptions]
      : [key: K, payload: P, options?: DispatchOptions]
  ): ReturnType<_actions[K]>;
  getters: getterCTX;
} & Omit<ActionContext<S, R>, 'commit' | 'dispatch' | 'getters'>;

function actions(app) {
  return {
    async initEvent({ commit }: _ctx, eventSlug: string) {
      const response = await fetchUserEventDetails(ticketApiConfig, { event_slug: eventSlug });
      if (response.data) commit('set_event', response.data);
      return response;
    },

    async initShop({ commit }: _ctx, slug: string) {
      let response;

      try {
        response = await request<TicketShop, never>({
          ...ticketApiConfig,
          resource: `shops/${slug}/`,
        });

        if (!response.data) {
          throw new Error(`No shop found for slug ${slug}`);
        }
      } catch (e) {
        captureException(e);
        throw e;
      }

      commit('set_shop', response.data);
      return response;
    },

    async initTicketKinds({ dispatch, commit, rootState, state, getters }: _ctx, shopSlug: string): Promise<void> {
      const { user } = rootState;
      const { shopUserTKStats, userShopStats, shop, cart } = state;
      const { countTicketsInCart } = getters;
      let userTKStats = shopUserTKStats;
      let shopUserStats = userShopStats;
      const response = await fetchAllPages<ShopTK>(request, {
        ...ticketApiConfig,
        resource: `ticket-kinds/shop/${shopSlug}/`,
      });
      const ticketKinds = response.data?.results || [];
      if (user) {
        const [userTKStatResponse, shopStatResponse] = await Promise.all([
          dispatch('initShopUserTKStats', shopSlug),
          dispatch('getShopUserStats', shopSlug),
        ]);
        userTKStats = userTKStatResponse.data?.results || [];
        shopUserStats = shopStatResponse.data || null;
      } else {
        commit('set_shop_user_tk_stats', []);
        commit('set_user_shop_stats', null);
      }

      if (shop) {
        const ticketLimit = shopUserStats ? shopUserStats.user_ticket_limit : shop.max_tickets;
        const effectiveShopLimit = calcEffectiveMaxTickets(
          ticketLimit,
          ticketsCountInRemoteCarts(userTKStats, ticketKinds),
          countTicketsInCart,
        );
        commit('set_effective_shop_limit', effectiveShopLimit);
      }

      commit('set_ticket_kinds', adjustTKLimits(ticketKinds, userTKStats, cart.tickets));
    },

    async initShopUserTKStats({ commit }: _ctx, shopSlug: string) {
      const response = await fetchAllPages<UserTKStats>(request, {
        ...ticketApiConfig,
        resource: `ticket-kinds/shop/${shopSlug}/user-stats/`,
      });
      const stats = response.data?.results || [];
      commit('set_shop_user_tk_stats', stats);
      return response;
    },

    async getShopUserStats({ commit }: _ctx, shopSlug: string) {
      const response = await request<UserShopStats, never>({
        ...ticketApiConfig,
        resource: `shops/${shopSlug}/user-stats/`,
      });
      if (response.data) commit('set_user_shop_stats', response.data);

      return response;
    },

    async initResaleShop({ commit, state }: _ctx, slug: string) {
      const { uuid } = state.resaleCart;
      const response = await request<ResaleShop, never>({
        ...ticketApiConfig,
        resource: `shops/${slug}/resale/?cart=${slug}/resale/${uuid}`,
      });
      if (response.data) commit('set_resale_shop', response.data);
      return response;
    },
    async getOrganization({ commit }: _ctx, orgId: number) {
      const response = await request<Organization, never>({ ...ticketApiConfig, resource: `organizations/${orgId}/` });
      if (response.data) commit('set_organization', response.data);
      return response;
    },
    async getResellersByKind(
      { commit, state },
      {
        kindId,
        page = 1,
        refresh = false,
        exclude,
        exclude_group,
      }: {
        kindId: number;
        page?: number;
        refresh?: boolean;
        exclude?: string;
        exclude_group?: string;
      },
    ) {
      if (!state.shop) throw new Error('Shop required');

      const {
        shop: { slug },
        resaleCart: { uuid },
      } = state;
      const limit = 10;
      const query = parseRequestQueryParams({
        limit,
        offset: limit * (page - 1),
        ticket_kind: kindId,
        cart: `${slug}/resale/${uuid}`,
        exclude_uuids: exclude,
        exclude_group,
      });

      const response = await request<ListResult<ResaleItem>, never>({
        ...ticketApiConfig,
        resource: `shops/${slug}/resellers/${query}`,
      });

      if (refresh) {
        commit('set_resellers_by_kind', { resellers: response.data?.results || [], kindId });
      } else {
        commit('add_resellers_by_kind', { resellers: response.data?.results || [] });
      }

      return response;
    },

    async getResellerItems(
      { commit, state },
      { seller, group, page = 1 }: { seller?: string; group?: string; page?: number },
    ) {
      if (!state.shop) throw new Error('Shop required');

      const limit = 100;
      const {
        shop: { slug },
        resaleCart: { uuid },
      } = state;

      const query = parseRequestQueryParams({
        limit,
        offset: limit * (page - 1),
        uuid: seller,
        ticket_group: group,
        cart: `${slug}/resale/${uuid}`,
      });

      const response = await request<ListResult<ResaleItem>, never>({
        ...ticketApiConfig,
        resource: `shops/${slug}/resellers/${query}`,
      });

      commit('set_reseller_resale_items', response.data?.results || []);
      return response;
    },

    async updateCart({ state, commit, dispatch }: _ctx, ticketKinds: CartTicket[]) {
      if (!state.shop) throw new Error('Missing shop');

      const {
        shop: { slug },
        cart: { uuid },
      } = state;
      let hasCorrectAmounts = true;
      let updatedCart: { ticket_kinds: CartTicket[] };

      if (!uuid) throw new Error('Missing cart uuid');

      try {
        const response = await request<{ ticket_kinds: CartTicket[] }, { ticket_kinds: CartTicket[] }>({
          ...ticketApiConfig,
          method: 'put',
          resource: `carts/${slug}/${uuid}/`,
          data: {
            ticket_kinds: ticketKinds,
          },
        });
        if (!response.data) throw Error('Unknown: Failed request with no data');
        updatedCart = response.data;
      } catch (e: any) {
        if (
          !['token_expired', 'token_different_user', 'invalid_token', 'missing_token', 'token_different_gate'].includes(
            e.response?.data?.error,
          )
        ) {
          // if something other than token validation goes wrong we refresh the shop
          await dispatch('initShop', slug);
          await dispatch('initTicketKinds', slug);
        }

        throw e;
      }
      // check if local cart matches the response from the backend
      hasCorrectAmounts = ticketKinds
        .filter((tk) => tk.amount !== 0)
        .every((tk) => updatedCart.ticket_kinds.find((kind) => kind.id === tk.id && kind.amount === tk.amount));
      // if the response doesn't match the local cart (i.e buying last ticket)
      // we update the store with the returned tickets
      commit('set_cart_tickets', updatedCart.ticket_kinds);
      if (!hasCorrectAmounts) {
        // set tickets must happen before shop update so cart in ticket list is updated correctly
        await dispatch('initTicketKinds', slug);
      }
    },

    async updateResaleCart({ state, commit }: _ctx, tickets: ResaleCartTicket[]) {
      if (!state.shop) throw new Error('Missing shop');
      const {
        shop: { slug },
        resaleCart: { uuid },
      } = state;
      if (!uuid) throw new Error('Missing cart uuid');

      const response = await request<{ tickets: ResaleCartTicket[] }, { tickets: ResaleCartTicket[] }>({
        ...ticketApiConfig,
        method: 'put',
        resource: `carts/${slug}/resale/${uuid}/`,
        data: { tickets },
      });

      const updatedCart = response.data || { tickets: [] };

      // check if local cart matches the response from the backend
      // update new availabilities
      const cartDiff = tickets.reduce((diff, tk) => {
        const found = updatedCart.tickets.find(
          ({ price, seller, kind }) => price === tk.price && seller === tk.seller && kind === tk.kind,
        );
        if ((!found && tk.amount !== 0) || (found && found.amount !== tk.amount)) {
          const available: number = found ? found.amount : 0;
          diff.push({ ...tk, available });
        }
        return diff;
      }, [] as cartDifference[]);

      commit('update_reseller_resale_items_availability', cartDiff);
      commit('update_resellers_availability', cartDiff);
      commit('set_resale_cart_tickets', updatedCart.tickets);
    },

    async getCart({ state, commit }: _ctx) {
      if (!state.shop) throw new Error('Shop required');
      let cartTickets: CartTicket[];
      const {
        cart: { uuid },
        shop: { slug },
      } = state;
      if (!uuid) return;
      try {
        const response = await request<{ ticket_kinds: CartTicket[] }, never>({
          ...ticketApiConfig,
          resource: `carts/${slug}/${uuid}/`,
        });
        cartTickets = response.data?.ticket_kinds || [];
      } catch (e: any) {
        if (e?.response?.status === 404) {
          commit('set_cart_tickets', []);
          return;
        }
        throw e;
      }
      commit('set_cart_tickets', cartTickets);
    },

    async getResaleCart({ state, commit }: _ctx) {
      if (!state.shop) throw new Error('Shop required');
      const {
        resaleCart: { uuid },
        shop: { slug },
      } = state;
      let cartTickets: ResaleCartTicket[];
      if (!uuid) return;
      try {
        const response = await request<{ tickets: ResaleCartTicket[] }, never>({
          ...ticketApiConfig,
          resource: `carts/${slug}/resale/${uuid}/`,
        });
        cartTickets = response.data?.tickets || [];
      } catch (e: any) {
        if (e?.response?.status === 404) {
          commit('set_resale_cart_tickets', []);
          return;
        }
        throw e;
      }
      commit('set_resale_cart_tickets', cartTickets);
    },

    initCart({ commit, dispatch }: _ctx): Promise<void> {
      const cartCache = app.config.globalProperties.storage.getItem('cart');
      const uuid = cartCache && cartCache.uuid ? cartCache.uuid : helpers.generateUUID4();

      app.config.globalProperties.storage.setItem('cart', { uuid });
      commit('init_cart', uuid);
      return dispatch('getCart');
    },

    initResaleCart({ commit, dispatch }: _ctx): Promise<void> {
      const cartCache = app.config.globalProperties.storage.getItem('resale-cart');
      const uuid = cartCache && cartCache.uuid ? cartCache.uuid : helpers.generateUUID4();

      app.config.globalProperties.storage.setItem('resale-cart', { uuid });
      commit('init_resale_cart', uuid);
      return dispatch('getResaleCart');
    },

    removeCart({ commit }: _ctx) {
      app.config.globalProperties.storage.removeItem('cart');
      commit('init_cart', '');
    },

    removeResaleCart({ commit }: _ctx) {
      app.config.globalProperties.storage.removeItem('resale-cart');
      commit('init_resale_cart', '');
    },
  };
}

export default actions;
