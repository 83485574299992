<template>
  <article v-if="loading" class="view view-loader">
    <Spinner class="loader" />
  </article>
  <Error v-else-if="error && !loading" :errorKey="error" />
  <article v-else class="view view-sharing">
    <InviterInfo v-if="inviter" :inviter="inviter" />
    <header v-if="event" class="sharing-header">
      <EventHeader :event="event" class="event-info" />
    </header>
    <section class="ticket-section">
      <div class="section-title">
        <Icon type="ticket" size="1.5rem" class="icon" />
        <strong class="label">
          {{ $tc('invite.shareTickets', sharingTicketAmount, { ticketAmount: sharingTicketAmount }) }}
        </strong>
      </div>
      <div v-for="ticket in tickets" :key="ticket.id" class="ticket-section-content">
        <span>
          <span class="label-nb-tickets">{{ ticketCountsByKind[ticket.kind] }}x</span>
          <strong>{{ ticket.name }}</strong>
        </span>
        <span class="price-label">
          {{ getFormattedPrice(ticket.price, currency) }}
        </span>
      </div>
    </section>
    <section class="group-section">
      <div class="section-title">
        <Icon type="person" size="1.5rem" class="icon" />
        <strong class="label">
          {{ $tc('invite.group', group.length, { personAmount: group.length }) }}
        </strong>
      </div>
      <div class="group-section-content">
        <div v-for="user in group" :key="user.id" class="group-section-item">
          <Avatar :user="user" />
          <small>{{ user.first_name }}</small>
        </div>
      </div>
    </section>
    <footer class="sharing-footer">
      <ActionButton uppercase class="btn-danger" :text="$t('invite.decline')" @action="decline" />
      <ActionButton
        uppercase
        :isLoading="loading"
        class="btn-secondary"
        :text="$t('invite.accept')"
        @action="debounceAccept"
      />
    </footer>
  </article>
</template>

<script>
  import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
  import { debounce } from 'throttle-debounce';
  import { GP_HttpError, request } from '@getprotocollab/get-pal';
  import ticketApiConfig from '@/api/ticket-service';
  import helpers from '@/helpers';
  import InviterInfo from '@/components/InviterInfo.vue';
  import Spinner from '@/components/loader/Spinner.vue';
  import EventHeader from '@/components/shop/details/EventHeader.vue';
  import Icon from '@/components/Icon.vue';
  import Avatar from '@/components/Avatar.vue';
  import ActionButton from '@/components/ActionButton.vue';
  import Error from '@/components/GenericError.vue';

  export default {
    name: 'Invite',
    components: {
      EventHeader,
      Icon,
      ActionButton,
      Avatar,
      InviterInfo,
      Error,
      Spinner,
    },
    data() {
      return {
        inviteCode: '',
        ticketgroup: {},
        tickets: [],
        loading: false,
        debounceAccept: null,
        error: null,
        inviter: null,
      };
    },
    computed: {
      ...mapState({
        event: (state) => state.shop.event,
      }),
      ...mapGetters({
        currency: 'shop/currency',
      }),
      group() {
        return this.ticketgroup.active_users ? this.ticketgroup.active_users : [];
      },
      sharingTicketAmount() {
        return this.ticketgroup.tickets ? this.ticketgroup.tickets.length : 0;
      },
      ticketCountsByKind() {
        return this.ticketgroup.tickets
          ? this.ticketgroup.tickets.reduce((acc, t) => {
              acc[t.kind] = acc[t.kind] || 0;
              acc[t.kind] += 1;
              return acc;
            }, {})
          : {};
      },
    },
    async created() {
      this.loading = true;
      this.debounceAccept = debounce(1000, () => this.accept(), { atBegin: true });
      this.inviteCode = this.$route.params.inviteCode;
      if (!this.inviteCode) this.$router.push({ name: 'notFound' });
      await this.fetchInviteData();
    },
    methods: {
      ...mapMutations({
        setGlobalError: 'set_global_error',
        setLayout: 'set_layout_cover',
        setEvent: 'shop/set_event',
      }),
      ...mapActions({
        initEvent: 'shop/initEvent',
      }),
      getFormattedPrice(price, currency) {
        return helpers.formatCurrency(price, currency);
      },
      async accept() {
        // eslint-disable-next-line no-alert
        const confirm = window.confirm(this.$t('invite.confirmAccept'));
        if (!confirm) return;
        try {
          await request({
            ...ticketApiConfig,
            resource: 'ticketgroups/invitation/accept/',
            method: 'post',
            data: { code: this.inviteCode },
          });
        } catch (err) {
          if (err instanceof GP_HttpError) {
            const { error: message, event: slug } = err.response.data || {};
            if (message === 'already_group_member') {
              this.$router.push({ name: 'tickets-detail', params: { eventSlug: slug } });
              return;
            }
            if (message) {
              this.error = message;
              return;
            }
          }
          throw err;
        } finally {
          this.loading = false;
        }

        this.$router.push({ name: 'tickets-detail', params: { eventSlug: this.event.slug } });
      },
      decline() {
        // eslint-disable-next-line no-alert
        const confirm = window.confirm(this.$t('invite.confirmDecline'));
        if (!confirm) return;
        this.$router.push({ name: 'tickets' });
      },
      async fetchEventData() {
        await this.initEvent(this.ticketgroup.event);
        this.setLayout({ image: this.event.cover });
        this.loading = false;
        this.tickets = this.ticketgroup.tickets.reduce((acc, tick) => {
          if (acc.findIndex((item) => item.kind === tick.kind) < 0) {
            acc.push(tick);
          }
          return acc;
        }, []);
      },
      async fetchInviteData() {
        let response;
        const payload = { code: this.inviteCode };
        try {
          response = await request({
            ...ticketApiConfig,
            method: 'post',
            resource: 'ticketgroups/invitation/',
            data: payload,
          });
        } catch (err) {
          if (err instanceof GP_HttpError) {
            const { error: message, event: slug } = err.response.data || {};
            if (message === 'already_group_member') {
              this.$router.push({ name: 'tickets-detail', params: { eventSlug: slug } });
              return;
            }
            if (message) {
              this.error = message;
              return;
            }
          }
          this.error = 'default';
          throw err;
        } finally {
          this.loading = false;
        }

        this.ticketgroup = response.data.ticketgroup;
        this.inviter = response.data.inviter;
        await this.fetchEventData();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .view-loader {
    padding: 10rem;
  }

  .loader {
    margin: 0 auto;
  }

  .ticket-section {
    padding: 1rem;
    border-bottom: 1px solid $color-border-light;
  }

  .ticket-section-content {
    display: flex;
    justify-content: space-between;
  }

  .group-section {
    padding: 1rem;
  }

  .section-title {
    margin-bottom: 0.5rem;
  }

  .group-section-content {
    display: flex;
    overflow-x: auto;
  }

  .group-section-item {
    width: 50px;
    text-align: center;
    margin: 0 0.5rem;
    overflow-wrap: break-word;
  }

  .event-info {
    padding: 1rem 1rem;
    border-bottom: 1px solid $color-border-light;
  }

  .icon {
    transition: transform ease-in 0.12s;
    vertical-align: middle;
    margin-bottom: 4px;
    :deep(path) {
      fill: $color-secondary;
    }
  }

  .label-nb-tickets {
    background-color: $color-grey-light;
    padding: 1px 6px;
    border-radius: 5px;
    color: $color-text-grey;
    font-weight: $weight-semibold;
    margin-right: 5px;
  }

  .label {
    flex: 1;
    font-weight: $weight-medium;
    font-size: $size-medium;
    color: $color-secondary;
    @include text-truncate;
  }

  .price-label {
    font-size: $size-medium;
    font-weight: $weight-semibold;
    color: $color-primary;
  }

  .sharing-footer {
    border-top: 1px solid $color-border-light;
    padding: 0.75rem 1rem;
    background-color: $color-white;
    @media (max-width: $device-small) {
      line-height: 5rem;
    }
    @media (min-width: $device-small) {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
</style>
