import { UserTKStats, ShopTK, CartTicket } from '@/store/modules/shop/types';

export function calcMaxSelectableForTK(
  available: number,
  effectiveShopLimit: number,
  maxPerUser: number,
  upsell: boolean,
): number {
  let max;

  if (upsell) {
    max = Math.min(available, maxPerUser);
  } else {
    max = Math.min(available, maxPerUser, effectiveShopLimit);
  }

  if (Number.isNaN(max) || max < 0) max = 0;
  return max;
}
export function calcEffectiveMaxTickets(
  shopLimit: number,
  ticketsInRemoteCarts: number,
  ticketsLocalInCart: number,
): number {
  const tkCountInRemoteCarts = Math.max(ticketsInRemoteCarts - ticketsLocalInCart, 0);
  return Math.max(shopLimit - tkCountInRemoteCarts, 0);
}

export function ticketsCountInRemoteCarts(userStats: UserTKStats[], ticketKinds: ShopTK[]): number {
  return userStats.reduce((totalTicketsInCarts, { id, in_cart }) => {
    const tk = ticketKinds.find((t) => t.id === id);
    if (tk && !tk.upsell) {
      totalTicketsInCarts += in_cart;
    }
    return totalTicketsInCarts;
  }, 0);
}

export function adjustTKLimits(ticketKinds: ShopTK[], userStats: UserTKStats[], cartTickets: CartTicket[]): ShopTK[] {
  return ticketKinds.map((tk) => {
    const { id, available, max_per_user } = tk;
    const { amount: amountInCart } = cartTickets.find((ct) => ct.id === id) || { amount: 0 };
    const { in_cart, user_bought } = userStats.find((stat) => stat.id === id) || {
      user_bought: 0,
      in_cart: 0,
    };

    const remoteCartTicketCount = Math.max(in_cart - amountInCart, 0);
    const effectiveUserLimit = Math.max(max_per_user - remoteCartTicketCount - user_bought, 0);
    const effectiveAvailable = Math.max(available + amountInCart, 0);

    return {
      ...tk,
      max_per_user: effectiveUserLimit,
      available: effectiveAvailable,
      amountInCart,
    };
  });
}
