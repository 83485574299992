import { GetterTree, Module } from 'vuex';
import { RootStoreable } from '@/store/types/models';
import { OrderStoreable } from './types';
import actions from './actions';
import mutations from './mutations';

const state: OrderStoreable = {
  psp_methods: null,
  order: null,
  credits: [],
  payment: null,
  payment_error: {
    show: false,
    key: '',
    message: '',
  },
};

const getters: GetterTree<OrderStoreable, RootStoreable> = {};

function orderModule(app): Module<OrderStoreable, RootStoreable> {
  return {
    namespaced: true,
    actions: actions(app),
    mutations,
    getters,
    state() {
      return state;
    },
  };
}

export default orderModule;
