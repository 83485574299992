<template>
  <div class="field">
    <slot />
    <p v-if="hasErrors" class="is-error">
      {{ $t(`validation.errors.${getErrorKey()}`) }}
    </p>
  </div>
</template>

<script>
  export default {
    props: {
      validation: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        params: [],
      };
    },
    computed: {
      hasErrors() {
        return this.validation.$error;
      },
    },
    created() {
      if (Object.keys(this.validation).length !== 0) {
        const validatorParams = Object.keys(this.validation).filter((key) => !key.includes('$'));

        this.params = validatorParams;
      }
    },
    methods: {
      getErrorKey() {
        return this.params.find((param) => this.validation.$errors.find((error) => error.$validator === param));
      },
    },
  };
</script>

<style lang="scss" scoped>
  .field {
    margin-bottom: 0.5em;
  }

  .is-error {
    color: $color-error;
    margin: 0;
    padding: 0.25em;
    font-size: $size-small;
    font-weight: $weight-medium;
  }
</style>
