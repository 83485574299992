import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import localeHelpers from '../helpers/locale';

export default {
  install: (app) => {
    // Tiny plugin to ensure consistent moment imports through the range limited timezone extension
    const browserLocales = localeHelpers.getBrowserLanguages().map((l) => (l.toLowerCase() === 'en-us' ? 'en' : l));
    moment.locale(browserLocales);
    app.provide('moment', moment);
  },
};
