<template>
  <div class="user-info-wrapper">
    <div class="user-info-header">
      <Avatar :user="inviter" />
      <div class="label-wrapper">
        <div class="title-label">{{ $t('invite.title') }}</div>
        <div class="name-label">{{ `${inviter.first_name} ${inviter.last_name}` }}</div>
      </div>
    </div>
    <div class="user-info-content">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="$t('invite.warning', { inviterName: inviter.first_name })"></p>
    </div>
  </div>
</template>

<script>
  import Avatar from '@/components/Avatar.vue';

  export default {
    components: {
      Avatar,
    },
    props: {
      inviter: {
        required: true,
        type: Object,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .user-info-wrapper {
    background-color: rgba($color-primary, 0.8);
    @media (min-width: $device-medium) {
      padding: 2rem;
      background-color: transparent;
      @include skew(16rem, 15%, rgba($color-primary, 0.8), 'left', 'top');
    }
    text-align: center;
    color: $color-white;
  }
  .user-info-header {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem;
    border-bottom: 1px solid rgba($color-white, 0.3);
  }
  .user-info-content {
    position: relative;
    z-index: 3;
    font-weight: $weight-medium;
    p {
      margin: 0;
      padding: 1rem 0rem;
    }
  }
  .label-wrapper {
    margin-left: 0.5rem;
    line-height: 1.4rem;
  }
  .title-label {
    text-align: left;
    text-transform: uppercase;
  }
  .name-label {
    font-weight: $weight-semibold;
    font-size: $size-big;
  }
</style>
