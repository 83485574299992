import { initRefreshMiddlewares } from './middlewares';
import ticketApiConfig, {
  setAuthToken as setTicketApiAuthToken,
  clearAuthtoken as clearTicketApiAuthToken,
} from './ticket-service';
import gusApiConfig, { setAuthToken as setGusApiAuthToken, clearAuthtoken as clearGusApiAuthToken } from './gus';

initRefreshMiddlewares(
  {
    config: ticketApiConfig,
    setAuthToken: setTicketApiAuthToken,
    clearAuthToken: clearTicketApiAuthToken,
  },
  {
    config: gusApiConfig,
    setAuthToken: setGusApiAuthToken,
    clearAuthToken: clearGusApiAuthToken,
  },
);

export {
  default as ticketApiConfig,
  setAuthToken as setTicketApiAuthToken,
  setShopGateToken,
  clearAuthtoken as clearTicketApiAuthToken,
  clearShopGateToken,
} from './ticket-service';
export {
  default as gusApiConfig,
  setAuthToken as setGusApiAuthToken,
  clearAuthtoken as clearGusApiAuthToken,
} from './gus';
