<script>
  import styles from '@/assets/styles/exports.module.scss';

  /* eslint-disable max-len */
  export default {
    props: {
      color: {
        type: String,
        default: styles.colorSecondary,
      },
    },
  };
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 200 140">
    <defs>
      <path
        id="a"
        d="M0.800000012,88.8000013 L60.8000009,88.8000013 L60.8000009,102.400002 C60.8000009,104.609141 59.0091399,106.400002 56.8000008,106.400002 L4.80000007,106.400002 C2.59086104,106.400002 0.800000012,104.609141 0.800000012,102.400002 L0.800000012,88.8000013 Z M60.8000009,17.6000003 L0.800000012,17.6000003 L0.800000012,4.00000006 C0.800000012,1.79086103 2.59086104,-3.55271368e-15 4.80000007,-3.55271368e-15 L56.8000008,3.55271368e-15 L56.8000008,0 C59.0091399,0 60.8000009,1.79086103 60.8000009,4.00000006 L60.8000009,17.6000003 Z M0.800000012,17.6000003 L60.8000009,17.6000003 L60.8000009,88.8000013 L0.800000012,88.8000013 L0.800000012,17.6000003 Z"
      />
      <path
        id="b"
        d="M0.800000012,88.8000013 L60.8000009,88.8000013 L60.8000009,102.400002 L60.8000009,102.400002 C60.8000009,104.609141 59.0091399,106.400002 56.8000008,106.400002 L4.80000007,106.400002 L4.80000007,106.400002 C2.59086104,106.400002 0.800000012,104.609141 0.800000012,102.400002 L0.800000012,88.8000013 Z"
      />
      <path
        id="c"
        d="M0.800000012,0 L60.8000009,0 L60.8000009,13.6000002 L60.8000009,13.6000002 C60.8000009,15.8091392 59.0091399,17.6000003 56.8000008,17.6000003 L4.80000007,17.6000003 L4.80000007,17.6000003 C2.59086104,17.6000003 0.800000012,15.8091392 0.800000012,13.6000002 L0.800000012,0 Z"
      />
      <rect id="d" width="60" height="71.2" x=".8" y="17.6" />
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(69.9 16.4)">
      <use fill="#FFFFFF" xlink:href="#a" />
      <path
        stroke="#363636"
        stroke-width="2.4"
        d="M-0.400000006,87.6000013 L62.0000009,87.6000013 L62.0000009,102.400002 C62.0000009,105.271882 59.6718816,107.600002 56.8000008,107.600002 L4.80000007,107.600002 C1.92811933,107.600002 -0.400000006,105.271882 -0.400000006,102.400002 L-0.400000006,87.6000013 Z M62.0000009,18.8000003 L-0.400000006,18.8000003 L-0.400000006,4.00000006 C-0.400000006,1.12811932 1.92811933,-1.20000002 4.80000007,-1.20000002 L56.8000008,-1.20000002 C59.6718816,-1.20000002 62.0000009,1.12811932 62.0000009,4.00000006 L62.0000009,18.8000003 Z M-0.400000006,16.4000002 L62.0000009,16.4000002 L62.0000009,90.0000013 L-0.400000006,90.0000013 L-0.400000006,16.4000002 Z"
      />
      <use fill="#FFFFFF" xlink:href="#b" />
      <path
        :stroke="color"
        stroke-width="2.4"
        d="M-0.400000006,87.6000013 L62.0000009,87.6000013 L62.0000009,102.400002 C62.0000009,105.271882 59.6718816,107.600002 56.8000008,107.600002 L4.80000007,107.600002 C1.92811933,107.600002 -0.400000006,105.271882 -0.400000006,102.400002 L-0.400000006,87.6000013 Z"
      />
      <g transform="rotate(-180 30.8 8.8)">
        <use fill="#FFFFFF" xlink:href="#c" />
        <path
          :stroke="color"
          stroke-width="2.4"
          d="M-0.400000006,-1.20000002 L62.0000009,-1.20000002 L62.0000009,13.6000002 C62.0000009,16.4718809 59.6718816,18.8000003 56.8000008,18.8000003 L4.80000007,18.8000003 C1.92811933,18.8000003 -0.400000006,16.4718809 -0.400000006,13.6000002 L-0.400000006,-1.20000002 Z"
        />
      </g>
      <use fill="#fff" xlink:href="#d" />
      <use :fill="color" xlink:href="#d" opacity="0.2" />
      <rect width="62.4" height="73.6" x="-.4" y="16.4" :stroke="color" stroke-width="2.4" />
      <path
        :fill="color"
        d="M0.800000012,17.6000003 L60.8000009,17.6000003 C50.5255338,29.7923679 40.2510666,41.9847356 29.9765995,54.1771032 C20.2510663,65.7180693 10.5255332,77.2590353 0.800000012,88.8000013 L0.800000012,17.6000003 Z"
        opacity=".353"
      />
      <rect width="24.8" height="4.8" x="18.4" y="4.8" :fill="color" rx="2.4" />
      <circle cx="30.4" cy="98.4" r="4" :fill="color" />
    </g>
  </svg>
</template>
