<template>
  <div ref="container" class="container" @scroll="scrollHandle">
    <ul class="country-list" :style="padding">
      <li v-for="country in countries.slice(start, end)" :id="country.code" :key="country.code">
        <a class="country-option" @click="selectCountry(country)">
          <figure class="list-flag">
            <img class="flag-img" alt="flag" :src="`${BASE_PATH}images/flags/${country.code.toLowerCase()}.png`" />
          </figure>
          {{ country.localName ? `${country.label} (${country.localName})` : country.label }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { debounce } from 'throttle-debounce';

  type country = {
    code: string;
    label: string;
    localName?: string;
    dialCode: string;
  };

  export default defineComponent({
    props: {
      countries: { type: Array as () => country[], required: true },
    },
    emits: ['click'],
    data() {
      return {
        BASE_PATH: import.meta.env.VITE_APP_BASE_PATH || '/',

        start: 0,
        end: 10,
        debouncedScroll: null,
      };
    },
    computed: {
      padding() {
        const top = this.start * 3.5;
        const bottom = (this.countries.length - this.start - 10) * 3.5;

        return {
          'padding-top': `${top}rem`,
          'padding-bottom': `${bottom}rem`,
        };
      },
    },
    watch: {
      countries: {
        handler() {
          this.start = 0;
          this.end = 10;
          this.$refs.container.scrollTop = 0;
        },
        deep: true,
      },
    },
    created() {
      this.debouncedScroll = debounce(
        1000 / 120,
        (scrollTop) => {
          const divHeight = 56; // 3.5rem / 1rem = 16px
          const topElements = Math.floor(scrollTop / divHeight);
          this.start = topElements;
          this.end = Math.min(this.start + 10, this.countries.length);
        },
        { atBegin: true },
      );
    },
    methods: {
      selectCountry(country) {
        this.$emit('click', country);
      },
      scrollHandle(e: Event) {
        if (!this.debouncedScroll) return;

        const { scrollTop } = e.target as HTMLDivElement;

        this.debouncedScroll(scrollTop);
      },
    },
  });
</script>

<style lang="scss" scoped>
  .container {
    max-height: 35rem;
    overflow: auto;
  }

  .country-option {
    display: flex;
    align-items: center;
    padding: 0.5rem 2rem;
    height: 3.5rem;
    width: 100%;
    border-top: 1px solid $color-border;

    &:hover {
      cursor: pointer;
    }
  }

  .list-flag {
    left: 0.5rem;
    width: 2.5rem;
    height: auto;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid $color-border;
    margin-right: 0.5rem;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
</style>
