<template>
  <section>
    <div class="control" :class="{ 'has-error': errors }">
      <button
        class="select-country"
        :class="{ 'is-focused': isFocus, 'has-error': errors }"
        type="button"
        @click="onCountrySelect"
      >
        <figure v-if="activeCountry.code" class="flag -smaller">
          <img class="flag-img" alt="flag" :src="`${BASE_PATH}images/flags/${activeCountry.code.toLowerCase()}.png`" />
        </figure>
        <span class="country-name" :class="{ selected: activeCountry.code }">{{
          activeCountry.code ? `${activeCountry.label} (+${activeCountry.dialCode})` : $t('account.login.selectCountry')
        }}</span>
        <Icon type="triangle" class="icon-triangle" size="12px" />
      </button>

      <transition name="fade">
        <label v-show="isFocus || !isEmpty" class="placeholder-label" :for="`input-${name}`">
          {{ placeholder }}
        </label>
      </transition>
      <input
        :id="`input-${name}`"
        :ref="`input-${name}`"
        v-autofocus
        type="tel"
        class="mobile-input"
        :class="{ 'is-expanded': isFocus || !isEmpty }"
        :placeholder="isFocus ? focusPlaceholder : placeholder"
        :value="formattedNumber"
        data-test-phone-number-input
        @focus="isFocus = true"
        @blur="isFocus = false"
        @input="(e) => updateFlagAndFormatNumber(e.target?.value)"
      />

      <Teleport to="#modal-target">
        <CountryModal v-model:active="modalActive" @select-country="handleCountryInput" />
      </Teleport>
    </div>
    <p class="input-description">{{ $t('account.login.mobileInputDescription') }}</p>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js/mobile';
  import { autofocus } from '@/helpers/directives';
  import Country from '@/helpers/Country';
  import CountryModal from '@/components/CountryModal.vue';
  import Icon from '@/components/Icon.vue';

  export default defineComponent({
    components: {
      CountryModal,
      Icon,
    },
    directives: {
      autofocus,
    },
    props: {
      name: { type: String, required: true },
      placeholder: { type: String, default: '' },
      focusPlaceholder: { type: String, default: '' },
      errors: { type: Boolean, default: false },
      modelValue: { type: String, required: true },
    },
    emits: ['update:modelValue'],
    data() {
      return {
        isFocus: false,
        activeCountry: {
          code: '',
          dialCode: '',
          label: '',
        },
        chosenCountryCode: '',
        formattedNumber: '+',
        modalActive: false,
        BASE_PATH: import.meta.env.VITE_APP_BASE_PATH || '/',
      };
    },
    computed: {
      isEmpty() {
        return this.formattedNumber.trim() === '';
      },
    },
    watch: {
      modelValue: {
        immediate: true,
        handler(value) {
          this.updateFlagAndFormatNumber(value);
        },
      },
    },
    created() {
      this.initCountry();
    },
    methods: {
      onCountrySelect() {
        this.modalActive = true;
      },
      getCountryCodeFromLocale() {
        const { locale } = this.$i18n;
        switch (locale) {
          case 'nl':
            return 'NL';
          case 'fr':
            return 'FR';
          case 'it':
            return 'IT';
          case 'de':
            return 'DE';
          case 'es':
            return 'ES';
          default:
            return '';
        }
      },
      initCountry() {
        const iso2 = this.getCountryCodeFromLocale();
        const country = Country.getCountryByIso(iso2);
        if (country) {
          this.activeCountry = {
            label: country.name,
            dialCode: country.dialCode,
            code: country.iso2,
          };
          this.formattedNumber = new AsYouType(iso2).input(`+${country.dialCode}`);
        }
      },
      handleCountryInput(country) {
        this.modalActive = false;

        if (country?.code !== this.activeCountry.code) {
          this.activeCountry = country;

          if (country?.dialCode) {
            this.formattedNumber = new AsYouType(country?.code).input(`+${country?.dialCode}`);
            this.chosenCountryCode = country?.code;
            this.$emit('update:modelValue', this.formattedNumber);
          }
        }
        (this.$refs[`input-${this.name}`] as HTMLInputElement).focus();
        this.updateFlagAndFormatNumber(this.formattedNumber);
      },
      updateFlagAndFormatNumber(number: string) {
        if (number === '+') {
          this.chosenCountryCode = '';
        }

        let iso2: string | undefined = this.chosenCountryCode || Country.getCountryCodeOfNumber(number) || '';

        const phoneNumber = parsePhoneNumberFromString(number);
        const formattedNumber = phoneNumber?.number || number;

        if (phoneNumber && phoneNumber.country !== iso2) {
          if (iso2 !== this.activeCountry.code) {
            iso2 = phoneNumber?.country;
          }
        }

        const countryObject = Country.getCountryByIso(iso2);
        this.activeCountry = {
          label: countryObject?.name,
          dialCode: countryObject?.dialCode,
          code: countryObject?.iso2,
        };

        this.formattedNumber = new AsYouType(iso2).input(formattedNumber);
        this.$emit('update:modelValue', this.formattedNumber);
      },
    },
  });
</script>

<style lang="scss" scoped>
  .control {
    position: relative;
    overflow: hidden;
  }

  .flag {
    border-radius: 4px;
    border: 1px solid $color-border;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    &:hover {
      cursor: pointer;
    }

    &.-smaller {
      width: 2rem;
      left: 1rem;
    }
  }

  .placeholder-label {
    position: absolute;
    width: 100%;
    bottom: 30px;
    padding-left: 1rem;
    color: $color-secondary;
    font-size: $size-small;
    font-weight: $weight-medium;
    pointer-events: none;
    white-space: nowrap;

    .has-error & {
      opacity: 1;
      color: $color-error;
    }
  }

  .select-country {
    height: 45px;
    background: $color-grey-light;
    width: stretch;
    flex-direction: row;
    display: flex;
    border-radius: 4px 4px 0 0;
    border: 1px solid $color-border;
    padding: 0 1rem;
    align-items: center;
    cursor: pointer;

    .icon-triangle {
      margin-left: auto;
    }

    .country-name {
      font-size: $size-regular;
      color: $color-grey;

      &.selected {
        margin-left: 1rem;
      }
    }

    &.is-focused {
      border-color: $color-secondary;
      border-bottom-color: $color-border;
    }

    &.has-error {
      border-color: $color-error;
    }
  }

  .mobile-input {
    padding-top: 0;
    border-radius: 0 0 4px 4px;
    border-top: none;
    box-shadow: none !important;
    &.is-expanded {
      padding-top: 1rem;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.12s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .input-description {
    margin-top: 0.25rem;
    font-size: $size-small;
    color: $color-grey-medium;
    text-align: center;
  }
</style>
