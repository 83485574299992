import { App } from 'vue';
import { Store } from '@/store/types';

const ShopDetails = () => import('@/views/shop/Details.vue');

export default (app: App, store: Store) => ({
  path: '/preview/:slug/:shopSlug',
  name: 'preview-shop',
  component: ShopDetails,
  meta: {
    preview: true,
    permissions: {
      access: (): boolean => store.state.is_preview,
    },
  },
});
