import { Module } from 'vuex';
import { App } from 'vue';
import { RootStoreable } from '@/store/types/models';
import { GateStoreable } from './types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state: GateStoreable = {
  queue: null,
  shops: [],
  favorites: [],
  gate_handle: '',
  isFreshHandle: false,
  line: null,
  phoneNumber: '',
  dateAutoSelect: true,
  gate_token: '',
};

function gateModule(app: App): Module<GateStoreable, RootStoreable> {
  return {
    namespaced: true,
    state() {
      return state;
    },
    actions: actions(app),
    getters,
    mutations,
  };
}

export default gateModule;
