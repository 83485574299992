<template>
  <div>
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script lang="ts">
  import { defineComponent, defineAsyncComponent } from 'vue';
  import { useHead } from '@vueuse/head';
  import { i18n } from '@/lang';
  import helpers from '@/helpers/index';

  const Default = defineAsyncComponent(() => import('@/layouts/Default.vue'));
  const Framed = defineAsyncComponent(() => import('@/layouts/Framed.vue'));
  const FullScreen = defineAsyncComponent(() => import('@/layouts/FullScreen.vue'));
  const Empty = defineAsyncComponent(() => import('@/layouts/Empty.vue'));

  export default defineComponent({
    name: 'App',
    components: {
      Default,
      Framed,
      FullScreen,
      Empty,
    },
    setup() {
      const { t } = i18n.global;

      useHead({
        meta: [
          { name: 'og:url', content: import.meta.env.VITE_APP_VUESCERA_URL },

          { name: 'og:site_name', content: t('global.head.title') },
          { name: 'og:type', content: 'website' },
          // Twitter
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:site', content: t('global.head.twitter') },
          { name: 'twitter:creator', content: t('global.head.twitter') },
        ],
      });
    },
    computed: {
      layout() {
        const layout = this.$route?.meta?.layout ? this.$route.meta.layout : 'Default';

        if (helpers.isFramed()) {
          return 'Framed';
        }

        return layout;
      },
    },
  });
</script>

<style lang="scss">
  // Removing this property will cause calendars to now longer render correctly.
  #app {
  }
</style>
