import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import PostMessageRequest from '@/helpers/PostMessageRequest';
import helpers from '@/helpers/';
import { VUEJONES_URL } from '@/constants';
import { PermissionsData } from '@/router/types';
import { Store } from '@/store/types';
import { setShopGateToken } from '@/api/ticket-service';
import RouteManagement from './RouteManagement';

/**
 * Sets route manager and before route listener
 * Need to fetch the queue every time the route changes for the slug info
 * Reuse existing queue from store if it is the same
 */
export async function globalNavigationGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
  routerManagement: RouteManagement,
  progress,
  store: Store,
): Promise<void> {
  progress.start();

  const { state, commit, dispatch } = store;
  const { params, query, meta } = to;

  const paramSlug = <string>params?.slug;
  const slug = helpers.slugFromHumanSlug(paramSlug || '');
  const shopSlug = <string>params?.shopSlug;

  let handle = '';

  const { gate, user, is_preview, shop: storeShop } = state;
  const { queue, shops, gate_token } = gate!;
  const permData: PermissionsData = {
    gate: { queue, shops, gate_token },
    user,
    shop: storeShop,
  };

  try {
    if (meta?.preview) {
      const currShop = await dispatch('shop/initShop', shopSlug).then((res) => res.data!);
      await dispatch('shop/initEvent', currShop.event_slug).then((res) => res.data!);

      if (helpers.isFramed() && !helpers.isWidget() && !is_preview) {
        const previewPermissionRequester = new PostMessageRequest(
          window,
          window.parent,
          VUEJONES_URL,
          { request: 'request_preview_permission' },
          (res) => res === 'allow_preview',
        );

        const hasPermission = await previewPermissionRequester.request().then((res) => res.success);
        commit('set_is_preview', hasPermission);
      }
    }

    if (meta?.gate && slug) {
      if (!(queue?.slug === slug)) {
        const date = to.query?.date as string;
        const response = await dispatch('gate/fetchGateInfo', { slug, date });
        permData.gate.queue = response.queue;
        permData.gate.shops = response.shops;
      }

      if (permData.gate.queue?.slug) {
        handle = await dispatch('gate/getGateHandle', permData.gate.queue.slug);
      }
    }

    if (meta?.shop && !meta.order && handle) {
      if (!permData.gate.gate_token) {
        permData.gate.gate_token = await dispatch('gate/getShopGateToken', { gate_handle: handle, slug });
      }
      setShopGateToken(permData.gate.gate_token);
    }

    if (meta?.auth || (meta?.shop && permData.gate.gate_token)) {
      const sso = query?.sso ? `${query.sso}` : '';
      permData.user = user;
      if (!permData.user) {
        try {
          const response = await dispatch('getLoggedInUser', sso);
          permData.user = response;
        } catch (e) {
          permData.user = null;
        }
      }

      if (sso) {
        const newQuery = { ...query };
        delete newQuery.sso;
        next({
          name: `${String(to.name)}`,
          query: newQuery,
          replace: true,
          params: to.params,
        });
      }
    }

    if ((meta?.shop && permData.gate.gate_token) || meta?.order) {
      const { shop, event } = state.shop!;
      let currShop = shop;
      if (permData.gate.queue && permData.gate.queue.shop_slugs.find((s) => s === shopSlug)) {
        if (!shop || shop.slug !== shopSlug) {
          const response = await dispatch('shop/initShop', shopSlug);
          currShop = response.data || null;
        }
        if (currShop && (!event || event.slug !== currShop.event_slug)) {
          await dispatch('shop/initEvent', currShop.event_slug);
        }
      }
    }

    routerManagement.resolveRoute({ to, from, next }, permData);
  } catch (e) {
    routerManagement.resolveRoute({ to, from, next }, permData);
    progress.fail();
    throw e;
  }
}

export default {
  install: (app, { router, store }) => {
    const routerManagement = new RouteManagement(router);
    const progress = app.config.globalProperties.$Progress;

    router.beforeEach((to, from, next) => globalNavigationGuard(to, from, next, routerManagement, progress, store));

    router.afterEach((_) => {
      progress.finish();
      // manual scrollBehavior implementation
      // vue-router scrollBehavior built-in config currently broken
      // see: https://github.com/vuejs/vue-router/issues/2593
      window.scrollTo(0, 0);
    });
  },
};
