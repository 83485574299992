<template>
  <div :class="['avatar', size]">
    <span v-if="!user.avatar" data-test-circle>
      <Icon :class="['avatar-icon', size]" type="person" :size="iconSize" />
    </span>
    <figure v-else class="avatar-image" :style="`background-image: url(${getResizedAvatar(user.avatar, 320)});`">
      <img :src="getResizedAvatar(user.avatar, 320)" :alt="user.first_name" data-test-avatar />
    </figure>
  </div>
</template>

<script>
  import helpers from '@/helpers';
  import Icon from '@/components/Icon.vue';

  export default {
    components: {
      Icon,
    },
    props: {
      user: {
        required: true,
        type: Object,
      },
      size: { type: String, default: '' },
    },
    computed: {
      iconSize() {
        switch (this.size) {
          case 'small':
            return '30px';
          case 'medium':
            return '40px';
          default:
            return '48px';
        }
      },
    },
    methods: {
      getResizedAvatar(avatar, size) {
        return helpers.getResizedImage(avatar, size);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .avatar {
    position: relative;
    top: -1px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid $color-border-light;
    background-color: $color-grey-light;
    overflow: hidden;
  }

  .avatar-image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0;

    img {
      display: none;
      width: 100%;
      height: auto;
    }
  }

  .avatar-icon {
    position: absolute;
    bottom: -10px;
    @include abs-center-x;

    :deep(path) {
      fill: rgba($color-grey, 0.7) !important;
    }
  }

  .small {
    &.avatar-icon {
      bottom: -7px;
    }

    &.avatar {
      height: 24px;
      width: 24px;
    }
  }

  .medium {
    &.avatar-icon {
      bottom: -9px;
    }

    &.avatar {
      height: 32px;
      width: 32px;
    }
  }
</style>
