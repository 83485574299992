import { Currency, ResaleItem, ShopStoreable as S, ShopTK } from '@/store/modules/shop/types';
import { Dictionary } from '@/store/types/types';

export type _ctx = {
  [Property in keyof _getters]: ReturnType<_getters[Property]>;
};
type _getters = {
  countTotalInCart(state: S): number;
  countTicketsInCart(state: S, g: _ctx): number;
  countUpsellsInCart(state: S, g: _ctx): number;
  ticketList(state: S): ShopTK[];
  countTicketsInResaleCart(state: S, g: _ctx): number;
  countUpsellsInResaleCart(state: S, g: _ctx): number;
  resaleTicketListByKind(state: S): Dictionary<ResaleItem[]>;
  resellerTicketListByKind(state: S, g: _ctx): Dictionary<ResaleItem[]>;
  resellerTicketList(state: S): ResaleItem[];
  resaleTicketList(state: S, g: _ctx): ResaleItem[];
  currency(state: S): Currency;
  layoutPageByTicketKind(state: S): Record<number, string>;
};
const getters: _getters = {
  countTotalInCart: ({ cart }) => cart.tickets.reduce((count, ticket) => count + (ticket.amount || 0), 0),
  countTicketsInCart: (_, g) => {
    const tk = g.ticketList;
    return tk.reduce((count, ticket) => {
      if (ticket && !ticket.upsell) {
        return count + (ticket.amountInCart || 0);
      }
      return count;
    }, 0);
  },
  countUpsellsInCart: (_, g) => {
    const tk = g.ticketList;
    return tk.reduce((count, ticket) => {
      if (ticket && ticket.upsell) {
        return count + (ticket.amountInCart || 0);
      }
      return count;
    }, 0);
  },
  ticketList: ({ ticketKinds, cart }) => {
    if (!ticketKinds) return [];

    return ticketKinds.map((ticket) => {
      const cartTicket = cart.tickets.find(({ id }) => id === ticket.id);
      const amountInCart = cartTicket ? cartTicket.amount : 0;
      return { ...ticket, amountInCart };
    });
  },

  countUpsellsInResaleCart: ({ resaleShop }, { resaleTicketList }): number => {
    if (!resaleShop) return 0;
    return resaleTicketList.reduce((acc, ticket) => {
      const kind = resaleShop.ticket_kinds.find((tk) => tk.id === ticket.kind_id);
      if (kind && kind.upsell) return acc + (ticket.amountInCart || 0);
      return acc;
    }, 0);
  },
  countTicketsInResaleCart: ({ resaleCart: { tickets } }): number =>
    tickets.reduce((count, { amount }) => {
      count += amount;
      return count;
    }, 0),
  resaleTicketListByKind: ({ resaleShop, resellersByKind, resaleCart }) => {
    if (!resaleShop || !resaleShop.ticket_kinds) return {} as Dictionary<ResaleItem[]>;
    const sellersByKind = resellersByKind;
    const ticketKinds = resaleShop.ticket_kinds;

    return ticketKinds.reduce((acc, tk) => {
      const resellers = sellersByKind[tk.id] || [];

      acc[tk.id] = resellers.map((reseller) => {
        const cartTicket = resaleCart.tickets.find(
          ({ kind, seller, price }) =>
            seller === reseller.user_id && price === reseller.price && kind === reseller.kind_id,
        );

        const amountInCart = cartTicket ? cartTicket.amount : 0;
        return { ...reseller, amountInCart };
      });
      return acc;
    }, {} as Dictionary<ResaleItem[]>);
  },
  resellerTicketListByKind: (_, g) =>
    g.resellerTicketList.reduce((acc, item) => {
      acc[item.kind_id] = acc[item.kind_id] || [];
      acc[item.kind_id].push(item);
      return acc;
    }, {} as Dictionary<ResaleItem[]>),
  resellerTicketList: ({ resaleCart, resellerResaleItems }) =>
    resellerResaleItems.map((item) => {
      const cartTicket = resaleCart.tickets.find(
        ({ kind, seller, price }) => seller === item.user_id && kind === item.kind_id && price === item.price,
      );
      const amountInCart = cartTicket ? cartTicket.amount : 0;
      return { ...item, amountInCart };
    }),
  resaleTicketList: (_, g) => {
    const listByKind = g.resaleTicketListByKind;
    const resellerList = g.resellerTicketList;

    return Object.values(listByKind)
      .reduce((acc, sellers) => acc.concat(sellers), [])
      .concat(resellerList);
  },

  currency: ({ event }): Currency => {
    if (!event || !event.currency) {
      return { code: 'EUR', decimals: 2, name: 'Euro' };
    }
    return event.currency;
  },
  layoutPageByTicketKind: ({ shop }): Record<number, string> => {
    if (!shop) return {};
    return shop.ticket_layout.reduce((acc, page) => {
      page.groups.forEach((group) => {
        group.members.forEach((m) => {
          acc[m] = page.slug;
        });
      });
      return acc;
    }, {} as Record<number, string>);
  },
};

export default getters;
