import { Store } from '@/store/types';
import { GP_HttpError } from '@getprotocollab/get-pal';

const Reservation = () => import('@/views/Reservation.vue');
const Invitation = () => import('@/views/reservation/Invitation.vue');
const InvitationDetails = () => import('@/views/reservation/InvitationDetails.vue');
const InvitationClaim = () => import('@/views/reservation/InvitationClaim.vue');
const InvitationStatus = () => import('@/views/reservation/InvitationStatus.vue');

export default (_, store: Store) => ({
  path: '/invitation/:eventSlug/:uuid/',
  component: Reservation,
  meta: {
    permissions: {
      access: () => true,
    },
  },
  beforeEnter: async (to, _from, next) => {
    const { dispatch, commit, state } = store;
    const { eventSlug } = to.params;

    try {
      await dispatch('shop/initEvent', eventSlug);
    } catch (err: any) {
      if (err instanceof GP_HttpError) {
        if (err.response?.status === 404) {
          next({ name: 'notFound', params: { pathMatch: to.path.substring(1).split('/') } });
          return;
        }
      }
      throw err;
    }

    if (state.shop.event) {
      await dispatch('shop/getOrganization', state.shop.event.organization);
    }

    if (state.shop.event && state.shop.event.cover) {
      commit('set_layout_cover', { cover: state.shop.event.cover });
    }

    next();
  },
  children: [
    {
      path: '',
      component: Invitation,
      redirect: { name: 'invitation' },
      children: [
        {
          path: 'details',
          name: 'invitation',
          component: InvitationDetails,
          meta: {
            showNavbar: false,
            permissions: {
              access: () => true,
            },
          },
        },
        {
          path: 'claim',
          name: 'invitation-claim',
          component: InvitationClaim,
          meta: {
            showNavbar: true,
            auth: true,
            permissions: {
              access: ({ user }, to) => !!user && !to.query.profile,
              redirect: (_state, to) => ({
                name: 'profile',
                query: {
                  next: to.path,
                  shop: store.state.shop.event?.default_shop,
                  orgId: store.state.shop.event?.organization,
                },
              }),
            },
          },
        },
        {
          path: 'status',
          name: 'invitation-status',
          component: InvitationStatus,
          meta: {
            showNavbar: true,
            auth: true,
            layout: 'Empty',
            permissions: {
              access: ({ user }) => !!user,
              redirect: () => ({
                name: 'account',
              }),
            },
          },
        },
      ],
    },
  ],
});
