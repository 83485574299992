import { App } from 'vue';
import { Store } from '@/store/types';
import { ACCESS_TYPES } from '@/helpers/constants';
import { RouteLocationNormalized } from 'vue-router';

const WalletConnect = () => import('@/views/shop/WalletConnect.vue');

export default (app: App, store: Store) => ({
  path: 'wallet-connect',
  name: 'wallet-connect',
  component: WalletConnect,
  meta: {
    gate: true,
    showNavbar: true,
    shop: true,
    permissions: {
      access: ({ user, shop }) => !!user && shop?.shop?.access_type === ACCESS_TYPES.COLLECTIBLES,
      redirect: ({ user, shop }, to: RouteLocationNormalized) => {
        if (shop?.shop?.access_type !== ACCESS_TYPES.COLLECTIBLES) {
          return { name: 'shop', params: { shopSlug: to.params.shopSlug } };
        }

        return {
          name: 'account',
          query: {
            next: to.fullPath,
            shop: to.params.shopSlug,
            orgId: store.state.shop.shop?.organization_id,
          },
        };
      },
    },
  },
});
