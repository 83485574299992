import livestream from '@/router/routes/tickets/livestream';
import ticket from '@/router/routes/tickets/ticket';

const Details = () => import('@/views/tickets/index.vue');

export default (app, store) => ({
  path: ':eventSlug',
  component: Details,
  children: [livestream(app, store), ticket(app, store)],
  beforeEnter: async (to, from, next) => {
    const { commit, dispatch, state } = store;
    const { eventSlug } = to.params;

    try {
      await Promise.all([dispatch('shop/initEvent', eventSlug), dispatch('tickets/getTickets', eventSlug)]);
    } catch (e) {
      next({ name: 'tickets' });
      throw e;
    }

    if (state.shop.event && state.shop.event.cover) {
      commit('set_layout_cover', { cover: state.shop.event.cover });
    }

    next();
  },
});
