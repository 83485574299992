import { App } from 'vue';
import { NavigationGuardNext, RouteLocationNormalized, Router } from 'vue-router';
import helpers from '@/helpers';

const setThemeColor = (color: string) => {
  const validColor = helpers.getAcceptableLowContrastColor(color);
  if (!validColor) {
    return false;
  }
  document.documentElement.style.setProperty('--color-theme', validColor);
  return true;
};

const handleInvert = (i: string) => {
  if (i !== '1') return false;
  document.body.classList.add('is-inverted');
  return true;
};

const handleThemeColor = (c: string) => helpers.isWidget() && setThemeColor(c);
const handleTracker = (key: string, id: string, app: App) => {
  app.config.globalProperties.gtm.track({ event: 'trackerInit', [key]: id });
  return true;
};
const handleGlobalTracker = (id: string, app: App) => handleTracker('trackerId', id, app);
const handleTrackerFb = (id: string, app: App) => handleTracker('fbId', id, app);
const handleTrackerGa = (id: string, app: App) => handleTracker('gaId', id, app);
const handleTrackerLi = (id: string, app: App) => handleTracker('liId', id, app);
const handleTrackerTw = (id: string, app: App) => handleTracker('twId', id, app);
const handleTrackerTc = (id: string, app: App) => handleTracker('referral_id', id, app);

export function handleQuery(query: string, app: App) {
  const params = new URLSearchParams(query);
  const handlers = {
    c: handleThemeColor,
    i: handleInvert,
    t: handleGlobalTracker,
    fb: handleTrackerFb,
    ga: handleTrackerGa,
    li: handleTrackerLi,
    tw: handleTrackerTw,
    tc: handleTrackerTc,
    // Permanent queries without a function
    // need for analytics
    gtm: () => true,
    sgtm: () => true,
    _ga: () => true,
    utm_source: () => true,
    utm_medium: () => true,
    utm_campaign: () => true,
    utm_term: () => true,
    utm_content: () => true,
    seller: () => true,
  };

  return Object.entries(handlers).reduce((acc, [key, handler]) => {
    const value = params.get(key);
    if (value && handler(value, app)) {
      acc[key] = value;
    }
    return acc;
  }, {});
}

export const permParamRouteGuard = (
  { to, from, next }: { to: RouteLocationNormalized; from: RouteLocationNormalized; next: NavigationGuardNext },
  permanentParams,
) => {
  const isMissingPermParams = Object.keys(permanentParams).some((p) => to.query[p] === undefined);
  if (isMissingPermParams) {
    next({
      name: to.name as string,
      params: { ...to.params },
      query: { ...permanentParams, ...to.query },
    });
    return;
  }
  next();
};

export default {
  install: (app: App, { query, router }: { query: string; router: Router }) => {
    const permanentParams = handleQuery(query, app);
    router.beforeEach((to, from, next) => permParamRouteGuard({ to, from, next }, permanentParams));
    app.config.globalProperties.permanentParams = permanentParams;
    app.provide('permanentParams', permanentParams);
  },
};
