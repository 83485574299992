import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { App } from 'vue';
import profile from '@/router/routes/profile';
import account from '@/router/routes/account';
import gatekeeper from '@/router/routes/gatekeeper';
import tickets from '@/router/routes/tickets';
import invite from '@/router/routes/invite';
import preview from '@/router/routes/preview';
import reservation from '@/router/routes/reservation';
import { Store } from '@/store/types';

const NotFound = () => import('@/views/errors/NotFound.vue');

function routes(app: App, store: Store) {
  return [
    tickets(app, store),
    account(app, store),
    profile(app, store),
    invite(app, store),
    preview(app, store),
    gatekeeper(app, store),
    reservation(app, store),
    {
      path: '/:pathMatch(.*)*',
      name: 'notFound',
      component: NotFound,
      meta: {
        layout: 'Empty',
        permissions: {
          access: () => true,
        },
      },
    },
    {
      path: '',
      redirect: '/tickets',
    },
  ];
}

function Router(app: App, store: Store) {
  return createRouter({
    history: createWebHistory(),
    routes: routes(app, store) as RouteRecordRaw[],
  });
}

export default (app: App, store: Store) => Router(app, store);
