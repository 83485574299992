<template>
  <div :key="$i18n.locale" class="date-card">
    <div v-if="multiday" class="multi-date">
      <div class="starts">
        <span class="multi-day">{{ dayLong }}</span>
        <span class="month">
          {{ month }}
        </span>
      </div>
      <div class="ends">
        <span class="multi-day">{{ eventEnds.day }}</span>
        <span class="month">
          {{ eventEnds.month }}
        </span>
      </div>
      <span v-if="showYear" class="year">{{ year }} </span>
    </div>
    <div v-else class="simple-date">
      <div class="top">{{ month }}</div>
      <div class="bottom">
        <span class="day">{{ dayShort }}</span>
        <span v-if="showYear" class="year">{{ year }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import helpers from '@/helpers';

  export default {
    inject: ['moment'],
    props: {
      date: { type: String, required: true },
      ends: { type: String, required: true },
      multiday: { type: Boolean, default: true },
      zone: { type: String, required: false, default: '' },
      endsOnPreviousDay: { type: Boolean, required: true },
    },
    computed: {
      showYear() {
        const diff = this.moment(this.date).diff(this.moment(), 'years');
        if (diff === 0) {
          return false;
        }
        return true;
      },
      eventEnds() {
        const date = helpers.eventEnds(this.ends, this.zone, this.endsOnPreviousDay);

        return {
          date,
          day: helpers.formatDate(date, 'DD', this.zone),
          month: helpers.formatDate(date, 'MMM', this.zone),
        };
      },
      year() {
        return helpers.formatDate(this.date, 'YYYY', this.zone);
      },
      month() {
        return helpers.formatDate(this.date, 'MMM', this.zone);
      },
      dayLong() {
        return helpers.formatDate(this.date, 'DD', this.zone);
      },
      dayShort() {
        return helpers.formatDate(this.date, 'D', this.zone);
      },
    },
  };
</script>

<style lang="scss" scoped>
  $border-radius: 4px;

  .date-card {
    display: inline-block;
    text-align: center;
    flex-shrink: 0;
  }

  .simple-date {
    width: 40px;
  }

  .top {
    display: block;
    padding: 3px 4px;
    color: $color-white;
    text-transform: uppercase;
    font-size: 0.75em;
    line-height: 1;
    font-weight: $weight-medium;
    border-radius: $border-radius $border-radius 0 0;
    @include background-gradient;

    .is-widget & {
      background: var(--color-theme);
    }
  }

  .bottom {
    display: block;
    color: $color-text;
    line-height: 1;
    letter-spacing: 0.025em;
    border: 1px solid $color-border-grey-light;
    border-top: none;
    border-radius: 0 0 $border-radius $border-radius;
    background-color: $color-white;
  }

  .day {
    display: block;
    padding: 4px;
    font-size: $size-large;
    font-weight: $weight-bold;
  }

  .multi-date {
    display: flex;
    flex-direction: column;
    width: 55px;
    border: 1px solid $color-border-grey-light;
    border-radius: $border-radius;
  }

  .starts {
    display: flex;
    justify-content: space-between;
    padding: 0px 4px 0px 4px;
    border-bottom: 1px dashed $color-border-grey-light;
  }

  .ends {
    display: flex;
    padding: 0px 4px 0px 4px;
    justify-content: space-between;
  }

  .multi-day {
    font-size: $size-small;
    font-weight: $weight-bold;
    color: $color-black;
  }

  .month {
    font-size: $size-small;
    font-weight: $weight-medium;
    text-transform: uppercase;
    color: $color-primary;
    color: var(--color-theme);
  }

  .year {
    display: block;
    padding: 3px 2px 1px;
    font-size: $size-tiny;
    display: block;
    color: $color-text-grey;
    background-color: $color-grey-light;
  }
</style>
