import { App } from 'vue';
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { PermissionsData } from '@/router/types';
import { OrderState } from '@/constants';
import { Store } from '@/store/types';

const Overview = () => import('@/views/shop/order/Overview.vue');

export default (app: App, store: Store) =>
  ({
    path: '',
    name: 'order',
    component: Overview,
    meta: {
      auth: true,
      gate: true,
      shop: true,
      order: true,
      showNavbar: true,
      permissions: {
        access: (data: PermissionsData, to: RouteLocationNormalized): boolean => {
          const { user, gate: { queue, shops } = {} } = data;
          const { shopSlug } = to.params;
          const { state } = store;

          if (!shops || !queue || !queue.shop_slugs.find((s) => s === shopSlug)) return false;

          if (!state.gate) return false;

          if (!user) return false;

          if (import.meta.env.VITE_APP_API_MODE !== 'sharded' && !user.profile_complete) return false;

          return true;
        },
        redirect: (data: PermissionsData, to: RouteLocationNormalized) => {
          const { user, gate: { queue, shops } = {} } = data;
          const {
            params: { shopSlug },
            path,
            fullPath,
          } = to;
          const { state } = store;

          if (!shops || !queue || !queue.shop_slugs.find((s) => s === shopSlug)) {
            return {
              name: 'queue',
              query: { next: fullPath },
            };
          }

          if (shops.length > 0 && !state.gate) {
            return { name: 'notFound', params: { 0: path } };
          }

          if (!user) {
            return {
              name: 'account',
              query: { next: fullPath },
            };
          }
          return {
            name: 'profile',
            query: { next: fullPath },
          };
        },
      },
    },
    beforeEnter: async (to, _, next) => {
      const { dispatch, state } = store;
      const { shop, organization } = state.shop!;
      const { order } = state.order!;
      const orderId = parseInt(to.params.id as string, 10);

      // if order has applied credits on pending order
      // we free the credits so they can be applied again
      if (order?.state === OrderState.PENDING && parseFloat(order?.credits) > 0) {
        await dispatch('order/applyCredits', { amount: '0', orderId });
        await dispatch('order/getOrder', orderId);
      }

      await dispatch('order/getCredits', orderId);

      if (shop?.organization_id && (!organization || organization.id !== shop.organization_id)) {
        await dispatch('shop/getOrganization', shop.organization_id);
      }

      next();
    },
  } as RouteRecordRaw);
