import { App } from 'vue';
import details from '@/router/routes/gatekeeper/shop/details';
import checkout from '@/router/routes/gatekeeper/shop/checkout';
import order from '@/router/routes/gatekeeper/shop/order';
import resale from '@/router/routes/gatekeeper/shop/resale';
import wallet from '@/router/routes/gatekeeper/shop/wallet';
import { Store } from '@/store/types';

const Shop = () => import('@/views/Shop.vue');

export default (app: App, store: Store) => ({
  path: ':shopSlug',
  component: Shop,
  children: [details(app, store), checkout(app, store), order(app, store), resale(app, store), wallet(app, store)],
});
