import { App } from 'vue';
import { Store } from '@/store/types';

export default (app: App, store: Store) => ({
  path: '/preview/:slug/:shopSlug/checkout/:shopType',
  name: 'preview-checkout',
  meta: {
    preview: true,
    permissions: {
      access: (): boolean => store.state.is_preview,
    },
  },
  beforeEnter: async (to, from, next) => {
    next({
      name: 'preview-order',
      params: {
        ...to.params,
        id: 'preview-order',
      },
    });
  },
});
