<template>
  <div
    class="spinner"
    role="progress"
    :style="`width: ${size}; height: ${size};
      animation-duration: ${speed};`"
  >
    <span class="circle circle-1">
      <span
        class="circle-inner"
        :style="`border-color: ${color};
          border-width: ${thickness};
          animation-duration: ${speed};`"
      ></span>
    </span>
    <span class="circle circle-2">
      <span
        class="circle-inner"
        :style="`border-color: ${color};
          border-width: ${thickness};
          animation-duration: ${speed};`"
      ></span>
    </span>
  </div>
</template>

<script>
  export default {
    props: {
      size: {
        type: String,
        default: '2rem',
        required: false,
      },
      thickness: {
        type: String,
        default: '4px',
        required: false,
      },
      speed: {
        type: String,
        default: '800ms',
        required: false,
      },
      color: {
        type: String,
        default: '',
        required: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @keyframes loader-spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes loader-spinner-1 {
    from {
      transform: rotate(60deg);
    }
    to {
      transform: rotate(205deg);
    }
  }

  @keyframes loader-spinner-2 {
    from {
      transform: rotate(30deg);
    }
    to {
      transform: rotate(-115deg);
    }
  }

  .spinner {
    display: block;
    animation: loader-spinner 0.8s linear infinite;

    .circle {
      display: block;
      width: 100%;
      height: 50%;
      overflow: hidden;
    }

    .circle-inner {
      display: block;
      transform: rotate(45deg);
      border-radius: 50%;
      border: 4px solid $color-primary;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      width: 100%;
      height: 200%;
      animation-name: loader-spinner-1;
      animation-duration: 0.8s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: cubic-bezier(0.25, 0.1, 0.5, 1);

      .is-widget & {
        border-color: var(--color-theme) !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
      }
    }

    .circle-2 {
      transform: rotate(180deg);

      .circle-inner {
        animation-name: loader-spinner-2;
      }
    }
  }
</style>
