import { App } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import { Store } from '@/store/types';

const Status = () => import('@/views/shop/order/Status.vue');

export default (app: App, store: Store) =>
  ({
    path: '/preview/:slug/:shopSlug/:shopType/orders/:id/status',
    name: 'preview-status',
    component: Status,
    meta: {
      preview: true,
      layout: 'Empty',
      permissions: {
        access: (): boolean => store.state.is_preview,
      },
    },
    beforeEnter: async (to, _, next) => {
      next();
    },
  } as RouteRecordRaw);
