{
  "global": {
    "language": "Français",
    "actionClose": "Fermer",
    "actionBack": "Retour",
    "actionCancel": "Annuler",
    "optional": "facultatif",
    "head": {
      "title": "@:brand.name",
      "description": "Tu veux vendre tes propres billets honnêtes ? @:brand.nameShort élimine la fraude aux billets et le scalping grâce à une technologie innovante. Des spectacles de théâtre aux concerts ou aux diffusions en direct : avec @:brand.nameShort, la vente et la revente de billets sont simples, transparentes et sécurisées.",
      "twitter": "@:brand.twitter",
      "event": {
        "title": "Billets pour {title}",
        "open": "Commande tes billets honnêtes pour {title}, le {date} à {location} sur @:brand.name",
        "queue": "Commande tes billets honnêtes pour {title} sur @:brand.name",
        "resale": "Consulte tous les billets sur notre marché de billets pour {title}, le {date} à {location} sur @:brand.name",
        "reseller": "Mes billets pour {title} le {date} à {location} sont à vendre sur @:brand.name. Profitez-en tant qu'ils sont disponibles !"
      }
    },
    "fullscreen": "Passe en mode plein écran pour une meilleure expérience",
    "cookiesConsent": {
      "description": "Nous utilisons des cookies pour une meilleure expérience.",
      "actionAccept": "Accepter les cookies",
      "actionMore": {
        "name": "En savoir plus"
      }
    },
    "logout": {
      "logoutText": "Déconnexion",
      "logoutConfirmText": "Es-tu sûr(e) ? Tu recevras un nouveau code d'accès quand tu te reconnecteras."
    },
    "navigation": {
      "account": "Compte",
      "eventList": "Evénements",
      "myTickets": "Mes billets",
      "login": "Connexion",
      "profile": "Profil"
    }
  },
  "brand": {
    "name": "#.name",
    "nameShort": "#.nameShort",
    "twitter": "#.twitter",
    "homepageUrl": "#.homepageUrl",
    "homepageUrlShort": "#.homepageUrlShort",
    "supportUrl": "#.supportUrl",
    "livestreamSupportUrl": "#.livestreamSupportUrl",
    "privacyPolicyUrl": "#.privacyPolicyUrl",
    "termsUrl": "#.termsUrl",
    "androidAppstoreUrl": "#.androidAppstoreUrl",
    "appleAppstoreUrl": "#.appleAppstoreUrl"
  },
  "validation": {
    "errors": {
      "required": "Ce champ ne peut pas être vide",
      "email": "Cette adresse e-mail semble invalide",
      "phoneNumber": "Ceci n'est pas un numéro de téléphone mobile valide",
      "rateLimit": {
        "message": "Trop de tentatives de vérification.",
        "minutes": "Merci de réessayer dans 2 minutes.",
        "seconds": "Merci de réessayer dans {seconds} secondes."
      },
      "globalBlock": {
        "message": "Too many verification attempts.",
        "minutes": "Please try again in a few minutes.",
        "seconds": "Please try again in {seconds} seconds."
      },
      "birthdate": "Cette date de naissance semble invalide"
    }
  },
  "messages": {
    "saveProfile": "Profil bien enregistré !",
    "seated": "Si tu as acheté des places assises, tu recevras tes numéros de siège au moins 24 heures avant l'événement, par e-mail et dans l'application @:brand.nameShort !",
    "seatsAssigned": "Remarque : Si tu achètes des places assises auprès de différentes personnes, nous ne pouvons pas garantir que tes sièges seront côte à côte."
  },
  "errors": {
    "default": "Oups ! Une erreur est survenue...<br/>Tu peux <a onClick='window.location.reload(false)'>actualiser cette page</a> et réessayer.",
    "notStarted": "La vente de billets n'a pas encore commencé. Merci de vérifier que l'heure et la date paramétrées sur ton appareil sont correctes.",
    "invalidURL": "Ce lien d'accès a déjà été utilisé ou n'est pas valide.",
    "saveProfile": "Erreur : Les informations du profil n'ont pas pu être enregistrées.",
    "exceeding_limits": "Tu dépasses le nombre maximal de billets que tu as le droit d'acheter.",
    "token_different_user": "Si tu t'es connecté avec un numéro de téléphone mobile différent que celui fourni en rejoignant la file, merci d'utiliser le bouton d'actualisation pour rejoindre à nouveau avec le numéro correct.",
    "token_expired": "Ta session pour cette boutique a expiré, merci d'utiliser le bouton d'actualisation pour entrer à nouveau",
    "paymentCanceled": "Tu as annulé ton paiement. N'hésite pas à réessayer.",
    "paymentFailed": "Ton paiement a échoué. N'hésite pas à réessayer.",
    "invalid_order_state": "Ta commande a expiré ou a déjà été réglée. Tu peux de nouveau commander des billets s'ils sont disponibles.",
    "orderNotFound": "La commande demandée n'est pas ou plus valide. Tu peux de nouveau commander des billets s'ils sont disponibles.",
    "user_cannot_share": "Ce compte n'a pas l'autorisation de partager des billets.",
    "already_in_group": "Tu fais déjà partie d'un groupe pour cet événement. Essaie de quitter d'abord l'autre groupe.",
    "user_cannot_leave": "Tu ne peux pas quitter, car tu as créé le groupe.",
    "group_is_full": "Tu ne peux pas rejoindre ce groupe, car il est déjà plein !",
    "expired_code": "Cette invitation a expiré. Demande-en une nouvelle à ton ami(e).",
    "invalid_code": "Ce code d'invitation n'est pas valide.",
    "system_unavailable": "Notre service est momentanément indisponible en raison de problèmes techniques. Nous travaillons actuellement à une solution. Nous nous excusons pour le dérangement.",
    "payment": {
      "canceledOrFailed": "Ton paiement a été annulé ou refusé par l'émetteur. Ta commande reste valide. N'hésite pas à réessayer.",
      "genericError": "Une erreur est survenue avec le fournisseur de service de paiement. Si le problème persiste, il est possible que les paiements ne soient actuellement pas possibles. Merci de réessayer plus tard."
    },
    "reservationInvitation": {
      "invalid_state": "I am sorry, there is no valid invitation associated with this link!",
      "claimed": "This invitation has already been claimed!",
      "expired": "This invitation is no longer valid!",
      "unkown_error": "Something went wrong! Please try again later."
    }
  },
  "notFound": {
    "head": {
      "title": " @:global.head.title",
      "complement": "Page introuvable"
    },
    "title": "Cette page est introuvable",
    "description": "Tu cherches peut-être une (pré)vente d'événement qui n'a pas encore commencé. Dans ce cas, merci de réessayer plus tard ! Souhaites-tu en savoir plus sur @:brand.name ?",
    "action": "Accède à @:brand.homepageUrlShort"
  },
  "appstore": {
    "footer": "Installe l'appli @:brand.name sur ton smartphone. La façon la plus facile d'entrer !",
    "action": "Installer l'appli",
    "install": {
      "title": "Installe l'appli mobile @:brand.name",
      "description": "Choisis ton type de smartphone pour accéder à l'app store :",
      "forAndroid": "Installer l'appli @:appstore.android.name",
      "forIos": "Installer l'appli @:appstore.ios.name",
      "alsoAndroid": "Également disponible pour @:appstore.android.name",
      "alsoIos": "Également disponible pour @:appstore.ios.name"
    },
    "android": {
      "name": "Android",
      "store": "Google Play Store",
      "description": "Découvrir sur @:appstore.android.name",
      "url": "@:brand.androidAppstoreUrl"
    },
    "ios": {
      "name": "iPhone (iOS)",
      "store": "Apple App Store",
      "description": "Télécharger dans l'@:appstore.ios.name",
      "url": "@:brand.appleAppstoreUrl"
    }
  },
  "queue": {
    "start": {
      "queue": {
        "header": {
          "title": "Rejoindre la file pour l'événement",
          "description": "Tes billets seront associés à ton numéro de téléphone mobile : vérifie bien tes détails !"
        },
        "actionEnter": "Entrer dans la file"
      },
      "preQueue": {
        "header": {
          "title": "La vente commence le {day} {month} à {time}",
          "description": "Rejoins la pré-file d'attente et tu recevras une position aléatoire pour la vente de billets à {time}."
        },
        "actionEnter": "Entrer dans la pré-file"
      },
      "form": {
        "phonePlaceholder": "Ton numéro de téléphone mobile",
        "emailPlaceholder": "Ton adresse e-mail",
        "focusPhonePlaceholder": "+31612345678",
        "focusEmailPlaceholder": "emailaddress{'@'}gmail.com"
      },
      "privacy": {
        "description": "En saisissant tes détails, tu rejoins la file et acceptes {0}.",
        "name": "notre politique de confidentialité"
      }
    },
    "allEventsPast": "Cet événement a expiré et les billets ne sont plus disponibles.",
    "countdown": {
      "positionInfoZero": "C'est presque ton tour, patience...",
      "positionInfoOne": "Il n'y a que {numberInFront} personnes devant toi.",
      "positionInfoMultiple": "{numberInFront} personnes sont actuellement devant toi.",
      "calculatePosition": "Calcul de ta position",
      "approx": "env.",
      "minutes": "minutes restantes | minute restante | minutes restantes",
      "hour": "Plus d'une heure...",
      "soldout": "aucune chance (épuisé)",
      "calculateChance": "Wow, tu avances vite ! Nous calculons la vitesse de la vente et te montrerons tes chances dans une minute.",
      "single": "Tu as {chance} que les billets seront disponibles une fois que ce sera ton tour.",
      "groupSingle": "Tu as {chance} que les billets pour ton événement préféré seront disponibles, tiens bon !",
      "groupMultiple": "Tu as {chance} que les billets pour au moins un de tes {count} événements préférés soient disponibles, tiens bon !",
      "groupEmtpy": "Sélectionne tes événements préférés et choisis-en un quand c'est ton tour.",
      "selectFav": "Sélectionner les événements préférés",
      "changeFav": "Ajuster les événements préférés"
    },
    "eventList": {
      "otherData": "Tous les événements",
      "soldout": "Épuisé",
      "allEvents": "Voir tous les événements",
      "favoriteEvents": "Tes événements préférés",
      "search": "Filtrer par lieu",
      "showsIn": "Aucun événement à | 1 événement à | {count} événements à",
      "other": "Autres lieux",
      "modal": {
        "title": "Sélectionne ton ou tes événement(s) préféré(s)",
        "subtitle": "Quand c'est ton tour, tes événements préférés seront affichés en premier. Facile !"
      },
      "few_available": "presque épuisé",
      "no_available": "épuisé",
      "tickets_in_carts": "épuisé"
    },
    "preQueue": {
      "starting": {
        "title": "Attribution de la position...",
        "description": "Tu recevras une position aléatoire dans la file. De cette façon, tout le monde a une chance équitable d'obtenir des billets."
      },
      "waiting": {
        "title": "Décompte jusqu'à {time}",
        "description": "À l'ouverture de la file de vente, tu recevras une position aléatoire et devras attendre ton tour pour acheter des billets."
      },
      "footer": {
        "noNumber": "Si ton numéro de téléphone est incorrect, tu ne pourras pas acheter de billets : {link} pour entrer à nouveau.",
        "description": "Tu t'es inscrit(e) avec le numéro mobile {number}. S'il est incorrect, tu ne pourras pas acheter de billets : {link} pour entrer à nouveau.",
        "link": "Clique ici"
      }
    },
    "paused": {
      "queuePaused": "File d'attente en pause",
      "details": "En raison de la demande importante, nous ne laissons actuellement personne passer par la file d'attente. Un instant, s'il te plaît..."
    },
    "turn": {
      "single": {
        "title": "C'est ton tour !",
        "description": "Tu peux maintenant commander tes billets",
        "order": "Commander"
      },
      "group": {
        "title": "C'est ton tour !",
        "description": "Sélectionne un événement. Tu peux toujours revenir et en choisir un autre."
      }
    },
    "open": {
      "title": "Choisir un événement"
    },
    "notStarted": {
      "description": "Cette vente n'a pas encore commencé. Elle commence le {date} à {time}.",
      "action": "Voir les événements",
      "support": "Merci de consulter notre {0} pour plus d'informations à propos du processus de commande.",
      "supportLink": "page d'assistance"
    },
    "soldout": {
      "title": "Cet événement est actuellement épuisé",
      "details": "Mauvaise nouvelle : Tous les billets disponibles ont été vendus. Il est possible qu'ils soient mis en vente, alors vérifie plus tard."
    },
    "access": {
      "description": "Cette boutique n'est accessible que via les liens d'accès fournis par l'organisateur de l'événement. Une fois que tu as demandé l'accès à une boutique, tu pourras toujours revenir.",
      "checkAccess": "Vérifier l'accès"
    }
  },
  "shop": {
    "global": {
      "tickets": "Billets | Billet | Billets",
      "total": "Total",
      "free": "Gratuit",
      "serviceCosts": "frais de service",
      "serviceCostsLong": "@:shop.global.serviceCosts compris",
      "serviceCostsShort": "@:shop.global.serviceCosts compr.",
      "buyerFee": "Comprend un frais de {fee}",
      "floorplan": "Plan",
      "back": "Modifier ta commande"
    },
    "tabs": {
      "info": "Informations",
      "seated": "& sièges"
    },
    "eventInfo": {
      "openDoors": "Ouverture",
      "startTime": "Début",
      "endTime": "Fin",
      "openMap": "Ouvrir sur la carte",
      "timezone": "Remarque : Le fuseau horaire est {timezone}"
    },
    "floorplan": {
      "description": "Sélectionne une section du plan pour acheter tes billets.",
      "action": "Commande via le plan",
      "list": "Ou sélectionne ton étage dans la liste ci-dessous."
    },
    "resale": {
      "actionBack": "Retour à la boutique principale",
      "resaleLink": "Afficher le marché de billets",
      "available": "{count} disponible(s)",
      "originalPrice": "Prix le plus bas {price}",
      "showMore": "Afficher plus de billets",
      "checkMore": "Chercher d'autres billets",
      "refreshMessage": "La liste actuelle pourrait contenir d'anciennes informations de billets. Veux-tu actualiser la liste ?",
      "emptyMessage": "Personne n'offre actuellement de billets via notre marché de billets. Reviens vérifier plus tard !",
      "noOthers": "Actuellement, les seuls billets disponibles sont offerts par {seller}. Regarde ci-dessus ou actualise la page.",
      "noOthersFallback": "Actuellement, les seuls billets disponibles sont affichés ci-dessus. Tu peux aussi rafraîchir la page.",
      "resellerItems": {
        "manyResellers": "{names} et {others_count} autres",
        "isSelling": "vend des billets. | vendent des billets.",
        "noTickets": "Cette personne n'a (plus) aucun billet à vendre.",
        "others": "Billets proposés par d'autres :"
      },
      "resaleItem": {
        "price": "Billets à {price} chacun | {count} billet à {price} | {count} billets à {price} chacun",
        "priceUpsell": "À {price} chacun | {count} à {price} | {count} à {price} chacun",
        "seller": "Offert par {name}",
        "justSold": {
          "warning": "Vient d'être vendu !",
          "message": "Quelqu'un a été plus rapide"
        }
      },
      "resaleHeader": {
        "title": "Les billets de cette boutique sont épuisés !",
        "description": "Toutefois : n'importe qui ayant des billets peut les offrir à la vente à tout moment sur notre marché de billets !"
      },
      "feesInfo": {
        "title": "Explication des frais du marché de revente",
        "description": "Un frais de service de {percentage} par billet s'applique.",
        "descriptionWithFixedFees": "Un frais de service de {percentage} et un coût de transaction fixe de {fixed} par billet s'applique.",
        "buyerFee": "Frais d'acheteur",
        "totalPrice": "Prix que tu paies",
        "footerWithFixedFees": "Pour le vendeur, un frais de service de {percentage} et un coût de transaction fixe de {fixed} par billet s'applique. C'est déjà inclus dans ton prix.",
        "footer": "Pour le vendeur, un frais de service de {percentage} par billet s'applique. C'est déjà inclus dans ton prix"
      }
    },
    "tickets": {
      "soldout": "Épuisé",
      "ticketsLeft": "Il reste {left}",
      "tutorial": "Besoin d'aide ?",
      "upsell": "Supplément"
    },
    "footer": {
      "maxTickets": "{amount} billet maximum par personne | {amount} billets maximum par personne",
      "userMaxTickets": "Tu ne peux plus commander de billets | Tu peux encore commander {amount} billet | Tu peux encore commander {amount} billets",
      "maxTicketsLeft": "sur {amount} max.",
      "actionOrder": "Commander",
      "actionNext": "Suivant"
    },
    "state": {
      "past": {
        "title": "Cet événement a déjà eu lieu",
        "description": "Plus aucun billet n'est disponible."
      },
      "soldoutWithoutResale": {
        "title": "Aucun billet n'est actuellement disponible",
        "description": "Il est possible que des billets soient mis en vente, alors reviens vérifier plus tard."
      }
    },
    "datepicker": {
      "toggle": "Sélectionne un jour différent",
      "legend": {
        "title": "Sélectionne un jour pour acheter tes billets",
        "soldout": "Ces jours sont actuellement épuisés",
        "hasEvents": "Des billets sont encore disponibles pour ces jours"
      },
      "shopList": {
        "shopCount": "{count} événements ont lieu ce jour",
        "description": "Choisis un événement pour acheter tes billets"
      },
      "noEvents": {
        "titleMonth": "Aucun événement ce mois",
        "descriptionMonth": "Aucun événement n'a lieu ce mois. Utilise ce calendrier pour sélectionner un mois différent et parcours l'agenda.",
        "titleDay": "Aucun événement ce jour",
        "descriptionDay": "Aucun événement n'a lieu ce jour. Utilise ce calendrier pour sélectionner un jour différent et parcours l'agenda."
      }
    },
    "loadingMessage": {
      "wait": "Merci d'attendre un instant",
      "contacting": "Nous contactons la boutique",
      "browser": "Ne ferme pas ton navigateur",
      "fetching": "Obtention des billets disponibles",
      "starting": "Démarrage de la boutique",
      "populating": "Chargement de la boutique",
      "opening": "Ouverture de la boutique"
    },
    "wallet-connect": {
      "notification": {
        "title": "Check your eligibility to buy tickets",
        "description": {
          "main": "This ticket shop is only accessible if you hold the required <a target=\"_blank\" href=\"https://support.guts.tickets/hc/en-001/articles/4410619860242-What-is-an-Event-Collectible\">digital collectible(s)</a> in your wallet.",
          "login": "Please login with your mobile number and verify ownership of your wallet that holds the collectible(s), to check your eligibility.",
          "verifyAccess": "Verify that you own one of the required digital collectibles by logging in and connecting your wallet."
        },
        "button": {
          "login": "LOG IN",
          "verify": "VERIFY ACCESS"
        }
      },
      "connect": {
        "title": "Connect your wallet",
        "description": "To access the shop, you need to hold a collectible from one of the following collections:",
        "button": "Connect wallet"
      },
      "searching": {
        "title": "Checking Collectibles in your wallet..."
      },
      "notFound": {
        "title": "Required collectibles not found...",
        "description": "You may try and connect a different wallet to check for your collectibles again",
        "button": "Connect different wallet"
      },
      "sign": {
        "title": "Confirm ownership by signing",
        "description": "We need to verify your ownership of the connected wallet by signing a message through your wallet",
        "button": "Sign via wallet"
      },
      "success": {
        "title": "Access to the shop granted!",
        "description": "We have successfully verified ownership of your wallet and it holds the required collectibles needed to access this ticket-shop",
        "button": "Proceed to Shop"
      },
      "connectedWallet": "Connected wallet",
      "previousWallet": "Previously connected wallet",
      "disconnect": "Disconnect",
      "searched": "Required collection(s)",
      "status": {
        "not-found": "Not found",
        "found": "Found",
        "no-collections": "Error: no collections to check were found."
      }
    }
  },
  "account": {
    "head": {
      "title": "Compte"
    },
    "login": {
      "title": "Vérifie ton numéro de téléphone mobile",
      "description": "Saisis ton numéro de téléphone mobile avec l’indicatif de pays. Tu recevras un code d'accès par SMS.",
      "phonePlaceholder": "Ton numéro de téléphone mobile",
      "focusPhonePlaceholder": "+33123456789",
      "actionVerify": "Vérifier",
      "searchCountryCode": "Cherche l'indicatif de ton pays",
      "selectCountry": "Sélectionne ton pays",
      "mobileInputDescription": "Doit inclure l'indicatif du pays, par exemple +33"
    },
    "callUser": {
      "title": "Tu n'as pas reçu le code ?",
      "description": "Notre robot peut t'appeler et lire ton code de vérification à haute voix.",
      "incorrectNumber": "Mon numéro de téléphone n'est pas correct",
      "action": "Appelez-moi au {phone}",
      "calling": "Tu recevras un appel maintenant"
    },
    "verify": {
      "title": "Vérifie ton téléphone mobile",
      "description": "Un SMS a été envoyé au {number}. Saisis le code à 4 chiffres.",
      "triesLeft": "Ton code n'est pas correct. Tu peux réessayer {times}.",
      "time": "{tries} fois | {tries} fois",
      "requestCodeCountdown": "Le renvoi est disponible dans {countdown} secondes."
    },
    "captcha": {
      "notice": "Cette fonctionnalité est protégée par reCAPTCHA et la {privacy} et les {tos} de Google s'appliquent.",
      "privacy": "Politique de confidentialité",
      "tos": "Conditions d'utilisation",
      "title": "Merci de vérifier que tu es un humain",
      "description": "Coche la case ci-dessous pour prouver tu n'es pas un bot. Nous pouvons parfois demander cela pour prévenir les spammeurs et les escroqueries."
    }
  },
  "profile": {
    "head": {
      "title": "Mon profil"
    },
    "title": "Mon profil",
    "description": "Ces informations peuvent être vérifiées lors d'un événement, donc assure-toi qu'elles sont correctes.",
    "mandatory": "Les champs marqués d'un * sont requis.",
    "additonalInfoTitle": "Informations supplémentaires",
    "additonalInfoDescription": "L'organisateur de l'événement demande les informations supplémentaires suivantes.",
    "terms": "J'accepte les <a href=@:brand.termsUrl target=\"_blank\">conditions générales</a> de @:brand.nameShort <small>(requis)</small>",
    "optIn": "@:brand.nameShort a l'autorisation de partager mes données avec les artistes dont j'achète des billets et m'informer à propos des nouveautés et des événements à venir, de la façon décrite dans <a href=@:brand.privacyPolicyUrl target=\"_blank\">la politique de confidentialité</a>.",
    "firstName": "Prénom *",
    "lastName": "Nom de famille *",
    "email": "Adresse e-mail *",
    "gender": "Genre",
    "male": "Homme",
    "female": "Femme",
    "other": "Autre",
    "birthdate": "Date de naissance",
    "dateProperties": {
      "day": "jour",
      "month": "mois",
      "year": "année"
    },
    "country": "Pays",
    "state": "Département",
    "zipcode": "Code postal",
    "address": "Nom et numéro de rue",
    "city": "Ville",
    "nft_address": {
      "wallet_address": "Wallet address",
      "title": "Définis l'adresse de ton portefeuille NFT",
      "description_off": "Receive event collectibles on your own existing Web3 wallet. By default a Web3 wallet will be created for you.",
      "description_on": "Receive event collectibles on your own Web3 wallet by connecting it once below. Collectibles are distributed on the Polygon blockchain.",
      "description_connected": "Your event collectibles will be sent to the wallet connected below on the Polygon blockchain. Reset to switch to a different wallet.",
      "help": "Aucune idée de ce que cela veut dire ? <a href=\"{url}\" target=\"_blank\">En savoir plus</a>",
      "overview": "Tu recevras tes NFT numériques à collectionner à l'adresse de portefeuille Ethereum / Polygon suivante",
      "reset": "Réinitialiser",
      "edit": "Edit",
      "confirmation": {
        "title": "Es-tu sûr(e) ?",
        "description": "Cela te permettra de définir une adresse de portefeuille différente pour réclamer toute pièce à collectionner future, tu peux aussi laisser ce champ vide pour ne plus les réclamer."
      }
    },
    "actionSave": "Enregistrer"
  },
  "order": {
    "head": {
      "title": "Ta commande"
    },
    "overview": {
      "credits": {
        "applied": "Crédits appliqués",
        "optIn": "Utiliser mes crédits pour régler cette commande",
        "appliedDescription": "Tu as {amount} crédits rattachés à cette commande.",
        "availableDescription": "Il reste {amount} crédits sur ton compte. Ils sont valides pour tous les événements de {org} et expireront le {expirationDate}."
      },
      "organisationName": "l'organisateur",
      "termsOptIn": {
        "url": "J'accepte les <a href=\"{url}\" target=\"_blank\">conditions générales</a> {name} <small>(requis)</small>",
        "custom": "{label} - <a target=\"_blank\" href=\"{url}\">Conditions générales</a> <small>(requis)</small>",
        "text": "<span>J'accepte les conditions générales de {name} <small>(requis)</small></span><p>{text}</p>"
      },
      "privacyOptIn": {
        "url": "Je veux être informé(e) des événements et promotions et accepte la <a href=\"{url}\" target=\"_blank\">déclaration de confidentialité</a> de {name}",
        "custom": "{label} - <a target=\"_blank\" href=\"{url}\">Politique de confidentialité</a>",
        "text": "<span>Je veux être informé(e) des événements et promotions et accepte la déclaration de confidentialité de {name}</span><p>{text}</p>"
      },
      "splitMarketTerms": "J'accepte les <a href=\"{url}\" target=\"_blank\">conditions générales</a> du marché de revente de GUTS Netherlands B.V.",
      "timeLeft": "Tu as maintenant 15 minutes pour terminer ta commande",
      "actionFree": "Confirmer",
      "actionPaid": "Payer"
    },
    "giftcard": {
      "title": "J'ai une carte-cadeau ou un code coupon",
      "description": "Saisis ton code ci-dessous pour échanger",
      "action": "Échanger",
      "placeholder": "Par exemple : '321BDFK874'",
      "label": "Code de carte-cadeau ou de coupon",
      "modal": {
        "label": "Code de carte-cadeau / coupon valide",
        "title": "Valeur",
        "description": "Voici ce qu'il va se passer",
        "description-one": "Ton compte sera crédité de {amount}",
        "description-two-a": "Tu peux utiliser ces crédits sur",
        "description-two-b": "tous les événements de {org}",
        "description-three": "Ils expireront le",
        "action": "Échanger",
        "cancel": "Pas maintenant"
      },
      "error": {
        "empty": "Merci de saisir un code de carte-cadeau ou de coupon valide",
        "invalid": "Ce code n'est pas valide",
        "expired": "Ce code a expiré",
        "used": "Ce code a déjà été utilisé",
        "wrong_organizer": "Ce code ne peut pas être utilisé pour {org}",
        "generic": "Une erreur est survenue, le code ne peut pas être échangé"
      }
    },
    "payment": {
      "ideal": "Choisis ta banque iDeal",
      "other": "Choisis ton moyen de paiement",
      "noPaymentOptions": "Aucun moyen de paiement n'est disponible actuellement. Essaie d'actualiser cette page. Si le problème persiste, <a href=\"@:brand.supportUrl\" target=\"_blank\">Merci de nous contacter</a>."
    },
    "paymentLoader": {
      "redirecting": "Redirection vers {method}...",
      "error": "{method} semble avoir un problème.\nRéessaie ou choisis un autre moyen de paiement."
    },
    "status": {
      "pending": {
        "title": "Ta commande est en cours de traitement..."
      },
      "paid": {
        "title": "Commande réussie !",
        "description": "Une confirmation de commande a été envoyée à {email} - si tu ne l'as pas reçue, assure-toi de consulter ton courrier indésirable. ",
        "descriptionAnonymous": "An order confirmation has been sent to you - if it's not there, be sure to check your spam. ",
        "actionTickets": "Accéder aux billets en ligne"
      },
      "timeout": {
        "title": "Un instant...",
        "description": "Malheureusement, nous n'avons pas encore pu confirmer ton paiement. Dès que ce sera fait, tu recevras une confirmation à l'adresse {email}."
      },
      "error": {
        "title": "Échec de la commande...",
        "description": "Une erreur s'est produite lors de la finalisation de ta commande. Merci de réessayer.",
        "actionBack": "Retour à l'événement"
      }
    },
    "app": {
      "title": "Les billets de @:brand.nameShort fonctionnent sur ton smartphone, alors installe l'appli !",
      "description": "Avantages de l'appli :",
      "imageAlt": "Appli de portefeuille @:brand.name pout iPhone et Android",
      "featureList": [
        "Les billets numériques dans l'application te permettent d'accéder à l'événement.",
        "Vends facilement tes billets si tu ne peux pas te rendre à l'événement.",
        "Donne tes billets ou partage-les avec tes amis."
      ]
    }
  },
  "tickets": {
    "index": {
      "head": {
        "title": "Mes événements"
      },
      "title": "Mes événements à venir",
      "noEventsTitle": "Aucun événement à venir",
      "noEventsDescription": "Tu n'as aucun billet pour des événements à venir."
    },
    "details": {
      "head": {
        "title": "Billets"
      },
      "actionBack": "Mes événements",
      "inactive": {
        "title": "Ton code QR est verrouillé en toute sécurité",
        "description": "Il apparaîtra automatiquement le {unlockDate}."
      },
      "active": {
        "title": "Fais scanner ce code QR lors de l'événement",
        "description": "Ce code fonctionne pour tous tes billets et amis."
      },
      "notScannable": {
        "title": "Aucun billet à enregistrer",
        "description": "Tous tes billets sont soit enregistrés, vendus ou invalidés (remboursés en crédits)."
      },
      "noCopies": "Les copies ou impressions ne fonctionneront pas",
      "seating": {
        "seatsAppear": "Les numéros de sièges sont attribués la veille de l'événement.",
        "entrance": "Entrée",
        "section": "Section",
        "row": "Rangée",
        "seats": "Siège"
      },
      "group": {
        "info": "Groupe de {activeUsers}/{groupSize}",
        "leave": "Quitter",
        "confirmLeave": "Veux-tu vraiment quitter ce groupe ?"
      },
      "livestream": {
        "watchStream": "Regarder maintenant",
        "upsell": {
          "shopLink": "Aller à la boutique",
          "warning": "Remarque : tu n'as pas acheté de billets",
          "description": "Tu n'as acheté que des suppléments : si tu avais l'intention d'acheter des billets, tu peux toujours le faire."
        },
        "inactive": {
          "title": "Accès à la diffusion",
          "description": "La diffusion sera accessible ici le {unlockDate}."
        },
        "unauthorized": {
          "title": "Aucun accès à la diffusion",
          "description": "Tu n'as pas acheté l'accès à cette diffusion"
        },
        "active": {
          "title": "La diffusion a commencé !",
          "description": "Tu as un accès exclusif à cette diffusion."
        }
      },
      "footer": {
        "title": "Tu veux partager ou vendre tes billets ?",
        "description": "Installe l'appli mobile pour faire plus."
      }
    }
  },
  "invite": {
    "title": "Invitation de",
    "shareTickets": "{ticketAmount} billet partagé avec tout le monde | {ticketAmount} billets partagés avec tout le monde",
    "group": "{personAmount} personne déjà dans le groupe | {personAmount} personnes déjà dans le groupe",
    "confirmAccept": "Veux-tu vraiment rejoindre ce groupe ?",
    "confirmDecline": "Veux-tu vraiment ne pas rejoindre ce groupe ?",
    "warning": "<strong>N'accepte que si tu connais {inviterName} et lui fais confiance !</strong> Tout le monde dans ce groupe a exactement les mêmes droits, y compris celui d'inviter d'autres personnes et de vendre tous les billets pour cet événement, y compris le tien !",
    "invite": "Invitation de",
    "accept": "accepter",
    "decline": "refuser"
  },
  "pendingPayment": {
    "title": "Avertissement : Paiement en attente",
    "pay": "Payer",
    "wait": "Attendre",
    "existingPayment": "Tu as déjà initié un paiement pour cette commande.",
    "confirmationEmail": "Si tu as terminé ce paiement, tu recevras un e-mail de confirmation bientôt.",
    "confirm": "Veux-tu initier un nouveau paiement ou attendre ?"
  },
  "reservationInvitation": {
    "claimAction": "Claim tickets",
    "status": {
      "title": "Great, the tickets are yours!"
    }
  }
}
