import { App } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import status from '@/router/routes/gatekeeper/shop/order/status';
import overview from '@/router/routes/gatekeeper/shop/order/overview';
import { OrderState } from '@/constants';
import { Order as OrderType } from '@/store/modules/order/types';
import { Store } from '@/store/types';

const Order = () => import('@/views/shop/Order.vue');

export default (app: App, store: Store) =>
  ({
    path: ':shopType/orders/:id',
    component: Order,
    children: [status(app, store), overview(app, store)],
    beforeEnter: async (to, _, next) => {
      let order: OrderType;
      const { commit, dispatch } = store;

      if (to.params.shopType !== 'resale' && to.params.shopType !== 'shop') {
        next({ name: to.name!, params: { ...to.params, shopType: 'shop' } });
        return;
      }

      try {
        const response = await dispatch('order/getOrder', parseInt(to.params.id as string, 10));
        if (!response.data) throw Error('Unknown: no order data');
        order = response.data;
      } catch (e: any) {
        if (e?.response?.status === 404) {
          next({ name: 'shop', params: { ...to.params }, query: to.query });
          commit('shop/set_local_error', { show: true, key: 'orderNotFound' });
          return;
        }
        commit('set_global_error', { show: true });
        throw e;
      }

      if (order.state === OrderState.FAILED || order.state === OrderState.CANCELED || !order.details.items) {
        next({ name: 'shop', params: { ...to.params }, query: to.query });
        commit('shop/set_local_error', { show: true, key: 'invalid_order_state' });
        return;
      }

      commit('order/set_order', { ...order });
      next();
    },
  } as RouteRecordRaw);
