import helpers from '@/helpers';

export default class PostMessageRequest {
  _self: Window;

  _target: Window;

  _targetOrigin: string;

  _data: { request: string };

  _validResponse: (response: string) => boolean;

  _receivedResponse: string | null;

  _interval: number | ReturnType<typeof setTimeout> | null;

  _timeout: number | ReturnType<typeof setTimeout> | null;

  constructor(
    self: Window,
    target: Window,
    targetOrigin: string,
    data: { request: string },
    validResponse: (response: string) => boolean,
  ) {
    this._self = self;
    this._target = target;
    this._targetOrigin = targetOrigin;
    this._data = data;
    this._validResponse = validResponse;
    this._receivedResponse = null;
    this._interval = null;
    this._timeout = null;
  }

  _initMessageListener(): void {
    this._self.addEventListener('message', this._messageHandle.bind(this), false);
  }

  _destroyMessageListener(): void {
    this._self.removeEventListener('message', this._messageHandle, false);
  }

  _clearTimers(): void {
    if (this._interval) {
      // TODO: typescript seems buggy, it requires a timeout to be sent but it should actually be a number
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      clearInterval(this._interval);
      this._interval = null;
    }
    if (this._timeout) {
      // TODO: typescript seems buggy, it requires a timeout to be sent but it should actually be a number
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      clearTimeout(this._timeout);
      this._timeout = null;
    }
  }

  _postRequest(): void {
    this._target.postMessage(this._data, this._targetOrigin);
    setTimeout(() => {
      this._target.postMessage(this._data, this._targetOrigin);
    }, 500);
  }

  _awaitResponse(): Promise<boolean> {
    return new Promise((resolve) => {
      this._interval = setInterval(() => {
        if (this._receivedResponse) {
          resolve(true);
        }
      }, 100);
      this._timeout = setTimeout(() => {
        resolve(false);
      }, 2000);
    });
  }

  _messageHandle({ data, origin }: MessageEvent<{ response: string }>): void {
    if (
      origin === this._targetOrigin &&
      this._validResponse(data.response) &&
      !helpers.isMaliciousString(data.response)
    ) {
      this._receivedResponse = data.response;
    }
  }

  async request(): Promise<{
    success: boolean;
    data: string | null;
  }> {
    this._initMessageListener();
    this._postRequest();
    const res = await this._awaitResponse();
    this._clearTimers();
    this._destroyMessageListener();
    return {
      success: res,
      data: this._receivedResponse,
    };
  }
}
