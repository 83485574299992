import { UserEventDetails } from '@getprotocollab/get-pal';
import {
  cartDifference,
  CartTicket,
  Organization,
  ResaleCartTicket,
  ResaleItem,
  ResaleShop,
  ShopStoreable as S,
  ShopTK,
  TicketShop,
  UserShopStats,
  UserTKStats,
} from '@/store/modules/shop/types';
import { ErrorMessage } from '@/store/types/types';

const mutations = {
  set_event(state: S, event: UserEventDetails) {
    state.event = { ...event };
  },
  clear_event(state: S) {
    state.event = null;
  },
  set_shop(state: S, shop: TicketShop) {
    state.shop = { ...shop };
  },
  set_effective_shop_limit(state: S, limit: number) {
    state.effectiveShopLimit = limit;
  },
  set_ticket_kinds(state: S, ticketKinds: ShopTK[]) {
    state.ticketKinds = [...ticketKinds];
  },
  set_shop_user_tk_stats(state: S, stats: UserTKStats[]) {
    state.shopUserTKStats = [...stats];
  },
  set_user_shop_stats(state: S, userShopStats: UserShopStats | null) {
    const stats = userShopStats ? { ...userShopStats } : null;
    state.userShopStats = stats;
  },
  set_resale_shop(state: S, resaleShop: ResaleShop) {
    state.resaleShop = {
      ...resaleShop,
      ticket_kinds: resaleShop.ticket_kinds.map((tk) => {
        const amountInCart = state.resaleCart.tickets.reduce((acc, item) => {
          if (item.kind === tk.id) return acc + item.amount;
          return acc;
        }, 0);
        return { ...tk, available: tk.available + amountInCart };
      }),
    };
  },
  set_organization(state: S, org: Organization) {
    state.organization = { ...org };
  },
  set_resellers_by_kind(state: S, { resellers, kindId }: { resellers: ResaleItem[]; kindId: number }) {
    state.resellersByKind = { ...state.resellersByKind, [kindId]: resellers };
  },
  add_resellers_by_kind(state: S, { resellers: newResellers }: { resellers: ResaleItem[] }) {
    const sellersByKind = { ...state.resellersByKind };
    newResellers.forEach((newSeller) => {
      sellersByKind[newSeller.kind_id] = sellersByKind[newSeller.kind_id] || [];

      const index = sellersByKind[newSeller.kind_id].findIndex(
        (item) => item.user_id === newSeller.user_id && parseFloat(item.price) === parseFloat(newSeller.price),
      );

      if (index !== -1) {
        // overwrite stale data
        sellersByKind[newSeller.kind_id].splice(index, 1, newSeller);
      } else {
        sellersByKind[newSeller.kind_id].push(newSeller);
      }
    });

    state.resellersByKind = sellersByKind;
  },
  set_reseller_resale_items(state: S, items: ResaleItem[]) {
    state.resellerResaleItems = items;
  },
  update_resellers_availability(state: S, diff: cartDifference[]) {
    const sellersByKind = { ...state.resellersByKind };
    diff.forEach(({ kind, price, seller, available }) => {
      const resellers = sellersByKind[kind] || [];
      const found = resellers.find((reseller) => reseller.user_id === seller && reseller.price === price);
      if (found) found.available = available;
    });

    state.resellersByKind = sellersByKind;
  },
  update_reseller_resale_items_availability(state: S, diff: cartDifference[]) {
    const resaleItems = [...state.resellerResaleItems];

    diff.forEach(({ kind, price, seller, available }) => {
      const found = resaleItems.find(
        (item) => item.user_id === seller && item.price === price && item.kind_id === kind,
      );
      if (found) found.available = available;
    });

    state.resellerResaleItems = resaleItems;
  },
  set_cart_tickets(state: S, tickets: CartTicket[]) {
    state.cart.tickets = tickets.map((t) => t);
  },
  set_resale_cart_tickets(state: S, tickets: ResaleCartTicket[]) {
    state.resaleCart.tickets = tickets.map((t) => t);
  },
  init_cart(state: S, uuid: string) {
    state.cart.uuid = uuid;
    state.cart.tickets = [];
  },
  init_resale_cart(state: S, uuid: string) {
    state.resaleCart.uuid = uuid;
    state.resaleCart.tickets = [];
  },
  set_loading_cart(state: S, loading: boolean) {
    state.cart.loading = loading;
  },
  set_loading_resale_cart(state: S, loading: boolean) {
    state.resaleCart.loading = loading;
  },
  set_local_error(state: S, error: ErrorMessage) {
    state.error = { ...error };
  },
};

export default mutations;
