import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';

export const isValidPhoneNumber = (value: string): boolean => {
  const number = parsePhoneNumberFromString(value);
  if (number) {
    return number.isValid();
  }
  return false;
};

export const isAcceptableBirthdate = (value: string): boolean => {
  if (!value) return true;
  const after = moment('1900-01-01').format();
  const before = moment().format();
  return moment(value).isBetween(after, before);
};

export const isValidWalletAddress = (value: string): boolean | string => {
  const regex = /0x[0-9a-fA-F]{40}/;
  const match = regex.exec(value);

  if (match) {
    return match[0];
  }
  return false;
};
