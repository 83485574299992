import { Module } from 'vuex';
import { ShopStoreable } from '@/store/modules/shop/types';
import { RootStoreable } from '@/store/types/models';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state: ShopStoreable = {
  event: null,
  shop: null,
  effectiveShopLimit: 0,
  ticketKinds: [],
  shopUserTKStats: [],
  userShopStats: null,
  resaleShop: null,
  resellersByKind: {},
  resellerResaleItems: [],
  organization: null,
  loading: true,
  cart: {
    uuid: null,
    tickets: [],
    loading: false,
  },
  resaleCart: {
    uuid: null,
    tickets: [],
    loading: false,
  },
  error: {
    show: false,
    key: '',
  },
};

function shopModule(app): Module<ShopStoreable, RootStoreable> {
  return {
    namespaced: true,
    state() {
      return state;
    },
    actions: actions(app),
    mutations,
    getters,
  };
}

export default shopModule;
