import { App } from 'vue';
import Invite from '@/views/invite/index.vue';
import { Store } from '@/store/types';

export default (app: App, store: Store) => ({
  path: '/invite/:inviteCode',
  name: 'invite',
  component: Invite,
  meta: {
    auth: true,
    showNavbar: true,
    permissions: {
      access: ({ user }) => !!user,
      redirect: (state, to) => ({
        name: 'account',
        query: { next: to.fullPath },
      }),
    },
  },
});
