import { App } from 'vue';
import { CommitOptions, DispatchOptions, ActionContext } from 'vuex';
import { fetchUserUpcomingEventList, request, fetchAllPages, parseRequestQueryParams } from '@getprotocollab/get-pal';
import { RootStoreable as R } from '@/store/types/models';
import { ticketApiConfig } from '@/api';
import { Ticket, TicketGroup, TicketStoreable as S } from './types';
import mutations from './mutations';

type _actions = ReturnType<typeof actions>;
type _mutations = typeof mutations;
type _ctx = {
  commit<K extends keyof _mutations, P extends Parameters<_mutations[K]>[1]>(
    ...args: P extends undefined
      ? [key: K, payload?: P, options?: CommitOptions]
      : [key: K, payload: P, options?: CommitOptions]
  ): ReturnType<_mutations[K]>;
  dispatch<K extends keyof _actions, P extends Parameters<_actions[K]>[1]>(
    ...args: P extends undefined
      ? [key: K, payload?: P, options?: DispatchOptions]
      : [key: K, payload: P, options?: DispatchOptions]
  ): ReturnType<_actions[K]>;
} & Omit<ActionContext<S, R>, 'commit' | 'dispatch'>;

function actions(_: App) {
  return {
    async getUserEvents({ commit }: _ctx) {
      const response = await fetchAllPages(fetchUserUpcomingEventList, ticketApiConfig);
      const events = response.data!.results;
      commit('set_user_events', events);
    },

    async getTickets({ commit }: _ctx, slug: string) {
      const params = parseRequestQueryParams({
        event_slug: slug,
      });

      const response = await fetchAllPages<Ticket>(request, {
        ...ticketApiConfig,
        resource: `tickets/${params}`,
      });
      const tickets = response.data?.results;
      commit('set_event_tickets', tickets || []);
    },

    async initGroup({ commit }: _ctx, groupId: number) {
      const response = await request<TicketGroup, never>({
        ...ticketApiConfig,
        resource: `ticketgroups/${groupId}/`,
      });

      commit('set_group', response.data!);
    },
    async leaveGroup({ commit }: _ctx, groupId: number) {
      await request<any, never>({
        ...ticketApiConfig,
        method: 'delete',
        resource: `ticketgroups/${groupId}/`,
      });
      commit('clear_group');
    },
  };
}

export default actions;
