import { ListResult, request, parseRequestQueryParams } from '@getprotocollab/get-pal';
import { ActionContext, DispatchOptions, CommitOptions } from 'vuex';
import { ticketApiConfig } from '@/api';
import { RootStoreable } from '@/store/types/models';
import { Order, OrderStoreable, Payment, Voucher, Credit } from './types';
import mutations from './mutations';

type _mutations = typeof mutations;
type _actions = ReturnType<typeof actions>;
type _ctx = {
  commit<K extends keyof _mutations, P extends Parameters<_mutations[K]>[1]>(
    ...args: P extends undefined
      ? [key: K, payload?: P, options?: CommitOptions]
      : [key: K, payload: P, options?: CommitOptions]
  ): ReturnType<_mutations[K]>;
  dispatch<K extends keyof _actions, P extends Parameters<_actions[K]>[1]>(
    ...args: P extends undefined
      ? [key: K, payload?: P, options?: DispatchOptions]
      : [key: K, payload: P, options?: DispatchOptions]
  ): ReturnType<_actions[K]>;
} & Omit<ActionContext<OrderStoreable, RootStoreable>, 'commit' | 'dispatch'>;
type OrganiserOptIn = {
  terms_and_conditions_opt_in: boolean;
  privacy_opt_in: boolean;
};

function actions(app) {
  return {
    saveOrganiserOptIns(
      _: _ctx,
      { termsOptIn, privacyOptIn, eventId }: { termsOptIn: boolean; privacyOptIn: boolean; eventId: number },
    ) {
      const payload: OrganiserOptIn = {
        terms_and_conditions_opt_in: termsOptIn,
        privacy_opt_in: privacyOptIn,
      };
      return request<OrganiserOptIn, OrganiserOptIn>({
        ...ticketApiConfig,
        method: 'put',
        resource: `events/${eventId}/preferences/`,
        data: payload,
      });
    },
    getOrganiserOptIns(_: _ctx, eventId: number) {
      return request<OrganiserOptIn, never>({ ...ticketApiConfig, resource: `events/${eventId}/preferences/` });
    },
    async getOrder({ commit }: _ctx, id: number) {
      const response = await request<Order, never>({ ...ticketApiConfig, resource: `orders/${id}/` });
      if (response.data) commit('set_order', response.data);
      return response;
    },
    async getPayment({ commit }: _ctx, id: number) {
      const response = await request<Payment, never>({ ...ticketApiConfig, resource: `psp/payment/${id}/` });
      if (response.data) commit('set_payment', response.data);
      return response;
    },
    getVoucherInfo(_: _ctx, code: string) {
      return request<Voucher, never>({ ...ticketApiConfig, resource: `credits/coupons/${code}` });
    },
    async redeemVoucher(_: _ctx, code: string) {
      return request<Voucher, never>({
        ...ticketApiConfig,
        method: 'post',
        resource: `credits/coupons/${code}/redeem`,
      });
    },
    async getCredits({ commit }: _ctx, orderId: number) {
      const query = parseRequestQueryParams({
        valid_for_order: orderId,
      });
      const response = await request<ListResult<Credit>, never>({
        ...ticketApiConfig,
        resource: `credits/${query}`,
      });

      commit('set_credits', response.data?.results || []);
      return response;
    },
    applyCredits(_: _ctx, { amount, orderId }: { amount: string; orderId: number }) {
      return request<any, { amount: string }>({
        ...ticketApiConfig,
        method: 'post',
        resource: `orders/${orderId}/apply_credit/`,
        data: { amount },
      });
    },
  };
}

export default actions;
