import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { i18n } from '@/lang';
import helpers from '@/helpers';

export function formatCurrency(value, { code, decimals, symbol = true }) {
  const options = {
    style: symbol ? 'currency' : 'decimal',
    currency: code,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  };
  let currency;

  try {
    currency = new Intl.NumberFormat(i18n.locale, options).format(value);
  } catch (e) {
    // fallback when Intl.NumberFormat not supported
    currency = `${code} ${Number(value).toFixed(decimals).replace(/[.]/, ',')}`;
  }

  return currency;
}

export function formatDate(value, format, zone) {
  if (zone) {
    return moment.tz(value, zone).format(format);
  }
  return moment(value).format(format);
}

export function formatLocaleMultiDate(value, zone) {
  return moment.tz(value, zone).format('ll');
}

export function formatLocaleDate(value, zone) {
  const momentDate = moment.tz(value, zone);
  return `${helpers.capitalize(momentDate.format('dddd'))} ${momentDate.format('LL')}`;
}
