export enum GateState {
  PAUSED = 'paused',
  RUNNING = 'running',
  SOLDOUT = 'soldout',
  PRE_QUEUE = 'pre-queue',
}
export enum GateMode {
  OPEN = 'open',
  ACCESS = 'access',
  PRE_QUEUE = 'prequeue',
}
export enum GateQueueState {
  AUTO = 'auto',
  THROTTLE = 'throttle',
  PENDING = 'pending',
  PENDING_AUTO = 'pending_auto',
}
export enum ShopType {
  RESALE = 'resale',
  SHOP = 'shop',
}
export enum LineState {
  WAITING = 'waiting',
  TURN = 'turn',
  PAUSED = 'paused',
  SOLDOUT = 'soldout',
}
export enum ShopState {
  SOLDOUT = 'soldout',
  AVAILABLE = 'available',
  PAST = 'past',
}
export enum ApiState {
  ERROR = 'error',
  SUCCESS = 'success',
}
export enum OrderState {
  PENDING = 'pending',
  COMPLETED = 'completed',
  FAILED = 'failed',
  TIMEOUT = 'timeout',
  CANCELED = 'canceled',
  INVALID = 'invalid_order_state',
}
export enum PaymentStatuses {
  PAID = 'paid',
  CANCELED = 'canceled',
  FAILED = 'failed',
  EXPIRED = 'expired',
  PENDING = 'pending',
  PENDING_TIMEOUT = 'pending-timeout',
  ERROR = 'error',
}
export enum TicketStates {
  SOLD = 'sold',
  FOR_SALE = 'forsale',
  EXECUTED = 'executed',
  RETURNED = 'returned',
  RESOLD = 'resold',
}
export const RESIZE_PREFIX = '/_/';
export const IMAGE_SIZES = [1, 42, 320, 640, 800, 1280, 1920];
export const USER_STATUS_INTERVAL = 16000;
export const ACTIVATION_INTERVAL = 8000;

export const DEFAULT_COUNTRY = 'NL';
export const VUESCERA_URL = import.meta.env.VITE_APP_VUESCERA_URL || 'http://localhost:9100';
export const VUEJONES_URL = import.meta.env.VITE_APP_VUEJONES || 'http://localhost:8080';

// VITE_APP_VUESCERA_URL necessary on top of BASE_PATH to breakout of widget to main domain.
