import { ErrorMessage } from '@/store/types/types';
import { Credit, Order, OrderStoreable as S, Payment, PspMethods } from './types';

const mutations = {
  store_psp(state: S, methods: PspMethods | null) {
    state.psp_methods = methods;
  },
  set_payment(state: S, payment: Payment) {
    state.payment = { ...payment };
  },
  set_order(state: S, order: Order) {
    state.order = { ...order };
  },
  set_credits(state: S, credits: Credit[]) {
    state.credits = credits;
  },
  set_payment_error(state: S, error: ErrorMessage) {
    state.payment_error = { ...error };
  },
};

export default mutations;
