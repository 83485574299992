import { App } from 'vue';
import details from '@/router/routes/preview/shop/details';
import checkout from '@/router/routes/preview/shop/checkout';
import order from '@/router/routes/preview/shop/order';
import { Store } from '@/store/types';

const Shop = () => import('@/views/Shop.vue');

export default (app: App, store: Store) => ({
  path: '/preview/:slug/:shopSlug',
  name: 'preview',
  component: Shop,
  children: [details(app, store), checkout(app, store), order(app, store)],
});
