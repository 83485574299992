import 'url-polyfill';
import { createHead } from '@vueuse/head';
import VueClickAway from 'vue3-click-away';

import { createApp, h } from 'vue';
import GtmManagerPlugin from '@/plugins/GtmManager';
import PermanentQueryParams from '@/plugins/PermanentQueryParams';

import momentPlugin from '@/plugins/vue-moment';
import localeHelpers from '@/helpers/locale';
import sentryHelpers from '@/helpers/sentry';

import routeManager from '@/plugins/route-manager';
import progressBar from '@/plugins/progress-bar';
import sharedStorage, { MemoryStorage } from '@/plugins/SharedStorage';

import { i18n, supportedLocales } from './lang';
import initRouter from './router';
import initStore, { key } from './store';
import App from './App.vue';

const app = createApp({
  created() {
    // on initial load we set the locale in the following order:
    // localstorage -> querystring -> browserLang -> i18n.fallbackLocale
    // this can later be overwritten when the user profile is loaded
    // all changes in the profile ar also set on localstorage so only the first load
    // on a new browser (for this user) might have the wrong locale
    const browserLanguages = localeHelpers.getBrowserLanguages();
    const supportedBrowserLang = localeHelpers.getPreferredSupportedLang(
      browserLanguages,
      this.$i18n.fallbackLocale,
      supportedLocales,
    );

    const queryLocale = localeHelpers.getQueryLocale(window.location.href);
    const storageLocale = this.$storage.getItem('locale');

    const locale = localeHelpers.setLocale(this.$i18n, storageLocale || queryLocale || supportedBrowserLang);

    this.$storage.setItem('locale', locale);
  },
  render: () => h(App),
});

const store = initStore(app);
app.config.globalProperties.$vuex = store;
app.config.globalProperties.store = store;
app.config.globalProperties.i18n = i18n.global;

const router = initRouter(app, store);
const head = createHead();

app
  .use(i18n)
  .use(head)
  .use(store, key)
  .use(router)
  .use(VueClickAway)
  .use(progressBar)
  .use(momentPlugin)
  .use(routeManager, { router, store })
  .use(sharedStorage, {
    storageFactories: [() => localStorage, () => sessionStorage, () => new MemoryStorage()],
  })
  .use(GtmManagerPlugin, {
    router,
    store,
    options: {
      debug: import.meta.env.NODE_ENV === 'development',
    },
  })
  .use(PermanentQueryParams, { query: window.location.search, router });

//
// Error handling:
//
if (import.meta.env.VITE_APP_SENTRY_DSN) {
  try {
    sentryHelpers.initSentry(app, {
      dsn: import.meta.env.VITE_APP_SENTRY_DSN,
      environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
      release: import.meta.env.VITE_APP_SENTRY_RELEASE,
    });
  } catch (e) {
    // failing to initialize sentry shouldn't kill the application
    console.error(e);
  }
}

// Sentry doesn't expose the actual errorHandler so we extend the existing one
const originalErrorHandler = app.config.errorHandler;

app.config.errorHandler = (error, vm, info) => {
  // When an error occures that we do not handle we show the global error that
  // wil ask a user to restart.
  // The vue integration will send the error to sentry and log it in the console
  store.commit('set_global_error', { show: true });

  if (originalErrorHandler) originalErrorHandler(error, vm, info);
  console.error(error);
};

//
// Start:
//
const vm = app.mount('#app');

export default app;
export { vm };
