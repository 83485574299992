const List = () => import('@/views/tickets/List.vue');

export default (app, store) => ({
  path: '',
  name: 'tickets',
  component: List,
  meta: {
    auth: true,
    showNavbar: true,
    permissions: {
      access: ({ user }) => !!user,
      redirect: (state, to) => ({
        name: 'account',
        query: { next: to.fullPath },
      }),
    },
  },
  beforeEnter: async (to, from, next) => {
    const { commit, dispatch } = store;

    try {
      await dispatch('tickets/getUserEvents');
    } catch (e) {
      next();
      throw e;
    }

    commit('set_layout_cover', { cover: null });
    next();
  },
});
