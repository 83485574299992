{
  "selectCountry": "Seleziona paese",
  "AF": "Afghanistan",
  "AX": "Isole Åland",
  "AL": "Albania",
  "DZ": "Algeria",
  "AS": "Samoa americane",
  "AD": "Andorra",
  "AO": "Angola",
  "AI": "Anguilla",
  "AQ": "Antartide",
  "AG": "Antigua e Barbuda",
  "AR": "Argentina",
  "AM": "Armenia",
  "AW": "Aruba",
  "AU": "Australia",
  "AT": "Austria",
  "AZ": "Azerbaigian",
  "BS": "Bahamas",
  "BH": "Bahrein",
  "BD": "Bangladesh",
  "BB": "Barbados",
  "BY": "Bielorussia",
  "BE": "Belgio",
  "BZ": "Belize",
  "BJ": "Benin",
  "BM": "Bermuda",
  "BT": "Bhutan",
  "BO": "Bolivia (stato plurinazionale della)",
  "BQ": "Bonaire, Sint Eustatius e Saba",
  "BA": "Bosnia-Erzegovina",
  "BW": "Botswana",
  "BV": "Isola Bouvet",
  "BR": "Brasile",
  "IO": "Territorio britannico dell'Oceano Indiano",
  "BN": "Brunei Darussalam",
  "BG": "Bulgaria",
  "BF": "Burkina Faso",
  "BI": "Burundi",
  "CV": "Cabo Verde",
  "KH": "Cambogia",
  "CM": "Camerun",
  "CA": "Canada",
  "KY": "Isole Cayman",
  "CF": "Repubblica centrafricana",
  "TD": "Ciad",
  "CL": "Cile",
  "CN": "Cina",
  "CX": "Isola di Natale",
  "CC": "Isole Cocos (Keeling)",
  "CO": "Colombia",
  "KM": "Comore",
  "CG": "Congo",
  "CD": "Congo (Repubblica democratica del)",
  "CK": "Isole Cook",
  "CR": "Costa Rica",
  "CI": "Costa d'Avorio",
  "HR": "Croazia",
  "CU": "Cuba",
  "CW": "Curaçao",
  "CY": "Cipro",
  "CZ": "Czechia",
  "DK": "Danimarca",
  "DJ": "Gibuti",
  "DM": "Dominica",
  "DO": "Repubblica Dominicana",
  "EC": "Ecuador",
  "EG": "Egitto",
  "SV": "El Salvador",
  "GQ": "Guinea equatoriale",
  "ER": "Eritrea",
  "EE": "Estonia",
  "ET": "Etiopia",
  "FK": "Isole Falkland (Malvinas)",
  "FO": "Isole Faroe",
  "FJ": "Figi",
  "FI": "Finlandia",
  "FR": "Francia",
  "GF": "Guyana francese",
  "PF": "Polinesia francese",
  "TF": "Territori australi francesi",
  "GA": "Gabon",
  "GM": "Gambia",
  "GE": "Georgia",
  "DE": "Germania",
  "GH": "Ghana",
  "GI": "Gibilterra",
  "GR": "Grecia",
  "GL": "Groenlandia",
  "GD": "Grenada",
  "GP": "Guadalupa",
  "GU": "Guam",
  "GT": "Guatemala",
  "GG": "Guernsey",
  "GN": "Guinea",
  "GW": "Guinea-Bissau",
  "GY": "Guyana",
  "HT": "Haiti",
  "HM": "Isole Heard e McDonald",
  "VA": "Santa Sede",
  "HN": "Honduras",
  "HK": "Hong Kong",
  "HU": "Ungheria",
  "IS": "Islanda",
  "IN": "India",
  "ID": "Indonesia",
  "IR": "Iran (Repubblica islamica di)",
  "IQ": "Iraq",
  "IE": "Irlanda",
  "IM": "Isola di Man",
  "IL": "Israele",
  "IT": "Italia",
  "JM": "Jamaica",
  "JP": "Giappone",
  "JE": "Jersey",
  "JO": "Jordan",
  "KZ": "Kazakistan",
  "KE": "Kenya",
  "KI": "Kiribati",
  "KP": "Corea (Repubblica Popolare Democratica di)",
  "KR": "Corea (Repubblica di)",
  "KW": "Kuwait",
  "KG": "Kirghizistan",
  "LA": "Repubblica democratica popolare del Laos",
  "LV": "Lettonia",
  "LB": "Libano",
  "LS": "Lesotho",
  "LR": "Liberia",
  "LY": "Libia",
  "LI": "Liechtenstein",
  "LT": "Lituania",
  "LU": "Lussemburgo",
  "MO": "Macao",
  "MK": "Macedonia (ex Repubblica iugoslava di)",
  "MG": "Madagascar",
  "MW": "Malawi",
  "MY": "Malaysia",
  "MV": "Maldive",
  "ML": "Mali",
  "MT": "Malta",
  "MH": "Isole Marshall",
  "MQ": "Martinica",
  "MR": "Mauritania",
  "MU": "Mauritius",
  "YT": "Mayotte",
  "MX": "Messico",
  "FM": "Micronesia (Stati federati di)",
  "MD": "Moldavia (Repubblica di)",
  "MC": "Monaco",
  "MN": "Mongolia",
  "ME": "Montenegro",
  "MS": "Montserrat",
  "MA": "Marocco",
  "MZ": "Mozambico",
  "MM": "Myanmar",
  "NA": "Namibia",
  "NR": "Nauru",
  "NP": "Nepal",
  "NL": "Paesi Bassi",
  "NC": "Nuova Caledonia",
  "NZ": "Nuova Zelanda",
  "NI": "Nicaragua",
  "NE": "Niger",
  "NG": "Nigeria",
  "NU": "Niue",
  "NF": "Norfolk Island",
  "MP": "Isole Marianne settentrionali",
  "NO": "Norvegia",
  "OM": "Oman",
  "PK": "Pakistan",
  "PW": "Palau",
  "PS": "Palestina (Stato di)",
  "PA": "Panama",
  "PG": "Papua Nuova Guinea",
  "PY": "Paraguay",
  "PE": "Perù",
  "PH": "Filippine",
  "PN": "Pitcairn",
  "PL": "Polonia",
  "PT": "Portogallo",
  "PR": "Puerto Rico",
  "QA": "Qatar",
  "RE": "Riunione (isola)",
  "RO": "Romania",
  "RU": "Federazione russa",
  "RW": "Ruanda",
  "BL": "Saint Barthélemy",
  "SH": "Sant'Elena, Ascensione e Tristan da Cunha",
  "KN": "Saint Kitts e Nevis",
  "LC": "Santa Lucia",
  "MF": "Saint Martin (parte francese)",
  "PM": "Saint Pierre e Miquelon",
  "VC": "Saint Vincent e Grenadine",
  "WS": "Samoa",
  "SM": "San Marino",
  "ST": "Sao Tome e Principe",
  "SA": "Arabia Saudita",
  "SN": "Senegal",
  "RS": "Serbia",
  "SC": "Seychelles",
  "SL": "Sierra Leone",
  "SG": "Singapore",
  "SX": "Sint Maarten (parte olandese)",
  "SK": "Slovacchia",
  "SI": "Slovenia",
  "SB": "Isole Salomone",
  "SO": "Somalia",
  "ZA": "Sudafrica",
  "GS": "Georgia del Sud e isole Sandwich meridionali",
  "SS": "Sudan del Sud",
  "ES": "Spagna",
  "LK": "Sri Lanka",
  "SD": "Sudan",
  "SR": "Suriname",
  "SJ": "Svalbard e Jan Mayen",
  "SZ": "Swaziland",
  "SE": "Svezia",
  "CH": "Svizzera",
  "SY": "Repubblica araba siriana",
  "TW": "Taiwan, provincia della Cina",
  "TJ": "Tagikistan",
  "TZ": "Tanzania, Repubblica unita di",
  "TH": "Thailandia",
  "TL": "Timor Est",
  "TG": "Togo",
  "TK": "Tokelau",
  "TO": "Tonga",
  "TT": "Trinidad e Tobago",
  "TN": "Tunisia",
  "TR": "Turchia",
  "TM": "Turkmenistan",
  "TC": "Isole Turks e Caicos",
  "TV": "Tuvalu",
  "UG": "Uganda",
  "UA": "Ucraina",
  "AE": "Emirati Arabi Uniti",
  "GB": "Regno Unito di Gran Bretagna e Irlanda del nord",
  "US": "Stati Uniti d\"America",
  "UM": "Isole periferiche minori degli Stati Uniti",
  "UY": "Uruguay",
  "UZ": "Uzbekistan",
  "VU": "Vanuatu",
  "VE": "Venezuela (Repubblica bolivariana di)",
  "VN": "Vietnam",
  "VG": "Isole Vergini (britanniche)",
  "VI": "Isole Vergini (USA)",
  "WF": "Wallis e Futuna",
  "EH": "Sahara occidentale",
  "YE": "Yemen",
  "ZM": "Zambia",
  "ZW": "Zimbabwe"
}
